import { Controller } from '@hotwired/stimulus'
import gsap from 'gsap'

export default class extends Controller {
  static targets = ['scrim', 'nav', 'navToggle', 'mainContent']
  static values = {
    isExpanded: Boolean,
    isPrimaryNav: Boolean,
  }

  connect() {
    // setup timeline
    this.tl = gsap
      .timeline({
        paused: true,
        onStart: () => {
          // remove body scroll when expanded
          document.body.dataset.scroll = false
        },
        onReverseComplete: () => {
          // enable body scroll when collapsed
          delete document.body.dataset.scroll
        },
      })
      // clear any applied properties
      .set([this.navTarget, this.mainContentTarget], {
        clearProps: 'all',
      })
      // set visibility of nav container/box
      .set(['#nav', this.navTarget], { autoAlpha: 1 })
      // fade scrim
      .to(this.scrimTarget, { autoAlpha: 1 }, 0)
      // animate nav box
      .to(
        this.navTarget,
        {
          xPercent: 100,
          ease: 'back.out(0.75)',
          duration: 0.5,
        },
        '<',
      )

    if (this.isPrimaryNavValue) {
      this.tl.fromTo(
        this.mainContentTarget,
        { x: 0, paddingLeft: 0 },
        { x: 319, paddingLeft: 319, ease: 'back.out(0.75)', duration: 0.5 },
        0,
      )
    }
  }

  toggleNav() {
    // handle animation
    this.isExpandedValue ? this.close() : this.open()
  }

  open() {
    // if already animating, do nothing
    if (this.isAnimating) return

    // update whether box is expanded/collapsed
    this.isExpandedValue = true

    // play animation at default speed
    this.tl.timeScale(1).play()
    this.navTarget.inert = false
  }

  close() {
    // if already animating, do nothing
    if (this.isAnimating) return

    // update whether box is expanded/collapsed
    this.isExpandedValue = false

    // reverse animation at 125% speed
    this.tl.timeScale(1.25).reverse()
    this.navTarget.inert = true
  }

  isExpandedValueChanged() {
    // update aria and focus for toggle buttons
    this.navToggleTargets.forEach((el) => {
      if (this.isExpandedValue) {
        // set toggle buttons to expanded
        el.ariaExpanded = true
        // sets focus to toggle in nav
        if (this.navTarget.contains(el)) el.focus()
      } else {
        // set toggle buttons to not expanded
        el.ariaExpanded = false
        // sets focus to toggle outside of nav
        if (!this.navTarget.contains(el)) el.focus()
      }
    })
  }
}
