import React, { Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'

import i18n from '@/core/locales/i18n'
import FullScreenLoader from '@/core/components/FullScreenLoader'

export default function I18nWrapper({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<FullScreenLoader />}>{children}</Suspense>
    </I18nextProvider>
  )
}
