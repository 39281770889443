import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  static targets = ['button', 'menu']
  static values = {
    placement: String('bottom-start'),
    offsetX: Number(0),
    offsetY: Number(10),
  }

  connect() {
    // create popper instance
    this.createPopperInstance()

    // setup popper event listeners
    this.setEventListeners()
  }

  createPopperInstance() {
    this.popperInstance = createPopper(this.buttonTarget, this.menuTarget, {
      placement: this.placementValue,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [this.offsetXValue, this.offsetYValue],
          },
        },
      ],
    })
  }

  setEventListeners() {
    const hideEvents = ['focusout']

    hideEvents.forEach((event) => {
      this.element.addEventListener(event, (e) => {
        // if child of dropdown has focus, do nothing
        if (this.element.contains(e.relatedTarget)) return

        // otherwise hide dropdown
        this.hide()
      })
    })
  }

  toggle() {
    // handle whether menu displays or not on button press
    this.buttonTarget.ariaExpanded === 'true' ? this.hide() : this.show()
  }

  show() {
    // make the dropdown visible
    this.menuTarget.setAttribute('data-show', '')
    this.buttonTarget.ariaExpanded = true

    // enable the event listeners
    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: true },
      ],
    }))

    // update its position
    this.popperInstance.update()
  }

  hide(e) {
    // if button/link pressed remove focus
    if (e) e.currentTarget.blur()

    // hide the dropdown
    this.menuTarget.removeAttribute('data-show')
    this.buttonTarget.ariaExpanded = false

    // disable the event listeners
    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: false },
      ],
    }))
  }
}
