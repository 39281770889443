import useWebSocket, { Options } from 'react-use-websocket'

import { buildWebSocketUrl } from './helpers'
import { WS_GENERIC_URL } from './constants'
import { GenericWSEvent } from './schemas'

export const useWebsocketManager = <T = unknown>(options: Options = {}) => {
  const wsUrl = buildWebSocketUrl(WS_GENERIC_URL)

  return useWebSocket<GenericWSEvent | T>(wsUrl, {
    heartbeat: true,
    shouldReconnect: () => true,
    share: true,
    ...options,
  })
}
