import React, { useState } from 'react'
import { partial } from 'lodash'
import { useTranslation } from 'react-i18next'

import { Button, Popover } from '@/core/mantine-ui'
import { classesMerge } from '@/core/helpers/classes-merge'

import { calculateTimeAgo } from './helpers'

interface HeaderProps {
  title: string
  isRead: boolean
  createdAt: string

  onMarkAsRead: () => void
  onMarkAsUnread: () => void
}

export const Header = ({
  title,
  isRead,
  createdAt,
  onMarkAsRead,
  onMarkAsUnread,
}: HeaderProps) => {
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)

  const notificationTime = calculateTimeAgo(createdAt)

  const handleMarkAsReadUnread = () => {
    if (isRead) {
      onMarkAsUnread()

      return
    }

    onMarkAsRead()
  }
  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <h2 className="font-semibold uppercase text-2xs text-secondary-text justify-self-end">
          {`TWYLL • ${notificationTime}`}
        </h2>

        <Popover position="bottom" shadow="md" opened={opened}>
          <Popover.Target>
            <Button
              variant="white"
              size="xs"
              className="flex items-center !h-12 px-0"
              onClick={handleMarkAsReadUnread}
              onMouseEnter={partial(setOpened, true)}
              onMouseLeave={partial(setOpened, false)}
            >
              <div
                className={classesMerge('w-8 h-8 rounded-full bg-primary', {
                  ['bg-white border-1 border-primary']: isRead,
                })}
              />
            </Button>
          </Popover.Target>
          <Popover.Dropdown style={{ pointerEvents: 'none' }}>
            <div className="p-8">
              {isRead ? t('Mark as unread') : t('Mark as read')}
            </div>
          </Popover.Dropdown>
        </Popover>
      </div>
      <h1
        className={classesMerge('font-semibold', {
          ['text-secondary-text']: isRead,
        })}
      >
        {title}
      </h1>
    </div>
  )
}
