import { createTrackedSelector } from 'react-tracked'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { ObservationResponse } from '@/ps/models/index'

import PostSessionTimeline, {
  EventRecordingIndexEntry,
  generateEvents,
  PostSessionTimelineEvent,
  PostSessionTimelineObservationEvent,
  insertIntoEvents,
  updateObservationIntoEvents,
} from '../helpers/timeline'
import {
  ObservationData,
  SectionData,
  PtoData,
  FoData,
  NewEventData,
  VideoTargetState,
  BillableSection,
} from '../models/data'
import { parsePostSessionData } from '../models/data_parse'

export type PostSessionStoreState = {
  timezone: string
  sections: SectionData[]
  observations: ObservationData[]
  billable_sections: BillableSection[]
  events: PostSessionTimelineEvent[][]
  timeline: PostSessionTimeline
  programs: PtoData[]
  free_operants: FoData[]
  session: string
  user_id: string
  isEditAllowed: boolean

  activeEventIndex: number
  isAutoScrolling: boolean
  currentTime: number
  isPlaying: boolean
  activeRecording: EventRecordingIndexEntry
  videoTargetState: VideoTargetState

  showAddObservationModal: boolean
  newEventData: NewEventData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialize(inputData: any): void
  flatEvents(): PostSessionTimelineEvent[]
  navigateToEvent(index: number, autoScroll?: boolean, autoPlay?: boolean): void
  clearAutoScroll(): void
  setAutoScroll(isAutoScrolling: boolean): void
  goPrev(): void
  goNext(): void
  toggleVideo(): void

  onVideoPlay(): void
  onVideoPause(): void
  onVideoTimeUpdate(playerCurrentTime: number): void
  onVideoEnded(): void

  setShowAddObservationModal(value: boolean): void
  setNewEventData(data: NewEventData): void
  addNewEvent(
    data: PostSessionTimelineObservationEvent,
    prevEventIndex: number,
  ): void
  updateEventsObservation(data: ObservationResponse[]): void

  addObservation(data: ObservationData): void
  removeObservation(uuid: string[]): void
  updateObservations(data: ObservationResponse[]): void

  updateObservationNote(uuid: string, comment: string): void
  updateEventObservationNote(uuid: string, comment: string): void
}

export const usePostSessionStoreBase = create<PostSessionStoreState>()(
  immer((set, get) => {
    const state: PostSessionStoreState = {
      timezone: '',
      sections: [],
      observations: [],
      billable_sections: [],
      events: null!,
      timeline: null!,
      programs: [],
      free_operants: [],
      session: '',
      user_id: '',
      isEditAllowed: true,

      activeEventIndex: -1,
      isAutoScrolling: false,
      currentTime: -1,
      isPlaying: false,
      activeRecording: {
        sectionIndex: -1,
        hasRecording: false,
        recordingIndex: -1,
        nextRecordingIndex: -1,
      },
      videoTargetState: {
        time: null,
        autoPlay: false,
      },

      showAddObservationModal: false,
      newEventData: {},

      initialize(inputData) {
        const {
          timezone,
          sections,
          billable_sections,
          observations,
          programs,
          free_operants,
          session,
          user_id,
          is_edit_allowed,
        } = parsePostSessionData(inputData)
        const events = generateEvents(sections, observations, billable_sections)
        const timeline = new PostSessionTimeline(
          sections,
          events,
          billable_sections,
        )

        set((state) => {
          state.timezone = timezone
          state.sections = sections
          state.billable_sections = billable_sections
          state.observations = observations
          state.events = events
          state.timeline = timeline
          state.programs = programs
          state.free_operants = free_operants
          state.session = session
          state.user_id = user_id
          state.isEditAllowed = is_edit_allowed
        })

        const { navigateToEvent } = get()
        setTimeout(() => {
          navigateToEvent(0, false, false)
        }, 0)
      },

      flatEvents() {
        const { events } = get()

        return events.flatMap((sectionEvents) => sectionEvents)
      },

      navigateToEvent(index, enableAutoScroll = true, autoPlay = true) {
        const { timeline, activeRecording } = get()

        const event2DIndex = timeline.event2DIndex(index)
        if (!event2DIndex) return

        const [sectionIndex, eventIndex] = event2DIndex
        const event = timeline.event(sectionIndex, eventIndex)

        const currentTime = event.time.getTime() - timeline.startAt.getTime()
        const nextActiveRecording = timeline.eventRecording(
          sectionIndex,
          eventIndex,
        )
        const isNewRecording =
          activeRecording.recordingIndex !== nextActiveRecording.recordingIndex
        const videoTargetState: VideoTargetState = {
          time: event.time,
          autoPlay,
        }

        set((state) => {
          if (enableAutoScroll) {
            state.isAutoScrolling = true
          }
          state.activeEventIndex = index
          state.currentTime = currentTime
          if (isNewRecording) {
            state.activeRecording = nextActiveRecording
          }
          state.videoTargetState = videoTargetState
        })
      },

      clearAutoScroll() {
        const { isAutoScrolling } = get()
        if (!isAutoScrolling) {
          return
        }

        set((state) => {
          state.isAutoScrolling = false
        })
      },

      setAutoScroll(isAutoScrolling) {
        set((state) => {
          state.isAutoScrolling = isAutoScrolling
        })
      },

      goPrev() {
        const { activeEventIndex, navigateToEvent } = get()
        if (activeEventIndex <= 0) {
          return
        }

        navigateToEvent(activeEventIndex - 1)
      },

      goNext() {
        const { timeline, activeEventIndex, navigateToEvent } = get()
        if (activeEventIndex >= timeline.eventsCount - 1) {
          return
        }

        navigateToEvent(activeEventIndex + 1)
      },

      toggleVideo() {
        const { activeRecording, isPlaying } = get()
        if (!activeRecording.hasRecording) {
          return
        }

        set((state) => {
          state.isAutoScrolling = true
          state.isPlaying = !isPlaying
        })
      },

      onVideoPlay() {
        set((state) => {
          state.isPlaying = true
        })
      },

      onVideoPause() {
        set((state) => {
          state.isPlaying = false
        })
      },

      onVideoTimeUpdate(playerCurrentTime: number) {
        const { timeline, isPlaying, activeRecording } = get()
        if (!(isPlaying && activeRecording.hasRecording)) {
          return
        }

        const { sectionIndex, recordingIndex } = activeRecording
        const recording = timeline.recording(sectionIndex, recordingIndex)

        // Determine the absolute time based on the relative player time and
        // absolute recording start time
        const playerTime = Math.floor(playerCurrentTime * 1000)
        const time = playerTime + recording.startAt.getTime()

        // Set the current time to the relative time from the session's start
        const nextCurrentTime = time - timeline.startAt.getTime()

        // Set the active event index to the latest event in the recording, having
        // occurred before the new time
        const latestEventIndex =
          timeline.latestEvent1DIndexInRecordingBeforeTime(
            sectionIndex,
            recordingIndex,
            new Date(time),
          )

        set((state) => {
          state.currentTime = nextCurrentTime
          if (latestEventIndex !== -1) {
            state.activeEventIndex = latestEventIndex
          }
        })
      },

      onVideoEnded() {
        const { timeline, activeRecording, navigateToEvent } = get()
        if (!activeRecording.hasRecording) {
          return
        }

        const { sectionIndex, recordingIndex } = activeRecording

        // Find the latest event in the recording
        const latestEventIndex = timeline.latestEvent2DIndexInRecording(
          sectionIndex,
          recordingIndex,
        )

        // Search for the next event after this recording
        let searchSectionIndex = sectionIndex
        let searchEventIndex = latestEventIndex
        let nextEventIndex = -1
        while (searchSectionIndex < timeline.sectionsCount) {
          const nextRecording = timeline.eventRecording(
            searchSectionIndex,
            searchEventIndex,
          )
          if (
            nextRecording.sectionIndex !== sectionIndex ||
            nextRecording.recordingIndex !== recordingIndex
          ) {
            nextEventIndex = timeline.event1DIndex(
              searchSectionIndex,
              searchEventIndex,
            )
            break
          }

          searchEventIndex++
          if (
            searchEventIndex === timeline.sectionEventsCount(searchSectionIndex)
          ) {
            searchEventIndex = 0
            searchSectionIndex++
          }
        }

        if (nextEventIndex > -1) {
          // If there is a next event, navigate to it
          navigateToEvent(nextEventIndex, false)
        } else {
          // Otherwise, update the state to point at the last event in this recording
          const recording = timeline.recording(sectionIndex, recordingIndex)

          set((state) => {
            state.currentTime =
              recording.endAt.getTime() - timeline.startAt.getTime()
            state.activeEventIndex = timeline.event1DIndex(
              sectionIndex,
              latestEventIndex,
            )
          })
        }
      },

      setShowAddObservationModal(value) {
        set((state) => {
          state.showAddObservationModal = value
        })
      },

      setNewEventData(data) {
        set((state) => {
          state.newEventData = data
        })
      },

      addNewEvent(data, prevEventIndex) {
        const { observations, events } = get()
        set((state) => {
          state.observations = [...observations, data.observation]
          const newEvents = insertIntoEvents(data, events, prevEventIndex)
          state.events = newEvents
        })
      },

      updateEventsObservation(updatedEvents) {
        const { events } = get()
        set((state) => {
          const newEvents = updateObservationIntoEvents(events, updatedEvents)
          state.events = newEvents
        })
      },

      addObservation(data) {
        const { observations } = get()
        set((state) => {
          state.observations = [...observations, data]
        })
      },

      removeObservation(obsIds) {
        const { observations, events } = get()
        set((state) => {
          state.observations = observations.filter(
            (obs) => !obsIds.includes(obs.uuid),
          )
          const newEvents = events.map((sectionEvents) => {
            return sectionEvents.filter((event) => {
              return event.type === 'observation'
                ? !obsIds.includes(event.observation.uuid)
                : true
            })
          })
          state.events = newEvents
        })
      },

      updateObservations(obsData) {
        if (Array.isArray(obsData)) {
          const { observations } = get()
          set((state) => {
            const NewObservations = observations.map((obs) => {
              if (obs.type !== 'timestamp') {
                const index = obsData.findIndex((o) => o.id === obs.uuid)
                if (index >= 0) {
                  const newObs = { ...obs }
                  newObs.data = {
                    ...obsData[index].data,
                  }
                  return newObs
                }
              }
              return obs
            })
            state.observations = NewObservations
          })
        }
      },

      updateObservationNote(uuid, comment) {
        const { observations } = get()

        set((state) => {
          const NewObservations = observations.map((observation) => {
            if (observation.type === 'timestamp' && observation.uuid === uuid) {
              return {
                ...observation,
                comment,
              }
            }
            return observation
          })
          state.observations = NewObservations
        })
      },

      updateEventObservationNote(uuid, comment) {
        const { flatEvents, events } = get()

        const event = flatEvents().find((e) =>
          e.type === 'observation' ? e.observation.uuid === uuid : false,
        )
        set((state) => {
          if (event && event.type === 'observation') {
            const newEvents = events.map((sectionEvents) => {
              return sectionEvents.map((e) => {
                if (e.type === 'observation' && e.uuid === event.uuid) {
                  return {
                    ...e,
                    observation: {
                      ...e.observation,
                      comment,
                    },
                  }
                }
                return e
              })
            })

            state.events = newEvents
          }
        })
      },
    }
    return state
  }),
)

export const usePostSessionStore = createTrackedSelector(
  usePostSessionStoreBase,
)

export function getPostSessionStore(): PostSessionStoreState {
  return usePostSessionStoreBase.getState()
}
