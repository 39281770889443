import { z } from 'zod'

export const attachmentSchema = z.object({
  id: z.string(),
  createdAt: z.string().pipe(z.coerce.date()),
  filename: z.string(),
  fileSize: z.number(),
  fileUrl: z.string(),
  showInSession: z.boolean(),
})
