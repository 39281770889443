export const iconStop = (classes = 'rect-24') => {
  return `<svg class="${classes}" viewBox="0 0 24 24" aria-hidden="true"><path d="M6 6H18V18H6V6Z"/></svg>`
}

export const iconPause = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 19H10V5H6V19ZM14 5V19H18V5H14Z"/></svg>`
}

export const iconPlay = (classes = 'rect-24') => {
  return `<svg class="${classes}" viewBox="0 0 24 24" aria-hidden="true"><path d="M8 5V19L19 12L8 5Z"/></svg>`
}

export const iconNotRecording = (classes = 'rect-8') => {
  return `<span class="inline-block rounded-full bg-gray-400 ${classes}"></span>`
}

export const iconRecording = (classes = 'rect-8 animate-pulse') => {
  return `<span class="inline-block rounded-full bg-red-400 ${classes}"></span>`
}

export const iconAdd = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24"><path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"/></svg>`
}

export const iconRemove = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24"><path d="M19 13H5V11H19V13Z"/></svg>`
}

export const iconPrompt = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.88022 21.8535C7.73503 21.9512 7.57169 22 7.3902 22C7.13007 22 6.93043 21.9145 6.79129 21.7436C6.65215 21.5727 6.58258 21.3438 6.58258 21.0569V18.3923H6.15608C5.25469 18.3923 4.49546 18.2305 3.8784 17.907C3.26134 17.5835 2.7925 17.1104 2.47187 16.4877C2.15729 15.8651 2 15.1051 2 14.2077V7.19374C2 6.29639 2.15729 5.53639 2.47187 4.91373C2.7925 4.29108 3.26134 3.81799 3.8784 3.49446C4.49546 3.16482 5.25469 3 6.15608 3H17.8439C18.7453 3 19.5045 3.16482 20.1216 3.49446C20.7387 3.81799 21.2045 4.29108 21.5191 4.91373C21.8397 5.53639 22 6.29639 22 7.19374V14.2077C22 15.1051 21.8397 15.8651 21.5191 16.4877C21.2045 17.1104 20.7387 17.5865 20.1216 17.9161C19.5045 18.2397 18.7453 18.4014 17.8439 18.4014L11.7096 18.3923L8.43376 21.414C8.21597 21.6154 8.03146 21.7619 7.88022 21.8535ZM9.40472 14.3451C9.53176 14.5038 9.71627 14.5831 9.95826 14.5831C10.2063 14.5831 10.3938 14.5068 10.5209 14.3542C10.6479 14.1955 10.7114 13.9849 10.7114 13.7224V11.6622H12.5535C13.467 11.6622 14.2021 11.3966 14.7586 10.8655C15.3212 10.3345 15.6025 9.6355 15.6025 8.76867C15.6025 7.90185 15.3212 7.19984 14.7586 6.66265C14.2021 6.12546 13.4701 5.85687 12.5626 5.85687H9.94918C9.7072 5.85687 9.52269 5.93622 9.39564 6.09494C9.27465 6.25365 9.21416 6.4612 9.21416 6.71759V13.7224C9.21416 13.9788 9.27768 14.1863 9.40472 14.3451ZM12.3448 10.5084H10.7114V7.01976H12.3448C12.8893 7.01976 13.3218 7.17542 13.6425 7.48675C13.9631 7.79807 14.1234 8.22538 14.1234 8.76867C14.1234 9.30586 13.9631 9.73012 13.6425 10.0414C13.3279 10.3528 12.8953 10.5084 12.3448 10.5084Z"/></svg>`
}

export const iconBlock = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM4 12C4 7.58 7.58 4 12 4C13.85 4 15.55 4.63 16.9 5.69L5.69 16.9C4.63 15.55 4 13.85 4 12ZM12 20C10.15 20 8.45 19.37 7.1 18.31L18.31 7.1C19.37 8.45 20 10.15 20 12C20 16.42 16.42 20 12 20Z"/></svg>`
}

export const iconFlag = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24"><path d="M12.36 6L12.76 8H18V14H14.64L14.24 12H7V6H12.36ZM14 4H5V21H7V14H12.6L13 16H20V6H14.4L14 4Z"/></svg>`
}

export const iconInfo = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"/></svg>`
}

export const iconTimer = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24"><path d="M15 1H9V3H15V1Z"/><path d="M15 1H9V3H15V1ZM11 14H13V8H11V14ZM19.03 7.39L20.45 5.97C20.02 5.46 19.55 4.98 19.04 4.56L17.62 5.98C16.07 4.74 14.12 4 12 4C7.03 4 3 8.03 3 13C3 17.97 7.02 22 12 22C16.98 22 21 17.97 21 13C21 10.88 20.26 8.93 19.03 7.39ZM12 20C8.13 20 5 16.87 5 13C5 9.13 8.13 6 12 6C15.87 6 19 9.13 19 13C19 16.87 15.87 20 12 20Z" /></svg>`
}

export const iconTimerOff = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24"><path d="M15 1H9V3H15V1Z"/><path d="M12 6C15.87 6 19 9.13 19 13C19 13.94 18.81 14.83 18.48 15.65L19.98 17.15C20.63 15.91 21 14.5 21 13C21 10.88 20.26 8.93 19.03 7.39L20.45 5.97C20.02 5.46 19.55 4.98 19.04 4.56L17.62 5.98C16.07 4.74 14.12 4 12 4C10.5 4 9.09 4.37 7.85 5.02L9.35 6.52C10.17 6.19 11.06 6 12 6Z"/><path d="M11 8.17L13 10.17V8H11V8.17Z"/><path d="M2.81 2.81L1.39 4.22L4.79 7.62C3.67 9.12 3 10.98 3 13C3 17.97 7.02 22 12 22C14.02 22 15.88 21.33 17.38 20.21L19.78 22.61L21.19 21.2L2.81 2.81ZM12 20C8.13 20 5 16.87 5 13C5 11.53 5.45 10.17 6.22 9.05L15.95 18.78C14.83 19.55 13.47 20 12 20Z"/></svg>`
}

export const iconDescription = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24"><path d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z"/></svg>`
}

export const iconPercent = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24"><path d="M7.5 11C6.53333 11 5.70833 10.6583 5.025 9.975C4.34167 9.29167 4 8.46667 4 7.5C4 6.53333 4.34167 5.70833 5.025 5.025C5.70833 4.34167 6.53333 4 7.5 4C8.46667 4 9.29167 4.34167 9.975 5.025C10.6583 5.70833 11 6.53333 11 7.5C11 8.46667 10.6583 9.29167 9.975 9.975C9.29167 10.6583 8.46667 11 7.5 11ZM7.5 9C7.91667 9 8.27067 8.854 8.562 8.562C8.854 8.27067 9 7.91667 9 7.5C9 7.08333 8.854 6.72933 8.562 6.438C8.27067 6.146 7.91667 6 7.5 6C7.08333 6 6.72933 6.146 6.438 6.438C6.146 6.72933 6 7.08333 6 7.5C6 7.91667 6.146 8.27067 6.438 8.562C6.72933 8.854 7.08333 9 7.5 9ZM16.5 20C15.5333 20 14.7083 19.6583 14.025 18.975C13.3417 18.2917 13 17.4667 13 16.5C13 15.5333 13.3417 14.7083 14.025 14.025C14.7083 13.3417 15.5333 13 16.5 13C17.4667 13 18.2917 13.3417 18.975 14.025C19.6583 14.7083 20 15.5333 20 16.5C20 17.4667 19.6583 18.2917 18.975 18.975C18.2917 19.6583 17.4667 20 16.5 20ZM16.5 18C16.9167 18 17.2707 17.854 17.562 17.562C17.854 17.2707 18 16.9167 18 16.5C18 16.0833 17.854 15.7293 17.562 15.438C17.2707 15.146 16.9167 15 16.5 15C16.0833 15 15.7293 15.146 15.438 15.438C15.146 15.7293 15 16.0833 15 16.5C15 16.9167 15.146 17.2707 15.438 17.562C15.7293 17.854 16.0833 18 16.5 18ZM5.4 20L4 18.6L18.6 4L20 5.4L5.4 20Z"/></svg>`
}

export const iconAddNote = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.98 8.94L13.06 8.02L4 17.08V18H4.92L13.98 8.94ZM15.96 2.29C16.15 2.1 16.41 2 16.66 2C16.92 2 17.17 2.09 17.37 2.29L19.71 4.63C20.1 5.02 20.1 5.65 19.71 6.04L17.88 7.87L14.13 4.12L15.96 2.29ZM2 16.25L13.06 5.19L16.81 8.94L5.75 20H2V16.25ZM17 14H19V17H22V19H19V21.99C19 21.99 17.01 22 17 21.99V19H14C14 19 14.01 17.01 14 17H17V14Z"/></svg>`
}

export const iconCheck = (classes = 'rect-24') => {
  return `<svg class="${classes}" aria-hidden="true" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.99999 16.2L4.79999 12L3.39999 13.4L8.99999 19L21 7.00001L19.6 5.60001L8.99999 16.2Z"/></svg>`
}
