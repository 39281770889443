import { useCallback, useState } from 'react'

import { Notification } from '../schemas'

export const useNotificationsState = () => {
  const [count, setCount] = useState(0)
  const [notificationsList, setNotificationsList] = useState<Notification[]>([])

  const setNotificationsCountState = useCallback((count: number) => {
    setCount(count)
  }, [])

  const setNotificationsListState = useCallback(
    (notifications: Notification[]) => {
      setNotificationsList(notifications)
    },
    [],
  )

  return {
    count,
    notificationsList,
    setNotificationsCountState,
    setNotificationsListState,
  }
}
