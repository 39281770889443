import React from 'react'
import {
  Notifications as MantineNotifications,
  notifications,
  type NotificationsProps,
} from '@mantine/notifications'

export const Notifications = (props: NotificationsProps) => {
  return <MantineNotifications {...props} />
}

export { notifications }
