import { z } from 'zod'

export const programCurriculumInformationSchema = z.object({
  generalizationGuidance: z.string(),
  implementationGuidance: z.string(),
  promptingGuidance: z.string(),
})

export type CurriculumInformation = z.infer<
  typeof programCurriculumInformationSchema
>
