import { z } from 'zod'

import { PROGRAM_DATA_COLLECTION_METHODS } from '../constants/programDataCollectionMethod'

export const programDataCollectionMethodSchema = z.enum(
  PROGRAM_DATA_COLLECTION_METHODS,
)

export type ProgramDataCollectionMethod = z.infer<
  typeof programDataCollectionMethodSchema
>
