import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SYNC_EVENT_TYPES } from '@/offline/interfaces/SyncEventBusManagerInterface'
import { ActionIcon } from '@/core/mantine-ui'
import { MaterialIcon } from '@/core/components/material-icon'
import { useOfflineApplication } from '@/offline/hooks/useOfflineApplication'

import { SyncCard } from './SyncCard'
import { useListenSyncData } from './hooks/useListenSyncData'
import { getSyncStatus } from './config'

export const Sync = () => {
  const { t } = useTranslation(['common'])
  const [isSyncOpen, setIsSyncOpen] = useState(false)
  const {
    offlineApplication: { syncEventBusManager: syncBus },
  } = useOfflineApplication()
  const { syncStatus } = useListenSyncData()
  const { SYNCING } = getSyncStatus(t)

  const onOpenSyncCard = () => {
    setIsSyncOpen(!isSyncOpen)
  }

  const handleSyncData = async () => {
    syncBus.dispatch(
      new CustomEvent(SYNC_EVENT_TYPES.MANUAL, {
        detail: { message: 'manually trigger background sync' },
      }),
    )
  }

  return (
    <>
      <ActionIcon
        size={'lg'}
        onClick={onOpenSyncCard}
        aria-label="Sync status icon"
      >
        <MaterialIcon icon={syncStatus.icon} size={28} />
      </ActionIcon>
      <SyncCard
        isVisible={isSyncOpen}
        disabled={syncStatus.title === SYNCING.title}
        title={syncStatus.title}
        handleSyncData={handleSyncData}
      />
    </>
  )
}
