import React from 'react'

import { classesMerge } from '@/core/helpers/classes-merge'
import { Notification } from '@/core/services/websocket/services'

import {
  Description,
  ExpiredAtLabel,
  Header,
  NotificationActions,
  NotificationCategoryBar,
} from './components'
import { NOTIFICATION_BACKGROUND_COLOR } from './constants'

export interface NotificationItemProps {
  notification: Notification

  onMarkAsRead: () => void
  onMarkAsUnread: () => void
  onDismiss: () => void
}

export const NotificationItem = ({
  notification: {
    title,
    description,
    expired_at,
    is_read,
    category,
    created_at,
    action_link,
  },

  onMarkAsRead,
  onMarkAsUnread,
  onDismiss,
}: NotificationItemProps) => {
  const isReadAndWarningOrError =
    is_read && (category === 'warning' || category === 'error')

  return (
    <section
      data-testid="notification-item"
      className={classesMerge(
        'box-border relative flex flex-col gap-8 px-24 py-16 pr-16 bg-white border border-gray-300 rounded-lg',
        NOTIFICATION_BACKGROUND_COLOR[category],
        {
          ['bg-opacity-8']: isReadAndWarningOrError,
        },
      )}
    >
      <NotificationCategoryBar isRead={is_read} category={category} />
      <Header
        title={title}
        isRead={is_read}
        createdAt={created_at}
        onMarkAsRead={onMarkAsRead}
        onMarkAsUnread={onMarkAsUnread}
      />
      <Description description={description} isRead={is_read} />
      <ExpiredAtLabel expiredAt={expired_at} />
      <NotificationActions actionLink={action_link} onDismiss={onDismiss} />
    </section>
  )
}
