import React from 'react'
import { partial } from 'lodash'

import { Popover } from '@/core/mantine-ui'
import { useNotifications } from '@/core/services/websocket'
import { IfFeatureEnabled } from '@/core/services/growthbook'

import {
  NotificationItem,
  NotificationPanelContent,
  NotificationPanelFooter,
  NotificationPanelHeader,
  NotificationPanelTargetButton,
} from './components'

export const NotificationPanel = () => {
  const {
    count,
    notificationsList,
    getNotificationsList,
    markAsRead,
    markAsUnread,
    markAllAsRead,
    dismissNotification,
  } = useNotifications()

  return (
    <IfFeatureEnabled feature="beavers_notifications_center">
      <Popover
        offset={2}
        shadow="md"
        closeOnClickOutside
        onOpen={getNotificationsList}
        classNames={{
          dropdown: 'px-0 py-8 bg-gray-50 border-rounded overflow-auto',
        }}
      >
        <Popover.Target>
          <NotificationPanelTargetButton unreadCount={count} />
        </Popover.Target>
        <Popover.Dropdown>
          <NotificationPanelHeader
            unreadCount={count}
            notificationsCount={notificationsList.length}
            onMarkAllAsRead={markAllAsRead}
          />
          <NotificationPanelContent
            notificationsCount={notificationsList.length}
          >
            {notificationsList.map((notification) => (
              <NotificationItem
                key={notification.uuid}
                notification={notification}
                onMarkAsRead={partial(markAsRead, notification.uuid)}
                onMarkAsUnread={partial(markAsUnread, notification.uuid)}
                onDismiss={partial(dismissNotification, notification.uuid)}
              />
            ))}
          </NotificationPanelContent>
          {notificationsList.length > 0 && <NotificationPanelFooter />}
        </Popover.Dropdown>
      </Popover>
    </IfFeatureEnabled>
  )
}
