import React from 'react'
import { Timeline as MantineTimeline, TimelineProps } from '@mantine/core'

import classes from './Timeline.module.scss'

export const Timeline = ({ ...props }: TimelineProps) => {
  return <MantineTimeline classNames={{ ...classes }} {...props} />
}

Timeline.Item = MantineTimeline.Item
