// The CSRF token should be injected into the window object
if (!window.csrfToken) {
  console.warn('Expected window.csrfToken to be defined')
}

const headers = {
  'X-CSRFToken': window.csrfToken,
}

/**
 * Initiate a WebRTC connection with the server.
 * Returns info necessary to setup a client connection.
 */
export async function initiateConnection(deviceInfo, mediaInfo) {
  const res = await fetch(`${window.location.pathname}/initiate-connection`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      device: deviceInfo,
      media: mediaInfo,
    }),
  })
  if (res.status !== 200) {
    throw new Error(res.statusText)
  }

  const data = await res.json()
  return {
    channelARN: data.channel_arn,
    channelEndpoint: data.channel_endpoint,
    region: data.region,
    signedURL: data.signed_url,
    iceServers: data.ice_servers.map(({ Uris, Username, Password }) => ({
      urls: Uris,
      username: Username,
      credential: Password,
    })),
  }
}
