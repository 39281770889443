import React from 'react'

export const UnreadNotificationsBadge = () => {
  return (
    <div
      data-testid="unread-notifications-badge"
      className="absolute top-[5px] right-6 bg-red-600 rounded-full w-[10px] h-[10px] border-1 border-white"
    />
  )
}
