import * as Sentry from '@sentry/react'

import {
  Identifiable,
  LocalDataManagerInterface,
} from '@/offline/interfaces/LocalDataManagerInterface'
import { Stores, Indexes, OfflineEvent } from '@/offline/models'
import { createNewOfflineSession } from '@/offline/services/createNewOfflineSession'
import { removeNullSegments } from '@/offline/helpers'

const SEVEN_DAYS_IN_MILLIS = 7 * 24 * 60 * 60 * 1000

export class DefaultLocalDataManager implements LocalDataManagerInterface {
  private readonly dbName: string
  private readonly dbVersion: number
  db: IDBDatabase | null = null

  constructor(dbName: string, dbVersion: number) {
    this.dbName = dbName
    this.dbVersion = dbVersion
  }

  /**
   * Initializes an IndexedDB database and handles version upgrades and schema changes.
   * Object stores should be added here.
   *
   * @returns A Promise that resolves true on successful initialization otherwise false.
   */
  async initDB(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!globalThis.indexedDB) {
        reject('indexedDB is not defined')
      }
      const request: IDBOpenDBRequest = globalThis.indexedDB.open(
        this.dbName,
        this.dbVersion,
      )
      request.onsuccess = () => {
        this.db = request.result
        resolve(true)
      }
      request.onerror = () => {
        reject('Error opening IndexedDB: ' + request.error)
      }
      // fires when you first try to open a DB at a given value of version
      request.onupgradeneeded = async (event) => {
        this.db = request.result
        const transaction = request.transaction

        if (event.oldVersion < 3) {
          let sessionOS = null
          let clientOS = null
          let iseOS = null
          let iseEventsOS = null
          let symmetricKeyOS = null
          let lastUpdatedAtOS = null

          if (!this.db.objectStoreNames.contains(Stores.Session)) {
            sessionOS = this.db.createObjectStore(Stores.Session, {
              keyPath: 'id',
            })
          } else {
            sessionOS = transaction?.objectStore(Stores.Session)
          }

          if (sessionOS && !sessionOS.indexNames.contains(Indexes.User)) {
            sessionOS.createIndex(Indexes.User, Indexes.User, { unique: false })
          }

          if (!this.db.objectStoreNames.contains(Stores.Client)) {
            clientOS = this.db.createObjectStore(Stores.Client, {
              keyPath: 'id',
            })
          } else {
            clientOS = transaction?.objectStore(Stores.Client)
          }

          if (clientOS && !clientOS.indexNames.contains(Indexes.User)) {
            clientOS.createIndex(Indexes.User, Indexes.User, { unique: false })
          }

          if (!this.db.objectStoreNames.contains(Stores.Ise)) {
            iseOS = this.db.createObjectStore(Stores.Ise, {
              keyPath: 'uuid',
            })
          } else {
            iseOS = transaction?.objectStore(Stores.Ise)
          }

          if (iseOS && !iseOS.indexNames.contains(Indexes.Uuid)) {
            iseOS.createIndex(Indexes.Uuid, Indexes.Uuid, { unique: false })
          }

          if (!this.db.objectStoreNames.contains(Stores.IseEvents)) {
            iseEventsOS = this.db.createObjectStore(Stores.IseEvents, {
              keyPath: 'uuid',
            })
          } else {
            iseEventsOS = transaction?.objectStore(Stores.IseEvents)
          }

          if (iseEventsOS && !iseEventsOS.indexNames.contains(Indexes.Uuid)) {
            iseEventsOS.createIndex(Indexes.Uuid, Indexes.Uuid, {
              unique: true,
            })
          }

          if (!this.db.objectStoreNames.contains(Stores.SymmetricKey)) {
            symmetricKeyOS = this.db.createObjectStore(Stores.SymmetricKey, {
              keyPath: 'id',
            })
          } else {
            symmetricKeyOS = transaction?.objectStore(Stores.SymmetricKey)
          }

          if (
            symmetricKeyOS &&
            !symmetricKeyOS.indexNames.contains(Indexes.Id)
          ) {
            symmetricKeyOS.createIndex(Indexes.Id, Indexes.Id, { unique: true })
          }

          if (!this.db.objectStoreNames.contains(Stores.LastUpdatedAt)) {
            lastUpdatedAtOS = this.db.createObjectStore(Stores.LastUpdatedAt, {
              keyPath: 'id',
            })
          } else {
            lastUpdatedAtOS = transaction?.objectStore(Stores.LastUpdatedAt)
          }

          if (
            lastUpdatedAtOS &&
            !lastUpdatedAtOS.indexNames.contains(Indexes.Id)
          ) {
            lastUpdatedAtOS.createIndex(Indexes.Id, Indexes.Id, {
              unique: true,
            })
          }
        }

        if (event.oldVersion < 4) {
          await Promise.all(
            Object.values(Stores).map(async (value) => {
              if (!this.db) {
                throw new Error(
                  'lost db connection during onupgradeneeded; aborting',
                )
              }
              const tempObjectStoreName = `temp-${value}`
              const tempObjectStore =
                this.db.createObjectStore(tempObjectStoreName)

              await this.clearStore(tempObjectStore)
              const oldObjectStore = transaction?.objectStore(value)

              if (!oldObjectStore) {
                throw new Error(`Object store ${value} does not exist`)
              }

              await this.copyData(oldObjectStore, tempObjectStore)
              await this.clearStore(oldObjectStore)
              this.db.deleteObjectStore(value)
              const newObjectStore = this.db.createObjectStore(value)
              await this.copyData(tempObjectStore, newObjectStore)

              this.db.deleteObjectStore(tempObjectStoreName)
            }),
          )
        }
      }
    })
  }

  /**
   * Create an index in an IndexedDB store if it doesn't exist.
   *
   * @param store - The IndexedDB object store in which the index should be created.
   * @param indexName - The name of the index.
   * @param keypath - The keypath value for the index.
   * @param unique - A boolean flag indicating whether the index should enforce uniqueness.
   *
   * @returns A `Promise` that resolves to `true` if the index is created or already exists, or rejects with `false` if an error occurs.
   */
  createIndex(
    store: IDBObjectStore,
    indexName: string,
    keypath: string,
    unique: boolean,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const createIndex = () => {
        // Create the index within the transaction.
        if (!this.db?.objectStoreNames.contains(store.name)) {
          store.createIndex(indexName, keypath, { unique: unique })
          resolve(true)
        } else {
          reject('createIndex: Index already exists')
        }
      }

      if (!this.db || !this.isDbOpen()) {
        this.initDB().then(createIndex).catch(reject)
      } else {
        createIndex()
      }
    })
  }

  /**
   * Retrieve data from an IndexedDB store using an index.
   *
   * @param storeName - The name of the IndexedDB store.
   * @param indexName - The name of the index within the store to query.
   * @param value - The value to look for in the specified index.
   * @returns A promise that resolves with an array of retrieved data or rejects with an error.
   */
  getDataByIndex<T>(
    storeName: string,
    indexName: string,
    value: string,
  ): Promise<T[] | null> {
    return new Promise((resolve, reject) => {
      const getDataByIndex = () => {
        if (!this.db) {
          return reject('Database is not initialized')
        }

        const transaction = this.db.transaction(storeName, 'readonly', {
          durability: 'strict',
        })
        transaction.onabort = () => reject(transaction.error)

        const store = transaction.objectStore(storeName)
        const index = store.index(indexName)
        const request = index.openCursor(IDBKeyRange.only(value))
        const result: T[] = []
        request.onsuccess = () => {
          const cursor = request.result
          if (cursor) {
            result.push(cursor.value)
            cursor.continue()
          } else {
            resolve(result)
          }
        }
        request.onerror = () => {
          reject(request.error)
        }
      }

      if (!this.db || !this.isDbOpen()) {
        this.initDB().then(getDataByIndex).catch(reject)
      } else {
        getDataByIndex()
      }
    })
  }

  /**
   * Adds data to a specified object store in the IndexedDB.
   *
   * @param storeName - The name of the object store to add data to.
   * @param data - The data to be added, either a single item or an array of items.
   *
   * @returns A Promise that resolves with success or an error message as a string.
   */
  async addData(storeName: string, data: Identifiable | Identifiable[]) {
    return new Promise<Identifiable | Identifiable[] | string>(
      (resolve, reject) => {
        const addData = () => {
          if (!this.db) {
            return reject('Database is not initialized')
          }

          const transaction = this.db.transaction(storeName, 'readwrite', {
            durability: 'strict',
          })
          transaction.onabort = () => reject(transaction.error)

          const store = transaction.objectStore(storeName)
          if (Array.isArray(data)) {
            const requests = data.map((item) =>
              store.put(removeNullSegments(item), this.extractId(item)),
            )
            Promise.all(
              requests.map((request) => {
                return new Promise((resolve) => {
                  request.onsuccess = () => resolve(true)
                  request.onerror = () => {
                    reject('Error adding data to the store')
                  }
                })
              }),
            ).then((results) => {
              if (results.every((result) => result)) {
                resolve('Data added successfully')
              } else {
                reject('Error adding data to the store')
              }
            })
          } else {
            const request = store.put(
              removeNullSegments(data),
              this.extractId(data),
            )
            request.onsuccess = () => resolve('Data added successfully')
            request.onerror = () => reject('Error adding data to the store')
          }
        }

        if (!this.db || !this.isDbOpen()) {
          this.initDB().then(addData).catch(reject)
        } else {
          addData()
        }
      },
    )
  }

  /**
   * Deletes data from an IndexedDB store.
   *
   * @param storeName - The name of the store from which to delete data.
   * @param keys - A single key or an array of keys to delete.
   *
   * @returns A `Promise` that resolves with a success message or rejects with an error message.
   */
  async deleteData(storeName: string, keys: string | string[]) {
    return new Promise<number | string>((resolve, reject) => {
      const deleteData = () => {
        if (!this.db) {
          return reject('Database is not initialized')
        }

        const transaction = this.db.transaction(storeName, 'readwrite', {
          durability: 'strict',
        })
        transaction.onabort = () => reject(transaction.error)

        const store: IDBObjectStore = transaction.objectStore(storeName)

        if (Array.isArray(keys)) {
          const deletePromises: Promise<void | string>[] = keys.map(
            (key: string) =>
              new Promise((resolve, reject) => {
                const request: IDBRequest = store.delete(key)
                request.onsuccess = () => resolve('Data deleted successfully')
                request.onerror = () => reject('Error deleting data')
              }),
          )

          Promise.all(deletePromises)
            .then(() => {
              resolve('Data deleted successfully')
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          const request = store.delete(keys)
          request.onsuccess = () => resolve('Data deleted successfully')
          request.onerror = () => {
            reject('Error deleting data')
          }
        }
      }

      if (!this.db || !this.isDbOpen()) {
        this.initDB().then(deleteData).catch(reject)
      } else {
        deleteData()
      }
    })
  }

  /**
   * Updates data in an IndexedDB store.
   *
   * @param storeName - The name of the store where data should be updated.
   * @param data - An object or an array of objects to update.
   *
   * @returns A `Promise` that resolves with the number of items updated, or rejects with an error message.
   */
  async updateData(
    storeName: string,
    data: Identifiable | Identifiable[],
  ): Promise<number | string> {
    return new Promise<number | string>((resolve, reject) => {
      const updateData = () => {
        if (!this.db) {
          return reject('Database is not initialized')
        }

        const transaction = this.db.transaction(storeName, 'readwrite', {
          durability: 'strict',
        })
        transaction.onabort = () => reject(transaction.error)

        const store = transaction.objectStore(storeName)

        if (Array.isArray(data)) {
          const promises = data.map((item: Identifiable) =>
            store.put(item, this.extractId(item)),
          )
          Promise.all(promises)
            .then((results) => {
              resolve(results.length)
            })
            .catch((e) => reject(e))
        } else {
          const request = store.put(data, this.extractId(data))
          request.onsuccess = () => resolve(1)
          request.onerror = () => reject(request.error)
        }
      }

      if (!this.db || !this.isDbOpen()) {
        this.initDB().then(updateData).catch(reject)
      } else {
        updateData()
      }
    })
  }

  /**
   * Retrieves data from an IndexedDB store.
   *
   * @param storeName - The name of the store from which data should be retrieved.
   * @param keys - An array of primary keys to retrieve specific items (optional).
   *
   * @returns A `Promise` that resolves with the retrieved data, an array of data, a single data item, or rejects with an error message.
   */
  async getStoreData<T>(storeName: string, keys?: string[]): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      const getStoreData = () => {
        if (!this.db) {
          return reject('Database is not initialized')
        }

        const transaction = this.db.transaction(storeName, 'readonly', {
          durability: 'strict',
        })
        transaction.onabort = () => {
          reject(transaction.error)
        }

        const store = transaction.objectStore(storeName)

        if (keys && keys.length > 0) {
          const promises = keys.map(
            (key) =>
              new Promise((res, rej) => {
                const request = store.get(key)
                request.onsuccess = () => res(request.result)
                request.onerror = () => {
                  rej(request.error)
                }
              }),
          )

          Promise.all(promises)
            .then((results) => {
              // dbw 5/22/2024: this is a hack to get around the fact that we can't
              // return an array of T[] from a promise.
              resolve(results as T[])
            })
            .catch((e) => reject(e))
        } else {
          const request = store.getAll()
          request.onsuccess = () => {
            try {
              resolve(request.result)
            } catch (e) {
              Sentry.captureException(
                new Error(`Failed to resolve access to store ${storeName}`, {
                  cause: e,
                }),
              )
              reject(e)
            }
          }
          request.onerror = () => {
            reject(request.error)
          }
        }
      }

      if (!this.db || !this.isDbOpen()) {
        this.initDB().then(getStoreData).catch(reject)
      } else {
        getStoreData()
      }
    })
  }

  /**
   * Clears all object stores in the IndexedDB database.
   *
   * @returns A promise that resolves to `true` if all object stores were cleared successfully,
   *          or `false` if an error occurred during the clearing process.
   */
  async clearDB(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const clearDb = () => {
        if (!this.db) {
          return reject('Database is not initialized')
        }

        const objectStoreNames = Array.from(this.db.objectStoreNames)

        if (objectStoreNames.length === 0) {
          resolve(true) // No object stores to clear
        }

        const clearPromises = objectStoreNames.map((storeName: string) => {
          return new Promise<boolean>((resolve, reject) => {
            const transaction = (this.db as IDBDatabase).transaction(
              storeName,
              'readwrite',
              { durability: 'strict' },
            )
            transaction.oncomplete = () => resolve(true)
            transaction.onerror = () => {
              reject(`Error clearing object store: ${transaction.error}`)
            }
            transaction.onabort = () => {
              reject(transaction.error)
            }

            const store = transaction.objectStore(storeName)
            const clearRequest = store.clear()

            clearRequest.onsuccess = () => resolve(true)
            clearRequest.onerror = () => {
              reject(`Error clearing DB: ${clearRequest.error}`)
            }
          })
        })

        Promise.all(clearPromises)
          .then((allClear) => {
            resolve(allClear.every((result: boolean) => result))
          })
          .catch((e) => reject(e))
      }

      if (!this.db || !this.isDbOpen()) {
        this.initDB().then(clearDb).catch(reject)
      } else {
        clearDb()
      }
    })
  }

  /**
   * Deletes the IndexedDB database, permanently removing it.
   *
   * @returns A promise that resolves to `true` if the database was deleted successfully, or `false` on failure.
   */
  async deleteDB(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const deleteDb = () => {
        if (!this.db) {
          return reject('Database is not initialized')
        }

        this.db.close()

        const request = globalThis.indexedDB.deleteDatabase(this.db.name)

        request.onsuccess = () => {
          resolve(true)
        }

        request.onerror = () => {
          reject(`Error deleting database: ${request.error}`)
        }
      }

      if (!this.db || !this.isDbOpen()) {
        this.initDB().then(deleteDb).catch(reject)
      } else {
        deleteDb()
      }
    })
  }

  /**
   * Closes the IndexedDB database. If the database is successfully closed, this method
   * resolves to `true`. If there is an error while closing the database, it resolves to `false`.
   *
   * @returns A promise that resolves to `true` on successful closure or `false` on error.
   */
  async closeDB(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        if (this.db) {
          this.db.close()
          this.db = null // Reset the reference to the database

          // other cleanup or post-closing actions can go here

          resolve(true)
        } else {
          resolve(false)
        }
      } catch (error) {
        reject(`Error closing database: ${error}`)
      }
    })
  }

  async removeQueuedDataForInProgressSession(sessionId: string) {
    if (!this.db || !this.isDbOpen()) {
      await this.initDB()
    }

    const iseEventTransaction = this.db?.transaction(
      Stores.IseEvents,
      'readwrite',
      {
        durability: 'strict',
      },
    )

    if (!iseEventTransaction) {
      throw new Error('Could not open transaction')
    }

    iseEventTransaction.onabort = () => {
      Sentry.captureException(
        new Error('Transaction aborted early for removeQueuedData'),
      )
    }
    iseEventTransaction.onerror = function () {
      Sentry.captureException(iseEventTransaction.error)
    }

    const iseEventStore = iseEventTransaction?.objectStore(Stores.IseEvents)
    const cursorRequest = iseEventStore.openCursor()
    cursorRequest.onsuccess = function (event) {
      // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/30669
      const cursor: IDBCursorWithValue = event.target?.result

      if (cursor) {
        const { key, value } = cursor
        if (value.sessionId === sessionId) {
          iseEventStore.delete(key)
        }
        cursor.continue()
      }
    }
  }

  async removeQueuedDataForEndedSessions(payload: {
    ise_events: any[]
    ise: any[]
  }) {
    if (!this.db || !this.isDbOpen()) {
      await this.initDB()
    }

    // ISE
    const iseTransaction = this.db?.transaction(Stores.Ise, 'readwrite', {
      durability: 'strict',
    })

    if (!iseTransaction) {
      throw new Error('Could not open ISE indexeddb transaction')
    }

    iseTransaction.onabort = () => {
      Sentry.captureException(
        new Error('Transaction aborted early for removeQueuedData'),
      )
    }
    iseTransaction.onerror = function () {
      Sentry.captureException(iseTransaction.error)
    }

    const iseStore = iseTransaction?.objectStore(Stores.Ise)
    iseStore?.clear()

    // ISE Events
    const endedSessions = new Set(
      payload.ise_events
        .filter((iseEvent) => {
          return iseEvent.isStopEvent
        })
        .map((iseEvent) => {
          return iseEvent.sessionId
        }),
    )
    const iseEventTransaction = this.db?.transaction(
      Stores.IseEvents,
      'readwrite',
      {
        durability: 'strict',
      },
    )

    if (!iseEventTransaction) {
      throw new Error('Could not open transaction')
    }

    iseEventTransaction.onabort = () => {
      Sentry.captureException(
        new Error('Transaction aborted early for removeQueuedData'),
      )
    }
    iseEventTransaction.onerror = function () {
      Sentry.captureException(iseEventTransaction.error)
    }

    const iseEventStore = iseEventTransaction?.objectStore(Stores.IseEvents)
    const cursorRequest = iseEventStore.openCursor()
    cursorRequest.onsuccess = function (event) {
      // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/30669
      const cursor: IDBCursorWithValue = event.target?.result

      if (cursor) {
        const { key, value } = cursor
        if (endedSessions.has(value.sessionId)) {
          iseEventStore.delete(key)
        }
        cursor.continue()
      }
    }
  }

  private plusSevenDays(date: Date) {
    return new Date(date.getTime() + SEVEN_DAYS_IN_MILLIS)
  }

  private async clearStore(objectStore: IDBObjectStore) {
    return new Promise((resolve, reject) => {
      const clearRequest = objectStore.clear()
      clearRequest.onsuccess = () => resolve(true)
      clearRequest.onerror = () => reject(clearRequest.error)
    })
  }

  private async copyData(
    from: IDBObjectStore,
    to: IDBObjectStore,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const getAllRequest = from.getAll()
      getAllRequest.onsuccess = () => {
        const items = getAllRequest.result
        const putPromises = items.map((item) =>
          to.put(item, this.extractId(item)),
        )
        Promise.all(putPromises)
          .then(() => resolve())
          .catch((e) => reject(e))
      }
      getAllRequest.onerror = () => reject(getAllRequest.error)
    })
  }

  private extractId(identifiable: Identifiable) {
    return identifiable.id || identifiable.uuid
  }

  async drainQueue() {
    const payload = await this.getQueuedEvents()

    const newOfflineSession = localStorage.getItem('new-session-to-post')
    if (newOfflineSession) {
      await createNewOfflineSession(newOfflineSession)
    }

    if (payload.ise.length < 1 && payload.ise_events.length < 1) {
      return
    }

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('x-csrftoken', window.CSRFTOKEN)
    const response = await fetch(`/api/offline/offline-session-events/`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers,
    })

    if (response.ok) {
      await this.removeQueuedDataForEndedSessions(payload)
    } else {
      throw new Error('failed to drain queue')
    }
  }

  async removeExpiredData() {
    const now = new Date()
    const dataStoresToExpire = [Stores.IseEvents, Stores.Ise]

    dataStoresToExpire.map(async (storeName) => {
      if (!this.db || !this.isDbOpen()) {
        await this.initDB()
      }

      const transaction = this.db?.transaction(storeName, 'readwrite', {
        durability: 'strict',
      })

      if (!transaction) {
        throw new Error('Could not open transaction')
      }

      transaction.onabort = () => {
        Sentry.captureException(
          new Error('Transaction aborted early for removeExpiredData'),
        )
      }

      const store = transaction.objectStore(storeName)
      const request = store.openCursor()

      // Silently fail
      request.onerror = function (event) {
        Sentry.captureException(
          'Error removing expired data:',
          // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/30669
          event.target?.error,
        )
      }

      request.onsuccess = (event) => {
        // @ts-expect-error: https://github.com/microsoft/TypeScript/issues/30669
        const cursor: IDBCursorWithValue = event.target?.result
        if (cursor) {
          const { timestamp } = cursor.value
          if (timestamp && this.plusSevenDays(timestamp) < now) {
            store?.delete(cursor.key)
          }
          cursor.continue()
        }
      }
    })
  }

  async hasQueuedEvents(sessionId: string): Promise<boolean> {
    const queuedEvents = await this.getStoreData<OfflineEvent>(Stores.IseEvents)
    if (!queuedEvents) {
      return false
    }

    return queuedEvents.some((event) => event.sessionId === sessionId)
  }

  async getQueuedEvents() {
    const iseEvents = await this.getStoreData<OfflineEvent>(Stores.IseEvents)
    const ise = await this.getStoreData(Stores.Ise)
    const endEvents = iseEvents.filter((event) => {
      if (!event.event) {
        return false
      }

      return event.isStopEvent
    })
    const endedSessions = new Set(endEvents.map((event) => event.sessionId))

    if (!endedSessions.size) {
      return { ise_events: [], ise }
    }

    const queuedEvents = iseEvents.filter((event) =>
      endedSessions.has(event.sessionId),
    )

    return { ise_events: queuedEvents, ise }
  }

  async getLastUpdatedAt(): Promise<number> {
    const ise = await this.getStoreData<{ timestamp: number }>(
      Stores.LastUpdatedAt,
    )
    return ise[0]?.timestamp
  }

  async setLastUpdatedAt(timestamp: number) {
    await this.updateData(Stores.LastUpdatedAt, {
      id: 1, // always use 1 so we overwrite the old key when we get a new one
      timestamp,
    })
  }

  private isDbOpen() {
    if (!this.db) {
      return false
    }

    try {
      this.db.transaction(Stores.Client)
    } catch (err) {
      Sentry.captureException(
        new Error('Indexeddb was closed when it was expected to be open', {
          cause: err,
        }),
      )
      return false
    }

    return true
  }
}
