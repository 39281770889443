import { NotificationWSClientEvents } from './constants'

export const notificationsListMessage = () => ({
  ws_type: 'notifications',
  message: {
    event_type: NotificationWSClientEvents.LIST,
  },
})

export const notificationsCountMessage = () => ({
  ws_type: 'notifications',
  message: {
    event_type: NotificationWSClientEvents.COUNT,
  },
})

export const notificationDismissMessage = (notificationID: string) => ({
  ws_type: 'notifications',
  message: {
    event_type: NotificationWSClientEvents.DISMISS,
    data: {
      uuid: notificationID,
    },
  },
})

export const notificationMarkAsReadMessage = (notificationID: string) => ({
  ws_type: 'notifications',
  message: {
    event_type: NotificationWSClientEvents.MARK_AS_READ,
    data: {
      uuid: notificationID,
    },
  },
})

export const notificationMarkAsUnreadMessage = (notificationID: string) => ({
  ws_type: 'notifications',
  message: {
    event_type: NotificationWSClientEvents.MARK_AS_UNREAD,
    data: {
      uuid: notificationID,
    },
  },
})

export const notificationMarkAllAsReadMessage = () => ({
  ws_type: 'notifications',
  message: {
    event_type: NotificationWSClientEvents.MARK_ALL_READ,
  },
})
