import { z } from 'zod'

import { ProgramAttributes } from '../../programSchema'
import { ProgramDataCollectionMethod } from '../programDataCollectionMethodSchema'

const promptStatusEnum = z.enum(['correct', 'incorrect', 'hidden'])

// TODO: This should be cleaned up in DB on envs for TA programs

// const advancementLevelsCriterias = z.object({
//   reduceAfter: z.number(),
//   increaseAfter: z.number(),
// })

// const fadingSchema = z.object({
//   showAllPrompts: z.boolean(),
//   advancementLevelsCriterias,
// })

const promptSchema = z.object({
  status: promptStatusEnum,
  abbreviation: z.string().optional(),
  promptTitle: z.string(),
})

export const programTaskAnalysisAttributesSchema = z.object({
  //   fading: fadingSchema,
  prompts: z.array(promptSchema),
})

export type ProgramTaskAnalysisPrompt = z.infer<typeof promptSchema>

export type ProgramTaskAnalysisAttributes = z.infer<
  typeof programTaskAnalysisAttributesSchema
>

// Assertion

export function assertAttributesIsTaskAnalysisAttributes(
  attributes: ProgramAttributes,
  dataCollectionMethod: ProgramDataCollectionMethod,
): asserts attributes is ProgramTaskAnalysisAttributes {
  if (dataCollectionMethod !== 'task_analysis') {
    throw new Error('Task analysis attributes must include fading and prompts')
  }
}
