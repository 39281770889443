import { z } from 'zod'

export const taskAnalysisResultsSchema = z.object({
  trial: z.number(),
  result: z.object({
    abbreviation: z.string().optional(),
    promptTitle: z.string(),
    status: z.enum(['correct', 'incorrect', 'hidden']),
  }),
})

export const iseSessionTargetStateTaskAnalysisSchema = z.object({
  dataCollectionMethod: z.literal('task_analysis'),
  results: z.array(taskAnalysisResultsSchema),
  startTime: z.number(),
  trial: z.number(),
})

export type TargetStateTaskAnalysisResults = z.infer<
  typeof taskAnalysisResultsSchema
>
export type TargetStateTaskAnalysisResult =
  TargetStateTaskAnalysisResults['result']

export type ISESessionTargetStateTaskAnalysis = z.infer<
  typeof iseSessionTargetStateTaskAnalysisSchema
>
