import { z } from 'zod'

import { freeOperantSchema } from '@/tp/models/free-operants'

export const TREATMENT_PLAN_STATUSES = [
  'not_started',
  'active',
  'completed',
] as const

export type TreatmentPlanStatus = (typeof TREATMENT_PLAN_STATUSES)[number]

export const TREATMENT_PLAN_PLAN_TYPES = [
  'focused',
  'comprehensive',
  'n/a',
] as const

export type TreatmentPlanPlanType = (typeof TREATMENT_PLAN_PLAN_TYPES)[number]

export const treatmentPlanSchema = z.object({
  id: z.string(),
  planType: z.enum(TREATMENT_PLAN_PLAN_TYPES, {
    errorMap: (_issue, _ctx) => ({ message: 'Invalid plan type' }),
  }),
  startAt: z.string().pipe(z.coerce.date()),
  endAt: z.string().pipe(z.coerce.date()),
  status: z.enum(TREATMENT_PLAN_STATUSES, {
    errorMap: (_issue, _ctx) => ({ message: 'Invalid status' }),
  }),
  timeframe: z.string(),
  title: z.string(),
  canBeActivated: z.boolean(),
  client: z.object({
    id: z.string(),
    mrn: z.number(),
    initials: z.string(),
    timezone: z.string(),
    avatarUrl: z.string(),
    shortFullName: z.string(),
  }),
  freeOperants: z.array(freeOperantSchema),
})

export type TreatmentPlan = z.infer<typeof treatmentPlanSchema>
