import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['clientsProgramForm', 'client', 'program']

  connect() {
    //trigger changes of header selects
    this.clientTarget.addEventListener('change', (e) => {
      if (e.target.value) {
        this.programTarget.value = ''
        this.clientsProgramFormTarget.submit()
      } else {
        this.clientTarget.value = ''
        this.programTarget.value = ''
        this.clientsProgramFormTarget.submit()
      }
    })

    this.programTarget.addEventListener('change', (_e) => {
      this.clientsProgramFormTarget.submit()
    })
  }
}
