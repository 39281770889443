import React from 'react'
import { Tabs as MantineTabs, TabsProps } from '@mantine/core'

import classes from './Tabs.module.scss'

export const Tabs = ({ classNames, ...props }: TabsProps) => {
  return (
    <MantineTabs
      classNames={{
        ...classes,
        ...classNames,
      }}
      {...props}
    />
  )
}

Tabs.List = MantineTabs.List
Tabs.Tab = MantineTabs.Tab
Tabs.Panel = MantineTabs.Panel
