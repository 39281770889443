import { useEffect, useRef } from 'react'

import { GenericWSEvent } from '@/core/services/websocket/schemas'

import { DocumentClaimServerMessage, DocumentsServerWSEvent } from '../schemas'

interface DocumentsMessagesEventsProps {
  lastJsonMessage: GenericWSEvent | DocumentsServerWSEvent | null
  onClaimChange?: (message: DocumentClaimServerMessage) => void
}

export const useDocumentsMessagesEvents = ({
  lastJsonMessage,
  onClaimChange,
}: DocumentsMessagesEventsProps) => {
  const lastProcessedMessageRef = useRef<
    GenericWSEvent | DocumentsServerWSEvent | null
  >(null)

  useEffect(() => {
    if (!lastJsonMessage) return

    if (lastProcessedMessageRef.current === lastJsonMessage) return
    lastProcessedMessageRef.current = lastJsonMessage

    const { ws_type, message } = lastJsonMessage

    if (ws_type === 'documents') {
      switch (message.event_type) {
        case 'user_document_claim':
          onClaimChange?.(message)
          break
      }
    }
  }, [lastJsonMessage, onClaimChange])
}
