import colors from 'tailwindcss/colors'

// GREEN COLORS is based on https://tailwindcss.com/docs/customizing-colors - Green palette

const GREEN_50 = '#f0fdf4'
const GREEN_100 = '#dcfce7'
const GREEN_200 = '#bbf7d0'
const GREEN_300 = '#86efac'
const GREEN_400 = '#4ade80'
const GREEN_500 = '#22c55e'
const GREEN_600 = '#16a34a'
const GREEN_700 = '#15803d'
const GREEN_800 = '#166534'
const GREEN_900 = '#14532d'
const GREEN_950 = '#052e16'

export const GREEN_MANTINE_PALLETE = [
  GREEN_50,
  GREEN_100,
  GREEN_200,
  GREEN_300,
  GREEN_400,
  GREEN_500,
  GREEN_600,
  GREEN_700,
  GREEN_800,
  GREEN_900,
  GREEN_950,
] as const

export const GREEN_TAILWIND_PALLETE = colors.green
