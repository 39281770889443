import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    // verify address count
    this.updateDetailCount()
  }

  updateDetailCount() {
    // NOTE: without the timeout, this wouldn't always run when deleteButton was initiated in the jquery.formset ¯\_(ツ)_/¯
    setTimeout(() => {
      // get all detail forms that aren't set to be deleted or hidden
      const details = this.element.querySelectorAll(
        'li[data-formset]:not([data-formset-deleted="true"]):not([style="display: none;"])',
      )

      // update the indexing label based on visible detail
      details.forEach((detail, i) => {
        const detailLabel = detail
          .querySelector('h2')
          .textContent.match(/[a-zA-Z]+/g)
        detail.querySelector('h2').textContent = `${detailLabel[0]} ${i + 1}`
      })
    }, 10)
  }
}
