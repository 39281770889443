import { parseObservations, parseSections } from './parse'

export default class PostSessionTimeline {
  constructor(sections, observations) {
    this._sections = parseSections(sections)
    this._observations = parseObservations(observations)

    this._events = generateEvents(this._sections, this._observations)
    this._eventIndex = generateEventIndex(this._events)
    this._eventIndexReverse = generateEventIndexReverse(this._events)
    this._eventRecordingIndex = generateEventRecordingIndex(
      this._events,
      this._sections,
    )
  }

  get startAt() {
    return this._sections[0].startAt
  }

  get endAt() {
    return this._sections[this._sections.length - 1].endAt
  }

  get sectionsCount() {
    return this._sections.length
  }

  get events() {
    return this._events
  }

  get eventsCount() {
    return this._eventIndex.length
  }

  recording(sectionIndex, recordingIndex) {
    return this._sections[sectionIndex].recordings[recordingIndex]
  }

  event(sectionIndex, eventIndex) {
    return this._events[sectionIndex][eventIndex]
  }

  sectionEventsCount(sectionIndex) {
    return this._events[sectionIndex].length
  }

  event2DIndex(flatIndex) {
    return this._eventIndex[flatIndex]
  }

  event1DIndex(sectionIndex, eventIndex) {
    return this._eventIndexReverse[sectionIndex][eventIndex]
  }

  eventRecording(sectionIndex, eventIndex) {
    return this._eventRecordingIndex[sectionIndex][eventIndex]
  }

  /**
   * Find the latest occuring event 1D index in the specified recording, having
   * occurred before the specified time
   */
  latestEvent1DIndexInRecordingBeforeTime(sectionIndex, recordingIndex, time) {
    // Find the indices of events in the recording that have occurred before time
    const eventIndices = this._events[sectionIndex]
      .map((event, eventIndex) => ({ event, eventIndex }))
      .filter(({ event, eventIndex }) => {
        const recording = this._eventRecordingIndex[sectionIndex][eventIndex]
        const isActiveRecording = recording.recordingIndex === recordingIndex
        const hasEventOccurred = event.time <= time

        return isActiveRecording && hasEventOccurred
      })
      .map(
        ({ eventIndex }) => this._eventIndexReverse[sectionIndex][eventIndex],
      )
    if (eventIndices.length > 0) {
      // The last event in the list is the latest event. Return that index.
      return eventIndices[eventIndices.length - 1]
    } else {
      return -1
    }
  }

  /**
   * Find the last occuring event 2D index in the specified recording
   */
  latestEvent2DIndexInRecording(sectionIndex, recordingIndex) {
    // Find the indices of events in the recording
    const eventIndices = this._events[sectionIndex]
      .map((_, eventIndex) => eventIndex)
      .filter((eventIndex) => {
        const recording = this._eventRecordingIndex[sectionIndex][eventIndex]
        const isActiveRecording = recording.recordingIndex === recordingIndex

        return isActiveRecording
      })
    if (eventIndices.length > 0) {
      // The last event in the list is the latest event. Return that index.
      return eventIndices[eventIndices.length - 1]
    } else {
      return -1
    }
  }
}

function generateEvents(sections, observations) {
  const events = []

  // Generate individual event arrays for each section
  for (let i = 0; i < sections.length; i++) {
    const sectionEvents = []
    const section = sections[i]

    // Section start/pause event
    if (i === 0) {
      sectionEvents.push({
        type: 'session_start',
        time: section.startAt,
      })
    } else {
      const prevSection = sections[i - 1]
      const pauseDuration = section.startAt - prevSection.endAt

      sectionEvents.push({
        type: 'session_pause',
        time: section.startAt,
        pauseDuration,
        reason: prevSection.pauseReason,
      })
    }

    // Section end event
    if (i === sections.length - 1) {
      sectionEvents.push({
        type: 'session_end',
        time: section.endAt,
      })
    }

    // Recording events
    for (let j = 0; j < section.recordings.length; j++) {
      const recording = section.recordings[j]

      // Don't include recording start event if it starts with the section
      const startIntersects = j === 0 && recording.startAt === section.startAt
      if (!startIntersects) {
        sectionEvents.push({
          type: 'recording_start',
          time: recording.startAt,
        })
      }

      // Don't include recording end event if it ends with the section
      const endIntersects =
        j === section.recordings.length - 1 && recording.endAt === section.endAt
      if (!endIntersects) {
        sectionEvents.push({
          type: 'recording_end',
          time: recording.endAt,
        })
      }
    }

    events.push(sectionEvents)
  }

  // Observation events.
  // Iterate through sections as createdAt increases in order to determine
  // correct sectionIndex for each observation.
  // Assumes observations and sections are in ascending order by time.
  let sectionIndex = 0
  let section = sections[sectionIndex]
  let sectionEvents = events[sectionIndex]
  for (let i = 0; i < observations.length; i++) {
    const observation = observations[i]

    // Increment the section while this observation occurs after the section's end
    while (observation.createdAt >= section.endAt) {
      sectionIndex++
      if (sectionIndex === sections.length) {
        throw new Error(
          'observation createdAt out of range defined by sections',
        )
      }
      section = sections[sectionIndex]
      sectionEvents = events[sectionIndex]
    }

    sectionEvents.push({
      type: 'observation',
      time: observation.createdAt,
      observation,
    })
  }

  // Iterate events by section to sort ascending by time
  for (let i = 0; i < sections.length; i++) {
    const sectionEvents = events[i]
    sectionEvents.sort((a, b) => a.time - b.time)
  }

  return events
}

function generateEventIndex(events) {
  const index = []
  events.forEach((sectionEvents, sectionIndex) => {
    sectionEvents.forEach((_, eventIndex) => {
      index.push([sectionIndex, eventIndex])
    })
  })

  return index
}

function generateEventIndexReverse(events) {
  let flatIndex = 0
  const index = []
  for (let i = 0; i < events.length; i++) {
    index.push([])
    const sectionEvents = events[i]
    for (let j = 0; j < sectionEvents.length; j++) {
      index[i].push(flatIndex++)
    }
  }

  return index
}

function generateEventRecordingIndex(events, sections) {
  const index = Array(events.length)
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i]
    const sectionEvents = events[i]
    index[i] = Array(sectionEvents.length)
      .fill(0)
      .map(() => ({
        sectionIndex: i,
        hasRecording: false,
        nextRecordingIndex: -1,
        recordingIndex: -1,
      }))

    if (section.recordings.length === 0) {
      continue
    }

    let recordingIndex = 0
    let recording = section.recordings[recordingIndex]
    for (let j = 0; j < sectionEvents.length; j++) {
      const event = sectionEvents[j]

      while (event.time > recording.endAt) {
        recordingIndex++
        if (recordingIndex === section.recordings.length) {
          break
        }
        recording = section.recordings[recordingIndex]
      }
      if (recordingIndex === section.recordings.length) {
        break
      }

      const isBeforeRecording = event.time < recording.startAt
      if (isBeforeRecording) {
        index[i][j].nextRecordingIndex = recordingIndex
      } else {
        index[i][j].hasRecording = true
        index[i][j].recordingIndex = recordingIndex
      }
    }
  }

  return index
}
