import { useCallback } from 'react'
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types'

import {
  notificationDismissMessage,
  notificationMarkAllAsReadMessage,
  notificationMarkAsReadMessage,
  notificationMarkAsUnreadMessage,
  notificationsListMessage,
  notificationsCountMessage,
} from '../messages'

export const useNotificationActions = ({
  sendJsonMessage,
}: {
  sendJsonMessage: SendJsonMessage
}) => {
  const getNotificationsCount = useCallback(() => {
    sendJsonMessage(notificationsCountMessage())
  }, [sendJsonMessage])

  const getNotificationsList = useCallback(() => {
    sendJsonMessage(notificationsListMessage())
  }, [sendJsonMessage])

  const dismissNotification = useCallback(
    (id: string) => {
      sendJsonMessage(notificationDismissMessage(id))
    },
    [sendJsonMessage],
  )

  const markAsRead = useCallback(
    (id: string) => {
      sendJsonMessage(notificationMarkAsReadMessage(id))
    },
    [sendJsonMessage],
  )

  const markAsUnread = useCallback(
    (id: string) => {
      sendJsonMessage(notificationMarkAsUnreadMessage(id))
    },
    [sendJsonMessage],
  )

  const markAllAsRead = useCallback(() => {
    sendJsonMessage(notificationMarkAllAsReadMessage())
  }, [sendJsonMessage])

  return {
    getNotificationsCount,
    getNotificationsList,
    dismissNotification,
    markAsRead,
    markAsUnread,
    markAllAsRead,
  }
}
