import { format } from 'date-fns'
import i18n from 'i18next'

export const formatExpireAtDate = (date: string) => {
  const formattedDate = format(date, "MMM dd, yyyy 'at' h:mma")

  return i18n.t(`Expires {{formattedDate}}`, {
    ns: 'common',
    formattedDate,
  })
}
