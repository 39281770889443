export const buildWebSocketUrl = (path: string) => {
  verifyPathStartsWithSlash(path)

  const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'

  return `${wsProtocol}//${window.location.host}${path}`
}

const verifyPathStartsWithSlash = (path: string) => {
  if (path[0] !== '/') {
    throw new Error(`Path ${path} does not start with a slash`)
  }
}
