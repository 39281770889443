import { z } from 'zod'

export const partialIntervalResultSchema = z.object({
  interval: z.number(),
  result: z.boolean(),
  trial: z.number(),
})

export const iseSessionTargetStatePartialInterval = z.object({
  dataCollectionMethod: z.literal('partial_interval'),
  latestInterval: z.number().optional(),
  results: z.array(partialIntervalResultSchema),
  startTime: z.number(),
  trial: z.number(),
})

export type PartialIntervalResult = z.infer<typeof partialIntervalResultSchema>

export type ISESessionTargetStatePartialInterval = z.infer<
  typeof iseSessionTargetStatePartialInterval
>
