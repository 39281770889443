import React from 'react'

import { formatExpireAtDate } from './helpers'

interface ExpiredAtLabelProps {
  expiredAt: string
}

export const ExpiredAtLabel = ({ expiredAt }: ExpiredAtLabelProps) => {
  return (
    <div className="text-xs text-gray-400">{formatExpireAtDate(expiredAt)}</div>
  )
}
