import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  resetUnits() {
    const unitsField = document.getElementById('id_units')
    if (unitsField) {
      unitsField.value = null
    }
  }
}
