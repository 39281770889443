import { z } from 'zod'

export const iseSessionTargetStateEstimatedMasterySchema = z.object({
  dataCollectionMethod: z.literal('estimated_mastery'),
  mastery: z.number(),
})

export type ISESessionTargetStateEstimatedMastery = z.infer<
  typeof iseSessionTargetStateEstimatedMasterySchema
>
