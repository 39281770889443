import colors from 'tailwindcss/colors'

// RED COLORS is based on https://tailwindcss.com/docs/customizing-colors - Red palette

const RED_50 = '#fef2f2'
const RED_100 = '#fee2e2'
const RED_200 = '#fecaca'
const RED_300 = '#fca5a5'
const RED_400 = '#f87171'
const RED_500 = '#ef4444'
const RED_600 = '#dc2626'
const RED_700 = '#b91c1c'
const RED_800 = '#991b1b'
const RED_900 = '#7f1d1d'
const RED_950 = '#450a0a'

export const RED_MANTINE_PALETTE = [
  RED_50,
  RED_100,
  RED_200,
  RED_300,
  RED_400,
  RED_500,
  RED_600,
  RED_700,
  RED_800,
  RED_900,
  RED_950,
] as const

export const RED_TAILWIND_PALETTE = colors.red
