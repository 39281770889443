import React, { PropsWithChildren } from 'react'
import { MantineProvider as CoreMantineProvider } from '@mantine/core'

import { useCustomTheme } from './hooks'
import { mantineProviderConfig } from './configs'

export const MantineProvider = ({ children }: PropsWithChildren) => {
  const theme = useCustomTheme()

  const customConfig = {
    ...mantineProviderConfig,
    theme,
  }

  return <CoreMantineProvider {...customConfig}>{children}</CoreMantineProvider>
}
