import { IconProps } from '@/core/components/icons/Icon'
import { ProgramStatus } from '@/core/models/program/constants'
import { MultiSwitchButtonsConfig } from '@/core/components/forms/inputs/multiple-icons-switch/types'

export const FR_DCM = 'frequency_and_rate'
export const FR_TIME_REPORT_TYPES = ['MIN', 'HR']
export const DURATION_REPORT_TYPES = ['AVG', 'TOT'] as const
export const CALCULATION_TYPES = ['each_session', 'average'] as const
export const TRIALS_CRITERIA_TYPES = ['min_trials', 'any_trials'] as const
export const FREQUENCY_AND_RATE_REPORT_TYPES = ['FRE', 'MIN', 'HR'] as const
export const PROPMT_DCM = ['percent_of_opportunities', 'task_analysis']

export const PERCENT_DCM_LIST = [
  'task_analysis',
  'estimated_mastery',
  'percent_of_opportunities',
]

export const OBJECTIVE_STATUSES = [
  'active',
  'on_hold',
  'mastered',
  'archived',
  'not_started',
  'maintenance',
] as const

export const OPERATOR_TYPES = [
  'equal_to',
  'less_than',
  'greater_than',
  'less_than_or_equal_to',
  'greater_than_or_equal_to',
] as const

export const PROGRAM_STATUS_COLORS = {
  on_hold: 'text-amber-500',
  completed: 'text-primary',
  maintenance: 'text-primary',
  active: 'text-status-success',
  archived: 'text-secondary-text',
  not_started: 'text-secondary-text',
} as const satisfies Record<ProgramStatus, string>

export const PROGRAM_STATUS_ICONS = {
  active: 'check',
  on_hold: 'pause',
  archived: 'lock',
  not_started: 'block',
  completed: 'mastery',
  maintenance: 'mastery',
} as const satisfies Record<ProgramStatus, IconProps['icon'] | undefined>

export const PROGRAM_GOAL_INTERVENTIONS = [
  'fading',
  'shaping',
  'chaining',
  'matrix_training',
  'incidental_teaching',
  'discrete_trial_teaching',
  'pivotal_response_therapy',
  'natural_language_paradigm',
  'differential_reinforcement',
  'natural_environment_teaching',
  'functional_communication_training',
] as const

export const PROMPT_LEVEL_STATUS_WIDGET_CONFIG: MultiSwitchButtonsConfig[] = [
  {
    icon: 'close_circle',
    activeColor: 'text-red-500',
    status: 'incorrect',
    disabledColor: 'text-secondary-text',
  },
  {
    icon: 'check_circle',
    activeColor: 'text-green-500',
    status: 'correct',
    disabledColor: 'text-secondary-text',
  },
  {
    icon: 'hidden',
    activeColor: 'text-blue-500',
    status: 'hidden',
    disabledColor: 'text-secondary-text',
  },
]

export type OperatorType = (typeof OPERATOR_TYPES)[number]
export type CalculationType = (typeof CALCULATION_TYPES)[number]
export type DurationReportTypes = (typeof DURATION_REPORT_TYPES)[number]
export type FrequencyAndRateReportTypes =
  (typeof FREQUENCY_AND_RATE_REPORT_TYPES)[number]
