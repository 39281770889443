import * as Sentry from '@sentry/react'

import {
  SYNC_EVENT_TYPES,
  SyncEventBusManagerInterface,
} from '../interfaces/SyncEventBusManagerInterface'

import SyncEventBusManager from './SyncEventBusManager'

const PERCENT_OF_STORAGE_TO_WARN_AT = 0.9

class StorageMonitor {
  private syncEventBusManager: SyncEventBusManagerInterface

  constructor() {
    this.syncEventBusManager = new SyncEventBusManager()

    this.syncEventBusManager.addEventListener(
      SYNC_EVENT_TYPES.SUCCESS,
      this.collect,
    )
  }

  private async collect() {
    const stats = {
      estimate: navigator.storage.estimate
        ? await navigator.storage.estimate()
        : undefined,
      persist: navigator.storage.persist
        ? await navigator.storage.persist()
        : undefined,
      persisted: navigator.storage.persisted
        ? await navigator.storage.persisted()
        : undefined,
    }
    if (
      stats.estimate &&
      stats.estimate.usage &&
      stats.estimate.quota &&
      stats.estimate.usage >
        PERCENT_OF_STORAGE_TO_WARN_AT * stats.estimate.quota
    ) {
      Sentry.captureException(
        new Error(
          `Storage Monitor detected storage at or above ${PERCENT_OF_STORAGE_TO_WARN_AT * 100}%`,
        ),
        {
          extra: {
            stats: JSON.stringify(stats),
          },
        },
      )
    } else {
      console.log('Storage usage is within acceptable limits', stats)
    }
  }
}

export default StorageMonitor
