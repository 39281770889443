import { z } from 'zod'

const PHASE_CHANGE_SCOPES = ['programs', 'targets'] as const
const PHASE_CHANGE_TYPES = ['conditional', 'regular'] as const

export type PhaseChangeType = (typeof PHASE_CHANGE_TYPES)[number]
export type PhaseChangeScope = (typeof PHASE_CHANGE_SCOPES)[number]

const pcProgramsAndTargetsSchema = z.object({
  id: z.string(),
  title: z.string().nullish(),
})

const pcFreeOperantsSchema = z.object({
  id: z.string(),
  name: z.string(),
})

export const phaseChangeSchema = z.object({
  id: z.string(),
  title: z.string().nullable(),
  description: z.string(),
  changeType: z.enum(PHASE_CHANGE_TYPES),
  changeDate: z.string().pipe(z.coerce.date()),
  showOnGraph: z.boolean(),
  applyTo: z.enum(PHASE_CHANGE_SCOPES),
  targets: z.array(pcProgramsAndTargetsSchema).default([]),
  programs: z.array(pcProgramsAndTargetsSchema).default([]),
  freeOperants: z.array(pcFreeOperantsSchema).default([]),
})

export const editPhaseChangeSchema = z.object({
  applyTo: z.string().optional(),
  changeType: z.string().min(1, { message: 'Type is required' }),
  changeDate: z.string().min(1, { message: 'Date is required' }),
  title: z.string().min(1, { message: 'Title is required' }),
  description: z.string().optional().nullable(),
  showOnGraph: z.boolean().optional(),
  targets: z.array(z.string()).optional(),
  programs: z.array(z.string()).optional(),
  freeOperantIds: z.array(z.string()).optional(),
})

export const editPhaseChangeResponseSchema = phaseChangeSchema.pick({
  id: true,
  changeType: true,
  changeDate: true,
  title: true,
  description: true,
  showOnGraph: true,
  applyTo: true,
  targets: true,
  programs: true,
  freeOperants: true,
})

export type EditPhaseChangeParams = z.infer<typeof editPhaseChangeSchema>
export type PhaseChangeResponse = z.infer<typeof editPhaseChangeResponseSchema>
export type PCFreeOperantsSchema = z.infer<typeof pcFreeOperantsSchema>
export type PCProgramsAndTargetsSchema = z.infer<
  typeof pcProgramsAndTargetsSchema
>
