// TEAL BLUE COLORS is base on Spectrum AI color palette (taken from figma)

const TEAL_BLUE_50 = '#EEFBFD'
const TEAL_BLUE_100 = '#CEF0F7'
const TEAL_BLUE_200 = '#B1E1ED'
const TEAL_BLUE_300 = '#99D1E2'
const TEAL_BLUE_400 = '#83C0D4'
const TEAL_BLUE_500 = '#70ADC4'
const TEAL_BLUE_600 = '#609AB3'
const TEAL_BLUE_700 = '#557C91'
const TEAL_BLUE_800 = '#1C5D79'
const TEAL_BLUE_900 = '#204152'

export const TEAL_BLUE_MANTINE_PALLETE = [
  TEAL_BLUE_50,
  TEAL_BLUE_100,
  TEAL_BLUE_200,
  TEAL_BLUE_300,
  TEAL_BLUE_400,
  TEAL_BLUE_500,
  TEAL_BLUE_600,
  TEAL_BLUE_700,
  TEAL_BLUE_800,
  TEAL_BLUE_900,
] as const

export const TEAL_BLUE_TAILWIND_PALLETE = {
  50: TEAL_BLUE_50,
  100: TEAL_BLUE_100,
  200: TEAL_BLUE_200,
  300: TEAL_BLUE_300,
  400: TEAL_BLUE_400,
  500: TEAL_BLUE_500,
  600: TEAL_BLUE_600,
  700: TEAL_BLUE_700,
  800: TEAL_BLUE_800,
  900: TEAL_BLUE_900,
}
