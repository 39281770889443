import { cloneDeep } from 'lodash'

import { BillableSection, ObservationData, SectionData } from '@/ps/models/data'
import {
  DeleteObservationResponse,
  ObservationResponse,
} from '@/ps/models/index'

export default class PostSessionTimeline {
  private _sections: SectionData[]
  private _events: PostSessionTimelineEvent[][]
  private _eventIndex: [number, number][]
  private _eventIndexReverse: number[][]
  private _eventRecordingIndex: EventRecordingIndexEntry[][]
  private _billable_sections: BillableSection[]

  constructor(
    sections: SectionData[],
    events: PostSessionTimelineEvent[][],
    billable_sections: BillableSection[],
  ) {
    this._sections = sections
    this._events = events
    this._eventIndex = generateEventIndex(this._events)
    this._eventIndexReverse = generateEventIndexReverse(this._events)
    this._eventRecordingIndex = generateEventRecordingIndex(
      this._events,
      this._sections,
    )
    this._billable_sections = billable_sections
  }

  public get startAt() {
    return this._billable_sections[0].startAt
  }

  public get endAt() {
    return this._billable_sections[this._billable_sections.length - 1].endAt
  }

  public get sectionsCount() {
    return this._sections.length
  }

  public get events() {
    return this._events
  }

  public get eventsCount() {
    return this._eventIndex.length
  }

  public recording(sectionIndex: number, recordingIndex: number) {
    return this._sections[sectionIndex].recordings[recordingIndex]
  }

  public event(sectionIndex: number, eventIndex: number) {
    return this._events[sectionIndex][eventIndex]
  }

  public sectionEventsCount(sectionIndex: number) {
    return this._events[sectionIndex].length
  }

  public event2DIndex(flatIndex: number) {
    return this._eventIndex[flatIndex]
  }

  public event1DIndex(sectionIndex: number, eventIndex: number) {
    return this._eventIndexReverse[sectionIndex][eventIndex]
  }

  public eventRecording(sectionIndex: number, eventIndex: number) {
    return this._eventRecordingIndex[sectionIndex][eventIndex]
  }

  /**
   * Find the latest occuring event 1D index in the specified recording, having
   * occurred before the specified time
   */
  public latestEvent1DIndexInRecordingBeforeTime(
    sectionIndex: number,
    recordingIndex: number,
    time: Date,
  ) {
    // Find the indices of events in the recording that have occurred before time
    const eventIndices = this._events[sectionIndex]
      .map((event, eventIndex) => ({ event, eventIndex }))
      .filter(({ event, eventIndex }) => {
        const recording = this._eventRecordingIndex[sectionIndex][eventIndex]
        const isActiveRecording = recording.recordingIndex === recordingIndex
        const hasEventOccurred = event.time <= time

        return isActiveRecording && hasEventOccurred
      })
      .map(
        ({ eventIndex }) => this._eventIndexReverse[sectionIndex][eventIndex],
      )
    if (eventIndices.length > 0) {
      // The last event in the list is the latest event. Return that index.
      return eventIndices[eventIndices.length - 1]
    } else {
      return -1
    }
  }

  /**
   * Find the last occuring event 2D index in the specified recording
   */
  public latestEvent2DIndexInRecording(
    sectionIndex: number,
    recordingIndex: number,
  ) {
    // Find the indices of events in the recording
    const eventIndices = this._events[sectionIndex]
      .map((_, eventIndex) => eventIndex)
      .filter((eventIndex) => {
        const recording = this._eventRecordingIndex[sectionIndex][eventIndex]
        const isActiveRecording = recording.recordingIndex === recordingIndex

        return isActiveRecording
      })
    if (eventIndices.length > 0) {
      // The last event in the list is the latest event. Return that index.
      return eventIndices[eventIndices.length - 1]
    } else {
      return -1
    }
  }
}

export type PostSessionTimelineSessionEvent = { time: Date } & (
  | {
      type: 'session_start'
    }
  | {
      type: 'session_pause'
      pauseDuration: number
      reason: string | null
    }
  | {
      type: 'session_end'
    }
  | {
      type: 'recording_start'
    }
  | {
      type: 'recording_end'
    }
  | {
      type: 'timeline_start'
    }
  | {
      type: 'timeline_end'
    }
)

export type PostSessionTimelineObservationEvent = {
  uuid: string
  time: Date
  type: 'observation'
  observation: ObservationData
}

export type PostSessionTimelineEvent =
  | PostSessionTimelineSessionEvent
  | PostSessionTimelineObservationEvent

const generateSectionsEvents = (
  sections: SectionData[],
  billable_sections: BillableSection[],
) => {
  const sectionEvents: PostSessionTimelineEvent[] = []
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i]

    // Section start/pause event
    if (i === 0) {
      sectionEvents.push({
        type: 'timeline_start',
        time: billable_sections[0].startAt,
      })
    } else {
      const prevSection = sections[i - 1]
      const pauseDuration =
        section.startAt.getTime() - prevSection.endAt.getTime()

      sectionEvents.push({
        type: 'session_pause',
        time: section.startAt,
        pauseDuration,
        reason: section.pauseReason,
      })
    }

    // Section end event
    if (i === sections.length - 1) {
      sectionEvents.push({
        type: 'timeline_end',
        time: billable_sections[billable_sections.length - 1].endAt,
      })
    }

    // Recording events
    for (let j = 0; j < section.recordings.length; j++) {
      const recording = section.recordings[j]

      // Don't include recording start event if it starts with the section
      const startIntersects =
        j === 0 && recording.startAt.getTime() === section.startAt.getTime()
      if (!startIntersects) {
        sectionEvents.push({
          type: 'recording_start',
          time: recording.startAt,
        })
      }

      // Don't include recording end event if it ends with the section
      const endIntersects =
        j === section.recordings.length - 1 &&
        recording.endAt.getTime() === section.endAt.getTime()
      if (!endIntersects) {
        sectionEvents.push({
          type: 'recording_end',
          time: recording.endAt,
        })
      }
    }
  }

  return sectionEvents
}

const processObservationsData = (
  observations: ObservationData[],
): PostSessionTimelineObservationEvent[] =>
  observations.map((observation) => ({
    uuid: observation.uuid,
    type: 'observation',
    time: observation.createdAt,
    observation,
  }))

export function generateEvents(
  sections: SectionData[],
  observations: ObservationData[],
  billable_sections: BillableSection[],
): PostSessionTimelineEvent[][] {
  const processedObservations = processObservationsData(observations)
  const allEvents = generateSectionsEvents(sections, billable_sections)
    .concat(processedObservations)
    .sort((a, b) => a.time.getTime() - b.time.getTime())

  const events: PostSessionTimelineEvent[][] = allEvents.reduce((acc, item) => {
    if (item.type === 'session_pause' || !acc.length) {
      // starting new section
      acc.push([item])
    } else {
      // adding events to the last added section
      const lastSection: PostSessionTimelineEvent[] = acc[acc?.length - 1]
      lastSection.push(item)
    }
    return acc
  }, [] as PostSessionTimelineEvent[][])

  return events
}

export function insertIntoEvents(
  newEvent: PostSessionTimelineEvent,
  events: PostSessionTimelineEvent[][],
  afterEventIndex: number,
): PostSessionTimelineEvent[][] {
  //search for index
  const previousEvent = events
    .flatMap((s) => s)
    .find(
      (e, i) => i === afterEventIndex,
    ) as PostSessionTimelineObservationEvent

  return events.map((sectionEvents) => {
    const indexOfEvent = sectionEvents.indexOf(previousEvent)
    //include new event if is on this section an reorder section
    if (indexOfEvent >= 0) {
      const newSectionEvents = sectionEvents.slice(0)
      return [...newSectionEvents, newEvent].sort(
        (a, b) => a.time.getTime() - b.time.getTime(),
      )
    }
    //return normal section otherwise
    return sectionEvents
  })
}

export function updateObservationIntoEvents(
  events: PostSessionTimelineEvent[][],
  updatedObservations: DeleteObservationResponse | ObservationResponse,
): PostSessionTimelineEvent[][] {
  if (Array.isArray(updatedObservations)) {
    return events.map((sectionEvents) => {
      return sectionEvents.map((event) => {
        if (
          event.type === 'observation' &&
          event.observation.type !== 'timestamp'
        ) {
          const index = updatedObservations.findIndex(
            (e) => e.id === event.observation.uuid,
          )
          if (index >= 0) {
            //need to use clonedeep for allowing data change
            const newEvent = cloneDeep(event)
            if (newEvent.observation.type !== 'timestamp') {
              newEvent.observation.data = {
                ...updatedObservations[index].data,
              }
            }
            return newEvent
          }
        }
        return event
      })
    })
  }
  return events
}

function generateEventIndex(
  events: PostSessionTimelineEvent[][],
): [number, number][] {
  const index: [number, number][] = []
  events.forEach((sectionEvents, sectionIndex) => {
    sectionEvents.forEach((_, eventIndex) => {
      index.push([sectionIndex, eventIndex])
    })
  })

  return index
}

function generateEventIndexReverse(
  events: PostSessionTimelineEvent[][],
): number[][] {
  let flatIndex = 0
  const index: number[][] = []
  for (let i = 0; i < events.length; i++) {
    index.push([])
    const sectionEvents = events[i]
    for (let j = 0; j < sectionEvents.length; j++) {
      index[i].push(flatIndex++)
    }
  }

  return index
}

export type EventRecordingIndexEntry = {
  sectionIndex: number
  hasRecording: boolean
  nextRecordingIndex: number
  recordingIndex: number
}

function generateEventRecordingIndex(
  events: PostSessionTimelineEvent[][],
  sections: SectionData[],
): EventRecordingIndexEntry[][] {
  const index: EventRecordingIndexEntry[][] = Array(events.length)
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i]
    const sectionEvents = events[i]
    index[i] = Array(sectionEvents.length)
      .fill(0)
      .map(() => ({
        sectionIndex: i,
        hasRecording: false,
        nextRecordingIndex: -1,
        recordingIndex: -1,
      }))

    if (section.recordings.length === 0) {
      continue
    }

    let recordingIndex = 0
    let recording = section.recordings[recordingIndex]
    for (let j = 0; j < sectionEvents.length; j++) {
      const event = sectionEvents[j]

      while (event.time > recording.endAt) {
        recordingIndex++
        if (recordingIndex === section.recordings.length) {
          break
        }
        recording = section.recordings[recordingIndex]
      }
      if (recordingIndex === section.recordings.length) {
        break
      }

      const isBeforeRecording = event.time < recording.startAt
      if (isBeforeRecording) {
        index[i][j].nextRecordingIndex = recordingIndex
      } else {
        index[i][j].hasRecording = true
        index[i][j].recordingIndex = recordingIndex
      }
    }
  }

  return index
}
