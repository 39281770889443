import React from 'react'
import { Radio as MantineRadio, RadioProps } from '@mantine/core'

import { classesMerge } from '@/core/helpers/classes-merge'

import { RadioCard } from './RadioCard'
import { RadioGroup } from './RadioGroup'
import { RadioIndicator } from './RadioIndicator'
import styles from './Radio.module.scss'

export const Radio = ({ classNames, ...props }: RadioProps) => (
  <MantineRadio
    {...props}
    classNames={{
      ...classNames,
      inner: classesMerge(
        styles.inputWrapper,
        classNames && 'inner' in classNames ? classNames.inner : '',
      ),
      radio: classesMerge(
        styles.radio,
        classNames && 'radio' in classNames ? classNames.radio : '',
      ),
      body: classesMerge(
        styles.body,
        classNames && 'body' in classNames ? classNames.body : '',
      ),
    }}
  />
)

Radio.Card = RadioCard
Radio.Group = RadioGroup
Radio.Indicator = RadioIndicator
