import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/core/mantine-ui'
import { Notification } from '@/core/services/websocket/services'

interface NotificationActionsProps {
  actionLink: Notification['action_link']

  onDismiss: () => void
}

export const NotificationActions = ({
  actionLink,
  onDismiss,
}: NotificationActionsProps) => {
  const { t } = useTranslation()

  const handleActionButtonClick = () => {
    if (actionLink) {
      window.open(actionLink.url, '_blank')
    }
  }

  return (
    <div className="flex gap-10">
      {actionLink && (
        <Button size="xs" onClick={handleActionButtonClick}>
          {actionLink.label}
        </Button>
      )}
      <Button variant="white" size="xs" className="pl-0" onClick={onDismiss}>
        {t('Dismiss')}
      </Button>
    </div>
  )
}
