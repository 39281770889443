import { z } from 'zod'

export const programAttachmentsSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  fileUrl: z.string(),
  fileName: z.string(),
})

export type ProgramAttachments = z.infer<typeof programAttachmentsSchema>
