import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import { initReactI18next } from 'react-i18next'

import { config } from '@/tp/config'
import {
  dateFormatter,
  distanceFormatter,
  dateToTimeFormatter,
} from '@/core/helpers/date'

import LOCALES from './config'

const isDev = config.mode === 'development'
const ONE_MONTH_IN_MS = 30 * 7 * 24 * 60 * 60 * 1000

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    debug: isDev,

    // only load the current language's translations
    load: 'currentOnly',

    // by default, only load the common translations
    ns: ['common'],

    // assume common namespace, unless otherwise specified when translating text
    defaultNS: 'common',

    // lookup translation keys in the common namespace, if not found in the specified namespace
    fallbackNS: 'common',

    // if an unrecognized locale is specified, use the first locale in the list of supported locales
    fallbackLng: LOCALES[0],
    supportedLngs: LOCALES,

    // always treat e.g. en-US as en-us
    lowerCaseLng: true,

    // disallow `null` as a translation value
    returnNull: false,

    // we use content as keys
    keySeparator: false,

    // prevents react-i18next from trying to issue an XHR for the JSON translations in tests
    ...(config.mode === 'test'
      ? { resources: { 'en-us': { common: {}, tp: {}, doc: {}, tm: {} } } }
      : {}),

    backend: {
      backends: [LocalStorageBackend, HttpBackend],

      backendOptions: [
        {
          prefix: 'spectrum_locale_',
          versions: config.i18nVersions,
          expirationTime: isDev ? 0 : ONE_MONTH_IN_MS,
        },
        {
          loadPath: '/static/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },

    // escaping interpolated values is not needed for react since it escapes by default
    interpolation: {
      escapeValue: false,
      formatSeparator: '|',
    },

    detection: {
      caches: ['cookie', 'localStorage'],
      lookupCookie: 'preferred_language',
      lookupLocalStorage: 'preferredLanguage',
    },

    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      if (isDev) {
        throw new Error(
          `No translation found for language "${lng}" for "${key}" in namespace "${ns}". Fallback is ${fallbackValue}.`,
        )
      }
    },
  })

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng)
})

i18n.services.formatter?.add('distance', (value, lng, options) =>
  distanceFormatter(value, lng || 'en-us', options),
)

i18n.services.formatter?.add('date', (value, lng, options) =>
  dateFormatter(value, lng || 'en-us', options),
)

i18n.services.formatter?.add('time', (value, lng, options) =>
  dateToTimeFormatter(value, lng || 'en-us', options),
)

export default i18n
