import { DateTime } from 'luxon'

// pad out numbers so they are always in 00 format
export const padNumber = (val) => {
  return val > 9 ? val : `0${val}`
}

export const toMinutes = (ms) => {
  var minutes = Math.floor(ms / 60000)
  var seconds = ((ms % 60000) / 1000).toFixed(0)
  return seconds == 60
    ? minutes + 1 + ':00'
    : minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

export const toDuration = (ms, includeZeroHours = true) => {
  const hours = Math.floor(ms / (60 * 60 * 1000))
  const minutes = Math.floor(ms / (60 * 1000)) % 60
  const seconds = Math.floor(ms / 1000) % 60

  return `${
    includeZeroHours || hours > 0 ? `${hours < 10 ? '0' : ''}${hours}:` : ''
  }${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

export const toDurationList = (ms) => {
  const hours = Math.floor(ms / (60 * 60 * 1000))
  const minutes = Math.floor(ms / (60 * 1000)) % 60
  const seconds = Math.floor(ms / 1000) % 60

  const s = []
  if (hours > 0) {
    s.push(`${hours}hr${hours !== 1 ? 's' : ''}`)
  }
  if (minutes > 0) {
    s.push(`${minutes}m`)
  }
  if (hours === 0) {
    s.push(`${seconds}s`)
  }

  return s.join(' ')
}

export const toAbsoluteHoursAndMinutes = (ms) => {
  const d = DateTime.fromMillis(ms, { zone: window.timezone })
  const h = d.hour
  const m = d.minute

  return `${h % 12 || 12}:${m < 10 ? '0' : ''}${m} ${h >= 12 ? 'PM' : 'AM'}`
}

//Django docs function to get cookie data
export const getCookie = (name) => {
  var cookieValue
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';')
    for (var i = 0; i < cookies.length; i++) {
      /* eslint-disable no-undef */
      var cookie = jQuery.trim(cookies[i])
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

//get csrftoken from hx-headers
export const getCsrfToken = () => {
  const hxHeaders = document
    .querySelector('body[hx-headers]')
    ?.getAttribute('hx-headers')
  const csrftoken = hxHeaders && JSON.parse(hxHeaders)['X-CSRFToken']

  return csrftoken
}

export const getInitialsFromFullName = (name) => {
  return name
    .split(' ')
    .map((value) => value.slice(0, 1))
    .join('')
}

/**
 *
 * @param { string | number | Date } date needed date, returns current day otherwise
 * @returns { Date } Date Object
 */
export const getBeginingOfDate = (date = Date.now()) => {
  const d = new Date(date)
  d.setHours(0, 0, 0, 0)
  return d
}

/**
 *
 * @returns { Date } Date Object on UTC
 */
export const getCurrentUtcDate = () => {
  return Date.now()
}
