import React from 'react'
import { Accordion as MantineAccordion } from '@mantine/core'

import { classesMerge } from '@/core/helpers/classes-merge'

import { AccordionProps } from './types'
import styles from './Accordion.module.scss'

export const Accordion: React.FC<AccordionProps> = ({
  list,
  chevronPosition = 'left',
  multiple = true,
  classNames = {},
  ...props
}) => {
  return (
    <MantineAccordion
      chevronPosition={chevronPosition}
      multiple={multiple}
      data-testid="accordion-root"
      classNames={{
        ...classNames,
        root: classesMerge(
          styles.root,
          'root' in classNames ? classNames.root : '',
        ),
        chevron: styles.chevron,
        control: classesMerge(
          styles.control,
          'control' in classNames ? classNames.control : '',
        ),
        item: styles.item,
      }}
      {...props}
    >
      {list.map((item) => (
        <MantineAccordion.Item
          key={item.value}
          value={item.value}
          {...item.itemProps}
        >
          <MantineAccordion.Control {...item.controlProps}>
            {item.title}
          </MantineAccordion.Control>
          <MantineAccordion.Panel {...item.panelProps}>
            {item.details}
          </MantineAccordion.Panel>
        </MantineAccordion.Item>
      ))}
    </MantineAccordion>
  )
}
