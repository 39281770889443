import { DocumentClaimServerMessage } from '@/core/services/websocket/services/documents-ws-provider/schemas'

interface DocumentsWSProviderProps {
  onClaimChange?: (message: DocumentClaimServerMessage) => void
}

export const useDocumentsEventHandlers = ({
  onClaimChange,
}: DocumentsWSProviderProps) => {
  const handleClaimChange = (message: DocumentClaimServerMessage) => {
    onClaimChange?.(message)
  }

  return { handleClaimChange }
}
