export const iseExistsInCache = async () => {
  // check if sessions tables aren't empty
  const joinLink = document
    .querySelector('a[data-testid="sessionIdentityButton"]')
    ?.getAttribute('href')

  if (!joinLink) {
    // empty sessions list so we have everything cached
    return true
  }

  const cache = await caches.open('twyll-offline')
  const keys = await cache?.keys()
  const urlExists = keys?.some((request) => request.url.includes(joinLink))

  return urlExists
}

export const removeNullSegments = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key]
    } else if (typeof obj[key] === 'object') {
      removeNullSegments(obj[key])
    }
  }

  return obj
}
