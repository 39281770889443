import { Controller } from '@hotwired/stimulus'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default class extends Controller {
  static targets = ['link', 'section']
  static values = {
    triggerStart: String('top'),
    triggerEnd: String('bottom'),
  }

  connect() {
    // setup scroll triggers
    this.sectionTargets.forEach((section) => {
      ScrollTrigger.create({
        trigger: section,
        start: this.triggerStartValue,
        end: this.triggerEndValue,
        // markers: true, // uncomment for debugger
        onEnter: () => {
          this.updateActive(section.id)
        },
        onEnterBack: () => {
          this.updateActive(section.id)
        },
      })
    })
  }

  disconnect() {
    // kill scroll trigger
    ScrollTrigger.kill()
  }

  refresh() {
    // refresh the scroll trigger positions
    ScrollTrigger.refresh()
  }

  updateActive(id) {
    // remove all active states from links
    this.linkTargets.forEach((link) => delete link.dataset.state)

    // add active state to link of current section
    let currentSectionLink = document.querySelector(`a[href="#${id}"]`)

    if (currentSectionLink) {
      currentSectionLink.dataset.state = 'active'
    }
  }
}
