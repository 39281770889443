import * as Sentry from '@sentry/react'

import { OfflineManagerInterface } from '@/offline/interfaces/OfflineManagerInterface'

import { OfflineApplicationInterface } from './interfaces/OfflineApplicationInterface'
import { BackgroundSyncManagerInterface } from './interfaces/BackgroundSyncManagerInterface'
import StorageMonitor from './managers/StorageMonitor'
import { DefaultLocalDataManager } from './managers/DefaultLocalDataManager'
import SymmetricKeyManager from './managers/SymmetricKeyManager'
import { DefaultOfflineManager } from './managers/DefaultOfflineManager'
import BackgroundSyncManager from './managers/BackgroundSyncManager'
import { config } from './config'
import { LocalDataManagerInterface } from './interfaces/LocalDataManagerInterface'
import HeartbeatManager from './managers/HeartbeatManager'
import { HeartbeatManagerInterface } from './interfaces/HeartbeatManagerInterface'
import SyncEventBusManager from './managers/SyncEventBusManager'
import { SyncEventBusManagerInterface } from './interfaces/SyncEventBusManagerInterface'

export class OfflineApplication implements OfflineApplicationInterface {
  private _offlineManager: OfflineManagerInterface
  private readonly _storageMonitor: StorageMonitor
  private _backgroundSyncManager: BackgroundSyncManagerInterface
  private _localDataManager: LocalDataManagerInterface
  private _symmetricKeyManager: SymmetricKeyManager
  private _symmetricKey: CryptoKey | null = null
  private _heartbeatManager: HeartbeatManagerInterface
  private _syncEventBusManager: SyncEventBusManagerInterface
  static instance: OfflineApplication

  constructor() {
    this._syncEventBusManager = new SyncEventBusManager()
    this._heartbeatManager = new HeartbeatManager()
    this._storageMonitor = new StorageMonitor()
    this._localDataManager = new DefaultLocalDataManager(
      config.DB_NAME,
      config.DB_VERSION,
    )
    this._symmetricKeyManager = new SymmetricKeyManager(this._localDataManager)
    this._offlineManager = new DefaultOfflineManager(
      this._symmetricKeyManager,
      this._localDataManager,
    )
    this._backgroundSyncManager = new BackgroundSyncManager(
      this._localDataManager,
      this._symmetricKeyManager,
      config.SYNC_INTERVAL,
    )

    if (typeof OfflineApplication.instance === 'object') {
      return OfflineApplication.instance
    }
    OfflineApplication.instance = this
    return this
  }

  get offlineManager(): OfflineManagerInterface {
    return this._offlineManager
  }

  get backgroundSyncManager(): BackgroundSyncManagerInterface {
    return this._backgroundSyncManager
  }

  get storageMonitor(): StorageMonitor {
    return this._storageMonitor
  }

  get symmetricKey(): CryptoKey | null {
    return this._symmetricKey
  }

  get localDataManager(): LocalDataManagerInterface {
    return this._localDataManager
  }

  get heartbeatManager(): HeartbeatManagerInterface {
    return this._heartbeatManager
  }

  get symmetricKeyManager(): SymmetricKeyManager {
    return this._symmetricKeyManager
  }

  get syncEventBusManager(): SyncEventBusManager {
    return this._syncEventBusManager
  }

  async init(): Promise<boolean | undefined> {
    if (this.offlineManager.isBlocklisted()) {
      console.log('the url is blocklisted')
      return undefined
    }
    try {
      await this._localDataManager.initDB()
      await this.backgroundSyncManager.startBackgroundSync()
    } catch (error) {
      console.error('Error initializing offline application', error)
      Sentry.captureException(error)
    }
  }
}
