import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['avatar']
  static values = {
    initials: String,
  }

  connect() {
    this.avatarTarget.addEventListener('error', this.handleError)
  }

  disconnect() {
    this.avatarTarget.removeEventListener('error', this.handleError)
  }

  handleError = () => {
    // get defined class styles
    const classList = this.avatarTarget.classList

    // remove small font size
    classList.remove('!text-2xs')

    // convert avatar <img> into a default avatar using initials
    this.avatarTarget.outerHTML = `<div class='${classList}' aria-hidden='true'><span>${this.initialsValue}</span></div>`
  }
}
