import React, { PropsWithChildren } from 'react'
import { Popover as MantinePopover, PopoverProps } from '@mantine/core'

import styles from './Popover.module.scss'

interface Props extends PropsWithChildren<PopoverProps> {}

export const Popover = ({ children, ...props }: Props) => {
  return (
    <MantinePopover classNames={{ dropdown: styles.dropdown }} {...props}>
      {children}
    </MantinePopover>
  )
}

Popover.Target = MantinePopover.Target
Popover.Dropdown = MantinePopover.Dropdown
