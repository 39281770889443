import { useEffect, useRef } from 'react'

import {
  BulkUpdateNotificationsServerMessage,
  NotificationListServerMessage,
  NotificationsCountServerMessage,
  NotificationsServerWSEvent,
  PushNotificationServerMessage,
  bulkUpdateNotificationsServerMessageSchema,
  notificationListServerMessageSchema,
  notificationsCountServerMessageSchema,
  pushNotificationServerMessageSchema,
} from '../schemas'
import { GenericWSEvent } from '../../../schemas'

interface NotificationEventsProps {
  lastJsonMessage: GenericWSEvent | NotificationsServerWSEvent | null

  onConnectMessage?: () => void
  onCountMessage?: (message: NotificationsCountServerMessage) => void
  onNotificationsListMessage?: (message: NotificationListServerMessage) => void
  onPushNotificationMessage?: (message: PushNotificationServerMessage) => void
  onBulkUpdateNotificationsMessage?: (
    message: BulkUpdateNotificationsServerMessage,
  ) => void
}

export const useNotificationMessagesEvents = ({
  lastJsonMessage,

  onConnectMessage,
  onCountMessage,
  onNotificationsListMessage,
  onPushNotificationMessage,
  onBulkUpdateNotificationsMessage,
}: NotificationEventsProps) => {
  const lastProcessedMessageRef = useRef<
    GenericWSEvent | NotificationsServerWSEvent | null
  >(null)

  useEffect(() => {
    if (!lastJsonMessage) return

    if (lastProcessedMessageRef.current === lastJsonMessage) return
    lastProcessedMessageRef.current = lastJsonMessage

    const { ws_type, message } = lastJsonMessage

    if (ws_type === 'generic') {
      switch (message.event_type) {
        case 'connect':
          onConnectMessage?.()
          break
      }
    } else if (ws_type === 'notifications') {
      switch (message.event_type) {
        case 'count':
          notificationsCountServerMessageSchema.parse(message)

          onCountMessage?.(message)
          break
        case 'list':
          notificationListServerMessageSchema.parse(message)

          onNotificationsListMessage?.(message)
          break
        case 'push_notification':
          pushNotificationServerMessageSchema.parse(message)

          onPushNotificationMessage?.(message)
          break
        case 'bulk_update_notifications_state':
          bulkUpdateNotificationsServerMessageSchema.parse(message)

          onBulkUpdateNotificationsMessage?.(message)
          break
      }
    }
  }, [
    lastJsonMessage,
    onConnectMessage,
    onBulkUpdateNotificationsMessage,
    onCountMessage,
    onNotificationsListMessage,
    onPushNotificationMessage,
  ])
}
