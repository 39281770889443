import React, { JSX } from 'react'
import {
  ActionIcon as MantineActionIcon,
  ActionIconProps,
  PolymorphicComponentProps,
} from '@mantine/core'

import styles from './Button.module.scss'

export const ActionIcon = <C = 'button',>({
  ...props
}: JSX.IntrinsicAttributes & PolymorphicComponentProps<C, ActionIconProps>) => {
  return (
    <MantineActionIcon
      variant="subtle"
      color="teal"
      classNames={{
        root: styles.mantineButtonRoot,
        ...props.classNames,
      }}
      {...props}
    />
  )
}
