import React, { PropsWithChildren } from 'react'

import { Notifications } from '@/core/mantine-ui'

import { NotificationsManagerContext } from './context'
import { usePostNotification } from './hooks/use-post-notification'
import { AUTO_CLOSE_DELAY } from './constants'

interface NotificationsMangerProps extends PropsWithChildren {}

export const NotificationsManagerProvider = ({
  children,
}: NotificationsMangerProps) => {
  const { postNotification } = usePostNotification()

  return (
    <NotificationsManagerContext.Provider value={{ postNotification }}>
      <Notifications autoClose={AUTO_CLOSE_DELAY} />
      {children}
    </NotificationsManagerContext.Provider>
  )
}
