import { z } from 'zod'

import { NotificationWSServerEvents } from '../constants'

import {
  notificationSchema,
  pushNotificationSchema,
} from './notificationSchema'

export const notificationsCountServerMessageSchema = z.object({
  event_type: z.literal(NotificationWSServerEvents.COUNT),
  data: z.object({
    count: z.number(),
  }),
})

export const notificationListServerMessageSchema = z.object({
  event_type: z.literal(NotificationWSServerEvents.LIST),
  data: z.object({ notifications: z.array(notificationSchema) }),
})

export const pushNotificationServerMessageSchema = z.object({
  event_type: z.literal(NotificationWSServerEvents.PUSH_NOTIFICATION),
  data: z.object({ notification: pushNotificationSchema }),
})

export const bulkUpdateNotificationsServerMessageSchema = z.object({
  event_type: z.literal(
    NotificationWSServerEvents.BULK_UPDATE_NOTIFICATIONS_STATE,
  ),
  data: z.object({
    count: z.number(),
    notifications: z.array(notificationSchema),
  }),
})

export const notificationsServerWSMessagesSchema = z.union([
  notificationsCountServerMessageSchema,
  notificationListServerMessageSchema,
  pushNotificationServerMessageSchema,
  bulkUpdateNotificationsServerMessageSchema,
])

export const notificationsServerWSEvent = z.object({
  ws_type: z.literal('notifications'),
  message: notificationsServerWSMessagesSchema,
})

export type NotificationsServerWSEvent = z.infer<
  typeof notificationsServerWSEvent
>

export type NotificationsServerWSMessages = z.infer<
  typeof notificationsServerWSMessagesSchema
>

export type NotificationsCountServerMessage = z.infer<
  typeof notificationsCountServerMessageSchema
>
export type NotificationListServerMessage = z.infer<
  typeof notificationListServerMessageSchema
>

export type PushNotificationServerMessage = z.infer<
  typeof pushNotificationServerMessageSchema
>

export type BulkUpdateNotificationsServerMessage = z.infer<
  typeof bulkUpdateNotificationsServerMessageSchema
>
