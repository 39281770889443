import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

interface NotificationPanelContentProps extends PropsWithChildren {
  notificationsCount: number
}

export const NotificationPanelContent = ({
  children,
  notificationsCount,
}: NotificationPanelContentProps) => {
  const { t } = useTranslation()
  if (notificationsCount === 0) {
    return <div className="p-16 w-250">{t("You're all caught up!")} 👌</div>
  }

  return (
    <div className="w-[390px] flex flex-col gap-12 max-h-[66vh] overflow-auto px-16">
      {children}
    </div>
  )
}
