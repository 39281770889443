import { useEffect, useState } from 'react'

import { useOfflineApplication } from './useOfflineApplication'

export const useIsOnline = () => {
  const {
    offlineApplication: { heartbeatManager },
  } = useOfflineApplication()
  const [isOnline, setIsOnline] = useState<boolean>(heartbeatManager.isOnline)

  /**
   * DBW 10/9/24: See HeartbeatManager.ts for further explanation as to why
   * we don't use navigator.onLine, window.addEventListener('online', ...),
   * or window.addEventListener('offline', ...) to determine offline status.
   */
  useEffect(() => {
    heartbeatManager.observe(setIsOnline)

    return () => {
      heartbeatManager.removeObserver(setIsOnline)
    }
  }, [heartbeatManager])

  return { isOnline }
}
