import { Controller } from '@hotwired/stimulus'

import { extractMediaInfo, gatherDeviceInfo } from '../helpers/device_info'
import { iconNotRecording, iconRecording } from '../helpers/icons'
import { RecordSocket } from '../video/record_socket'
import { initiateConnection } from '../video/video_api'
import { VideoPeerConnection } from '../video/video_peer_connection'

const STATE_LABELS = {
  waiting_devices: ['Select input devices', 6],
  new: ['Initializing...', 1],
  connecting: ['Connecting...', 2],
  connected: ['Connected', -1],
  closed: ['Disconnected', 3],
  disconnected: ['Disconnected', 4],
  failed: ['Failed', 5],
}

export default class extends Controller {
  static targets = ['video', 'state', 'stateIcon', 'timer']
  static values = {
    deviceIds: Array,
    socketState: String('new'),
    pcState: String('waiting_devices'),
  }

  connect() {
    this.socket = new RecordSocket()
    this.socket.addEventListener('statechange', this._onSocketStateChange)
    this.socket.connect(_socketURL())
  }

  disconnect() {
    this._dispose()
  }

  setDevices(e) {
    this.deviceIdsValue = [e.detail?.videoDeviceId, e.detail?.audioDeviceId]
  }

  deviceIdsValueChanged() {
    this._updateDevices()
  }

  pcStateValueChanged(pcState, prevPCState) {
    // If the socket is connected, send an active or inactive message
    // if the state has changed
    if (this.socket && this.socket.state === 'connected') {
      const isActive = pcState === 'connected'
      if (isActive) {
        this.socket.markActive()
      } else if (prevPCState === 'connected') {
        this.socket.markInactive()
      }
    }

    this._updateState()
  }

  socketStateValueChanged(socketStateValue) {
    // ON socket connect, send an active or inactive message based on
    // the PC state
    if (socketStateValue === 'connected') {
      const isActive = this.pcStateValue === 'connected'
      if (isActive) {
        this.socket.markActive()
      } else {
        this.socket.markInactive()
      }
    } else if (socketStateValue === 'closed') {
      window.location.href = '/'
    }

    this._updateState()
    this._updateDevices()
  }

  async _updateDevices() {
    this._disposePC()

    if (this.socketStateValue !== 'connected') {
      return
    }

    const [vid, aid] = this.deviceIdsValue
    // If no video device, clear the video element but don't connect
    if (!vid) {
      this.videoTarget.srcObject = null
      return
    }

    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: { exact: vid },
        width: {
          ideal: 1280,
        },
        height: {
          ideal: 720,
        },
        aspectRatio: {
          ideal: 1280 / 720,
        },
        frameRate: {
          ideal: 15,
          max: 30,
        },
      },
      audio: aid ? { deviceId: { exact: aid } } : false,
    })
    // Cancel if devices changed during the async operation
    if (!(vid === this.deviceIdsValue[0] && aid === this.deviceIdsValue[1])) {
      return
    }

    this.videoTarget.srcObject = stream

    if (vid && aid) {
      this._connect(stream)
    }
  }

  _updateState() {
    if (
      this.socketStateValue === 'connected' &&
      this.pcStateValue === 'connected'
    ) {
      this.stateTarget.innerHTML = 'Recording'
      this.stateIconTarget.innerHTML = iconRecording()
      return
    }

    const [socketLabel, socketPriority] = STATE_LABELS[this.socketStateValue]
    const [pcLabel, pcPriority] = STATE_LABELS[this.pcStateValue]
    const label = socketPriority > pcPriority ? socketLabel : pcLabel
    this.stateTarget.innerHTML = label
    this.stateIconTarget.innerHTML = iconNotRecording()
  }

  async _connect(stream) {
    this.pcStateValue = 'new'

    console.log('Initiating connection...')

    const deviceInfo = gatherDeviceInfo()
    const mediaInfo = extractMediaInfo(stream)

    const [vid, aid] = this.deviceIdsValue
    const config = await initiateConnection(deviceInfo, mediaInfo)
    // Cancel if devices changed during the async operation
    if (!(vid === this.deviceIdsValue[0] && aid === this.deviceIdsValue[1])) {
      return
    }

    this.pc = new VideoPeerConnection(config, stream)

    console.log('Connecting...')

    this.pc.connect()
    this.pc.addStateChangeListener(this._onPCStateChange)
  }

  _disposeSocket() {
    if (this.socket) {
      this.socket.removeEventListener('statechange', this._onSocketStateChange)
      this.socket.dispose()
      this.socket = undefined
    }

    this.socketStateValue = 'new'
  }

  _disposePC() {
    if (this.pc) {
      this.pc.removeStateChangeListener(this._onPCStateChange)
      this.pc.dispose()
      this.pc = undefined
    }

    this.pcStateValue = 'waiting_devices'
  }

  _dispose() {
    this._disposeSocket()
    this._disposePC()
  }

  _onSocketStateChange = () => {
    console.debug('socketStateChange', this.socket.state)

    this.socketStateValue = this.socket.state
  }

  _onPCStateChange = () => {
    console.debug('pcStateChange', this.pc.state)

    this.pcStateValue = this.pc.state
  }
}

function _socketURL() {
  let wsProtocol = 'ws:'
  if (window.location.protocol === 'https:') {
    wsProtocol = 'wss:'
  }

  return `${wsProtocol}//${window.location.host}/ws${window.location.pathname}`
}
