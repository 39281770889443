import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    elapsed: Number,
    startTime: Number,
  }

  elapsedValueChanged() {
    this._updateTime()
  }

  startTimeValueChanged() {
    this._updateTime()

    if (this.startTimeValue !== -1) {
      if (this.interval) {
        clearInterval(this.interval)
      }

      this.interval = setInterval(this._updateTime, 1000)
    } else if (this.interval) {
      clearInterval(this.interval)
      this.interval = undefined
    }
  }

  _updateTime = () => {
    let t = this.elapsedValue
    if (this.startTimeValue !== -1) {
      t += Math.max(0, Math.floor(Date.now() / 1000) - this.startTimeValue)
    }
    t = Math.floor(t)

    const h = Math.floor(t / 3600)
    const m = Math.floor((t % 3600) / 60)
    const s = t % 60

    let str = ''
    if (h > 0) {
      str += `${h}:`
    }
    str += `${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`

    this.element.innerHTML = str
  }
}
