import React from 'react'
import { useTranslation } from 'react-i18next'

import { MaterialIcon } from '@/core/components/material-icon'
import { Button } from '@/core/mantine-ui'

interface NotificationPanelHeaderProps {
  notificationsCount: number
  unreadCount: number

  onMarkAllAsRead: () => void
}

export const NotificationPanelHeader = ({
  notificationsCount,
  unreadCount,
  onMarkAllAsRead,
}: NotificationPanelHeaderProps) => {
  const { t } = useTranslation(['common'])
  return (
    <div className="flex items-center justify-between px-16 h-44">
      <h3 className="font-semibold">{t('Notifications', { ns: 'common' })}</h3>
      {notificationsCount > 0 && (
        <Button
          variant="white"
          size="sm"
          onClick={onMarkAllAsRead}
          disabled={unreadCount === 0}
          className="disabled:bg-transparent"
        >
          <MaterialIcon icon="check" size={18} className="mr-4" />
          {t('Mark all as read', { ns: 'common' })}
        </Button>
      )}
    </div>
  )
}
