import { z } from 'zod'

export const durationObservationSchema = z.object({
  duration: z.object({
    duration: z.object({
      hours: z.number(),
      minutes: z.number(),
      seconds: z.number(),
    }),
    status: z.string(),
  }),
})

export const iseSessionTargetStateDurationSchema = z.object({
  dataCollectionMethod: z.literal('duration'),
  observations: z.array(durationObservationSchema),
  startTime: z.number(),
})

export type TargetStateDurationObservation = z.infer<
  typeof durationObservationSchema
>

export type ISESessionTargetStateDuration = z.infer<
  typeof iseSessionTargetStateDurationSchema
>
