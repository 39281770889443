function parseRecording(recording, sectionIndex) {
  return {
    browserSessionKey: recording.browser_session_key,
    deviceName: recording.device_name,
    startAt: Date.parse(recording.start_at),
    endAt: Date.parse(recording.end_at),
    hlsInfo: {
      startTime: recording.hls_info.start_time,
      url: recording.hls_info.url,
    },
    sectionIndex,
  }
}

function parseSection(section, sectionIndex) {
  return {
    startAt: Date.parse(section.start_at),
    endAt: Date.parse(section.end_at),
    pauseReason: section.pause_reason,
    recordings: section.recordings.map((recording) => {
      return parseRecording(recording, sectionIndex)
    }),
  }
}

export function parseSections(sections) {
  return sections.map((section, i) => {
    return parseSection(section, i)
  })
}

function parseObservation(observation) {
  const base = {
    type: observation.type,
    createdAt: Date.parse(observation.created_at),
  }

  switch (observation.type) {
    case 'program_target_observation':
      return {
        ...base,
        name: observation.name,
        program: observation.program,
        data: observation.data,
      }
    case 'free_operant_observation':
      return {
        ...base,
        name: observation.name,
        data: observation.data,
      }
    case 'timestamp':
      return {
        ...base,
        uuid: observation.uuid,
        comment: observation.comment,
      }
    default:
      return base
  }
}

export function parseObservations(observations) {
  return observations.map(parseObservation)
}
