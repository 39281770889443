export enum PROGRAM_DATA_COLLECTION_METHODS_ENUM {
  DURATION = 'duration',
  ESTIMATED_MASTERY = 'estimated_mastery',
  FREQUENCY_AND_RATE = 'frequency_and_rate',
  PERCENT_OF_OPPORTUNITIES = 'percent_of_opportunities',
  TASK_ANALYSIS = 'task_analysis',
}

export const PROGRAM_DATA_COLLECTION_METHODS = [
  'duration',
  'estimated_mastery',
  'frequency_and_rate',
  'percent_of_opportunities',
  'task_analysis',
] as const
