import React, { forwardRef } from 'react'

import { classesMerge } from '@/core/helpers/classes-merge'

interface MaterialIconProps {
  className?: string
  color?: string
  size?: number
  fontWeight?: string | number
  icon: string
  fill?: boolean
}

// To use this component you need to pass icon name as a string, you can find names of icons on https://fonts.google.com/icons
// Also you can pass size and color, otherwise color will be inherited from parent and size will be 24px by default
// Forward ref is added to be able use it for Mantine Tooltip

export const MaterialIcon = forwardRef<HTMLSpanElement, MaterialIconProps>(
  ({ icon, color, size = 24, fontWeight = 'normal', fill, className }, ref) => {
    const iconClassNames = classesMerge('material-symbols-outlined', className)

    return (
      <span
        ref={ref}
        className={iconClassNames}
        style={{
          color: color ?? 'inherit',
          fontSize: `${size}px`,
          fontWeight,
          fontVariationSettings: `'FILL' ${fill ? 1 : 0}`,
        }}
      >
        {icon}
      </span>
    )
  },
)

MaterialIcon.displayName = 'MaterialIcon'
