export class RecordSocket extends EventTarget {
  constructor() {
    super()

    this.state = 'new'
  }

  _setState(state) {
    this.state = state
    this.dispatchEvent(new Event('statechange'))
  }

  connect(url) {
    this.socket = new WebSocket(url)

    this.socket.addEventListener('open', this.onOpen)
    this.socket.addEventListener('close', this.onClose)
    this.socket.addEventListener('error', this.onError)

    this._setState('connecting')
  }

  dispose() {
    if (this.socket) {
      this.socket.close()
    }
  }

  markActive() {
    this.socket.send(JSON.stringify({ event_type: 'active' }))
  }

  markInactive() {
    this.socket.send(JSON.stringify({ event_type: 'inactive' }))
  }

  onOpen = () => {
    this._setState('connected')
  }

  onClose = () => {
    this._setState('closed')
  }

  onError = (ex) => {
    console.error(ex)
  }
}
