import { z } from 'zod'

export const percentOfOpportunitiesObservationSchema = z.object({
  promptTitle: z.string(),
  status: z.enum(['correct', 'incorrect', 'hidden']),
  abbreviation: z.string().optional(),
})

export const percentOfOpportunitiesTargetsDataSchema = z.object({
  fading: z
    .object({
      currentLevel: z.string(),
    })
    .optional(),
  prompts: z
    .array(
      z.object({
        abbreviation: z.string().optional(),
        promptTitle: z.string(),
        status: z.enum(['correct', 'incorrect', 'hidden']),
      }),
    )
    .optional(),
})

export const iseSessionTargetStatePercentOfOpportunitiesSchema = z.object({
  dataCollectionMethod: z.literal('percent_of_opportunities'),
  observations: z.array(percentOfOpportunitiesObservationSchema),
  targetData: percentOfOpportunitiesTargetsDataSchema,
})

export type PercentOfOpportunitiesObservation = z.infer<
  typeof percentOfOpportunitiesObservationSchema
>

export type PercentOfOpportunitiesTargetsData = z.infer<
  typeof percentOfOpportunitiesTargetsDataSchema
>

export type ISESessionTargetStatePercentOfOpportunities = z.infer<
  typeof iseSessionTargetStatePercentOfOpportunitiesSchema
>
