import React, { PropsWithChildren } from 'react'

import { useWebsocketManager } from '@/core/services/websocket/useWebsocketManager'
import { useNotificationsManager } from '@/core/services/notifications/services'

import { NotificationWsContext } from './context'
import {
  useNotificationActions,
  useNotificationEventHandlers,
  useNotificationsState,
  useNotificationMessagesEvents,
} from './hooks'
import { NotificationsServerWSEvent } from './schemas'

export const NotificationWSProvider = ({ children }: PropsWithChildren) => {
  const { postNotification } = useNotificationsManager()

  const { lastJsonMessage, sendJsonMessage } =
    useWebsocketManager<NotificationsServerWSEvent | null>({})

  const {
    count,
    notificationsList,
    setNotificationsCountState,
    setNotificationsListState,
  } = useNotificationsState()

  const {
    getNotificationsCount,
    getNotificationsList,
    dismissNotification,
    markAsRead,
    markAsUnread,
    markAllAsRead,
  } = useNotificationActions({
    sendJsonMessage,
  })

  const {
    onConnect,
    onCountChange,
    onNotificationsListChange,
    onPushNotificationChange,
    onBulkUpdateNotificationsChange,
  } = useNotificationEventHandlers({
    getNotificationsCount,
    setNotificationsCountState,
    setNotificationsListState,
    postNotification,
  })

  useNotificationMessagesEvents({
    lastJsonMessage,

    onConnectMessage: onConnect,
    onCountMessage: onCountChange,
    onNotificationsListMessage: onNotificationsListChange,
    onPushNotificationMessage: onPushNotificationChange,
    onBulkUpdateNotificationsMessage: onBulkUpdateNotificationsChange,
  })

  return (
    <NotificationWsContext.Provider
      value={{
        count,
        notificationsList,
        getNotificationsList,
        dismissNotification,
        markAsRead,
        markAsUnread,
        markAllAsRead,
      }}
    >
      {children}
    </NotificationWsContext.Provider>
  )
}
