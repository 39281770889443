import { createContext } from 'react'
import { noop } from 'lodash'

import { Notification } from './schemas'

interface NotificationWsContextType {
  count: number
  notificationsList: Notification[]
  getNotificationsList: () => void
  dismissNotification: (id: string) => void
  markAsRead: (id: string) => void
  markAsUnread: (id: string) => void
  markAllAsRead: () => void
}

export const NotificationWsContext = createContext<NotificationWsContextType>({
  count: 0,
  notificationsList: [],
  getNotificationsList: noop,
  dismissNotification: noop,
  markAsRead: noop,
  markAsUnread: noop,
  markAllAsRead: noop,
})
