export enum NotificationWSClientEvents {
  LIST = 'list',
  COUNT = 'count',
  DISMISS = 'dismiss',
  MARK_AS_READ = 'mark_as_read',
  MARK_AS_UNREAD = 'mark_as_unread',
  MARK_ALL_READ = 'mark_all_read',
}

export enum NotificationWSServerEvents {
  PUSH_NOTIFICATION = 'push_notification',
  BULK_UPDATE_NOTIFICATIONS_STATE = 'bulk_update_notifications_state',
  COUNT = 'count',
  LIST = 'list',
}
