export const calculateTimeAgo = (dateString: string) => {
  const date = new Date(dateString)
  const now = new Date()
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000)

  const intervals: { [key: string]: number } = {
    day: 24 * 60 * 60,
    hour: 60 * 60,
    minute: 60,
  }

  if (seconds < 60) return 'NOW'

  for (const [key, value] of Object.entries(intervals)) {
    const interval = Math.floor(seconds / value)
    if (interval >= 1) {
      if (key === 'day' && interval >= 7) return `7 days ago`
      return `${interval} ${key}${interval > 1 ? 's' : ''} ago`
    }
  }

  return `on ${date.toLocaleString()}`
}
