import React from 'react'

import { classesMerge } from '../helpers/classes-merge'

import { Icon } from './icons/Icon'

type Props = {
  className?: string
}

export default function FullScreenLoader({ className = '' }: Props) {
  return (
    <div
      className={classesMerge(
        'flex items-center justify-center h-screen-nav',
        className,
      )}
    >
      <Icon
        icon="loading"
        className="rect-24 text-primary"
        aria-label="Loading..."
      />
    </div>
  )
}
