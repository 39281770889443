export const FREE_OPERANT_DATA_COLLECTION_METHODS = [
  'frequency_and_rate',
  'duration',
  'estimated_mastery',
  'partial_interval',
  'whole_interval',
] as const

export type FreeOperantDataCollectionMethod =
  (typeof FREE_OPERANT_DATA_COLLECTION_METHODS)[number]

export const FREE_OPERANT_STATUSES = [
  'baseline',
  'intervention',
  'maintenance_daily',
  'maintenance_eod',
  'maintenance_weekly',
  'maintenance_every_other_week',
  'maintenance_monthly',
  'on_hold',
  'completed',
  'archived',
] as const

export type FreeOperantStatus = (typeof FREE_OPERANT_STATUSES)[number]

export const FREQUENCY_AND_RATE_REPORT_TYPES = ['FRE', 'MIN', 'HR'] as const

export const DURATION_REPORT_TYPES = ['AVG', 'TOT'] as const

export const INTENSITY_SCALE_CHOICES = ['2', '3', '4', '5'] as const
