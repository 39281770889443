import { z } from 'zod'

import { ProgramDataCollectionMethod } from '@/core/models/program'

import { ProgramAttributes } from '../../programSchema'

// Percent of opportunities attributes schemas

const promptStatusEnum = z.enum(['correct', 'incorrect', 'hidden'])

export const advancementLevelsCriteriasSchema = z.object({
  increaseAfter: z.number(),
  independentAfter: z.number(),
  reduceAfter: z.number(),
})

const fadingSchema = z.object({
  advancementLevelsCriterias: advancementLevelsCriteriasSchema,
  hideAllPrompts: z.boolean(),
  independentSwitch: z.boolean(),
  isEnabled: z.boolean(),
  showAllPrompts: z.boolean(),
  startingLevel: z.string(),
})

const promptSchema = z.object({
  status: promptStatusEnum,
  abbreviation: z.string().optional(),
  promptTitle: z.string(),
  advancesPromptProgression: z.boolean().nullable().optional(),
})

export const programPercentOfOpportunitiesAttributesSchema = z.object({
  fading: fadingSchema,
  prompts: z.array(promptSchema),
})

export type ProgramPercentOfOpportunitiesPrompt = z.infer<typeof promptSchema>

export type ProgramPercentOfOpportunitiesAttributes = z.infer<
  typeof programPercentOfOpportunitiesAttributesSchema
>

// Assertion

export function assertAttributesIsPercentOfOpportunitiesAttributes(
  attributes: ProgramAttributes,
  dataCollectionMethod: ProgramDataCollectionMethod,
): asserts attributes is ProgramPercentOfOpportunitiesAttributes {
  if (dataCollectionMethod !== 'percent_of_opportunities') {
    throw new Error(
      'Percent of opportunities attributes must include fading and prompts',
    )
  }
}
