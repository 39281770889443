import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'timeRangePickers',
    'sessionsOptions',
    'assessmentOptions',
    'treatmentPlans',
    'treatmentPlansPrograms',
    'programGraphs',
    'programGraphsOptions',
  ]

  connect() {
    // get all time range radio inputs
    this.timeRangeRadio = this.element.querySelectorAll(
      'input[name="time_range_type"]',
    )

    // listen for radio changes
    this.timeRangeRadio.forEach((radio) => {
      radio.addEventListener('change', (e) => {
        // handle whether to show time range fields
        this.handleTimeRangeType(e.currentTarget)
      })
    })

    this.programGraphsOptions = document.getElementsByName(
      'program_graphs_options',
    )

    // get sessions/assessments checkboxes
    const sessionsInput = document.getElementById('id_Sessions')
    const assessmentsInput = document.getElementById('id_Assessments')
    const treatmentPlansInput = document.getElementById('id_TreatmentPlans')
    const treatmentPlansProgramsInput = document.getElementById(
      'id_TreatmentPlansPrograms',
    )
    const includeProgramGraphsInput = document.getElementById(
      'id_include_program_graphs',
    )

    // listen for sessions checkbox change
    sessionsInput.addEventListener('change', (e) => {
      // handle whether sub-list displays or not
      this.handleSessionsOptions(e.currentTarget)
    })

    // if sessions checkbox is checked
    if (sessionsInput.checked) {
      // handle the sub-list
      this.handleSessionsOptions(sessionsInput)
    }

    // listen for sessions checkbox change
    assessmentsInput.addEventListener('change', (e) => {
      // handle whether sub-list displays or not
      this.handleAssessmentsOptions(e.currentTarget)
    })

    // if assessments checkbox is checked
    if (sessionsInput.checked) {
      // handle the sub-list
      this.handleAssessmentsOptions(assessmentsInput)
    }

    // listen for treatment plans checkbox change
    treatmentPlansInput.addEventListener('change', (_e) => {
      // handle whether sub-list checks or unchecks
      this.handleToggleOptions(
        this.treatmentPlansTarget.checked,
        [
          this.treatmentPlansProgramsTarget,
          this.programGraphsTarget,
          this.programGraphsOptions,
        ],
        [],
      )
    })

    if (treatmentPlansInput.checked) {
      this.handleToggleOptions(
        this.treatmentPlansTarget.checked,
        [
          this.treatmentPlansProgramsTarget,
          this.programGraphsTarget,
          this.programGraphsOptions,
        ],
        [],
      )
    }

    // listen for treatment plans checkbox change
    treatmentPlansProgramsInput.addEventListener('change', (_e) => {
      // handle whether sub-list checks or unchecks
      this.handleToggleOptions(
        this.treatmentPlansProgramsTarget.checked,
        [this.programGraphsTarget, this.programGraphsOptions],
        this.treatmentPlansTarget,
      )
    })

    if (treatmentPlansProgramsInput.checked) {
      this.handleToggleOptions(
        this.treatmentPlansProgramsTarget.checked,
        [this.programGraphsTarget, this.programGraphsOptions],
        this.treatmentPlansTarget,
      )
    }

    // listen for program graphs checkbox change
    includeProgramGraphsInput.addEventListener('change', (_e) => {
      // handle whether sub-list checks or unchecks
      this.handleToggleOptions(
        this.programGraphsTarget.checked,
        this.programGraphsOptions,
        [this.treatmentPlansTarget, this.treatmentPlansProgramsTarget],
      )
    })

    if (includeProgramGraphsInput.checked) {
      this.handleToggleOptions(
        this.programGraphsTarget.checked,
        this.programGraphsOptions,
        [this.treatmentPlansTarget, this.treatmentPlansProgramsTarget],
      )
    }
  }

  handleTimeRangeType(e) {
    // get all time range date pickers
    const datePickers =
      this.timeRangePickersTarget.querySelectorAll('input[type="date"]')

    // check if time range is set to 'custom_range'
    if (e.value == 'custom_range') {
      // remove hidden from time range pickers
      this.timeRangePickersTarget.classList.remove('hidden')
      // and set each time range to be required
      datePickers.forEach((datePicker) => {
        datePicker.setAttribute('required', '')
      })
    } else {
      // otherwise hide time range pickers
      this.timeRangePickersTarget.classList.add('hidden')
      // and set each time range to not be required
      datePickers.forEach((datePicker) => {
        datePicker.removeAttribute('required')
      })
    }
  }

  handleSessionsOptions(e) {
    // check if sessions exist
    if (!this.hasSessionsOptionsTarget) return

    // if sessions is checked
    if (e.checked) {
      // reveal sub-list
      this.sessionsOptionsTarget.classList.remove('hidden')
    } else {
      // otherwise hide sub-list
      this.sessionsOptionsTarget.classList.add('hidden')
    }
  }

  handleAssessmentsOptions(e) {
    // check if assessments exist
    if (!this.hasAssessmentOptionsTarget) return

    // if sessions is checked
    if (e.checked) {
      // reveal sub-list
      this.assessmentOptionsTarget.classList.remove('hidden')
    } else {
      // otherwise hide sub-list
      this.assessmentOptionsTarget.classList.add('hidden')
    }
  }

  //handles all hierarchy checkboxes
  handleToggleOptions(targetChecked, childTargets, parentTargets) {
    if (targetChecked) {
      //if target is checkbox is checked, checks all childs and parents
      this.recursiveCheck(childTargets, targetChecked)
      this.recursiveCheck(parentTargets, targetChecked)
    } else {
      //if target is checkbox is unchecked, keep check on parents and uncheck childs
      this.recursiveCheck(childTargets, targetChecked)
    }
  }

  //this recursively checks targets with a determinate value
  recursiveCheck(target, value) {
    target instanceof NodeList || Array.isArray(target)
      ? target.forEach((t) => this.recursiveCheck(t, value))
      : (target.checked = value)
  }

  clearChecks(e) {
    e.preventDefault()

    // get all inputs to be purged by 'clear' button
    const clearInputs = e.currentTarget.parentNode.querySelectorAll(
      'input[type="checkbox"]',
    )

    // for each of them
    clearInputs.forEach((input) => {
      // uncheck them
      input.checked = false
    })
  }
}
