import { createContext } from 'react'
import { noop } from 'lodash'

import { PushNotification } from '@/core/services/websocket/services'

interface NotificationsManagerContextType {
  postNotification: (notification: PushNotification) => void
}

export const NotificationsManagerContext =
  createContext<NotificationsManagerContextType>({
    postNotification: noop,
  })
