import { pick } from 'lodash'

export function gatherDeviceInfo() {
  return {
    ...pick(navigator, [
      'deviceMemory',
      'hardwareConcurrency',
      'maxTouchPoints',
      'userAgent',
    ]),
    connection: pick(navigator.connection, [
      'downlink',
      'downlinkMax',
      'effectiveType',
      'rtt',
      'saveData',
      'type',
    ]),
    userAgentData: pick(navigator.userAgentData, [
      'brands',
      'mobile',
      'platform',
    ]),
  }
}

export function extractMediaInfo(stream) {
  const tracks = stream.getTracks()

  return {
    tracks: tracks.map((track) => {
      return {
        ...pick(track, ['kind', 'label']),
        capabilities: track.getCapabilities
          ? track.getCapabilities()
          : undefined,
        constraints: track.getConstraints ? track.getConstraints() : undefined,
        settings: track.getSettings ? track.getSettings() : undefined,
      }
    }),
  }
}
