import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'button']
  static values = {
    data: String,
  }

  connect() {
    this.loadInitialValue()
  }

  loadInitialValue() {
    // Find button whose result param equals the data sent from the server
    const activeButtonIndex = this.buttonTargets.indexOf(
      this.buttonTargets.find(
        (e) =>
          e.getAttribute('data-segmented-radio-result-param') == this.dataValue,
      ),
    )

    this.dispatch('loadInitialValue', {
      detail: { content: activeButtonIndex },
    })
  }

  updateInputValue({ params: { result } }) {
    this.inputTarget.value = result
  }
}
