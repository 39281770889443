import { z } from 'zod'

import { PROGRAM_TARGET_STATUSES } from '../constants/programTargetsStatuses'
import { percentOfOpportunitiesTargetsDataSchema } from '../../ise-session/ise-session-target-state-schemas'

export const programTarget = z.object({
  data: percentOfOpportunitiesTargetsDataSchema.optional(),
  maxTrials: z.number().nullable(),
  minTrials: z.number().nullable(),
  name: z.string(),
  note: z.string().nullable(),
  sd: z.string().nullable(),
  status: z.enum(PROGRAM_TARGET_STATUSES),
  uuid: z.string(),
})

export type ProgramTarget = z.infer<typeof programTarget>
export type ProgramTargetStatus = ProgramTarget['status']
