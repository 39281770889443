import { Controller } from '@hotwired/stimulus'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default class extends Controller {
  connect() {
    this.element.showModal()
    disableBodyScroll(document.body)
  }

  close() {
    this.element.close()
    enableBodyScroll(document.body)
  }

  backdropClose(e) {
    // check if dialog (::backdrop) was clicked
    if (e.target == this.element) {
      // close modal
      this.close()
    }
  }
}
