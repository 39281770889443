import { Controller } from '@hotwired/stimulus'
import videojs from 'video.js'

import { iconPause, iconPlay } from '../helpers/icons'
import { toDuration } from '../helpers/utils'
import { renderEvents } from '../post_session_timeline/render'
import PostSessionTimeline from '../post_session_timeline/timeline'

// When navigating the video time to an event, navigate this number of seconds before the event's timestamp
// const NAVIGATE_EVENT_OFFSET_SECONDS = 10
const HEADING_STICKY_TOP = 80
const SCROLL_MARGIN_TOP = 22

export default class extends Controller {
  static targets = [
    'heading',
    'autoScroll',
    'events',
    'event',
    'noVideo',
    'video',
    'goPrev',
    'toggleVideo',
    'goNext',
    'currentTime',
  ]
  static values = {
    sections: Array,
    observations: Array,
    activeEventIndex: Number(-1),
    isAutoScrolling: Boolean(false),
    currentTime: Number(-1),
    recording: Object({
      sectionIndex: -1,
      hasRecording: false,
      nextRecordingIndex: -1,
    }),
    videoTarget: Object({
      time: -1,
      autoPlay: false,
    }),
    isPlaying: Boolean(false),
    timezone: String,
  }

  connect() {
    window.timezone = this.timezoneValue

    this._timeline = new PostSessionTimeline(
      this.sectionsValue,
      this.observationsValue,
    )

    this._player = videojs(this.videoTarget, { controls: true })

    // Register player event handlers
    this._player.on('play', this._onPlayerPlay)
    this._player.on('pause', this._onPlayerPause)
    this._player.on('timeupdate', this._onPlayerTimeUpdate)
    this._player.on('ended', this._onPlayerEnded)

    // Render the events into the events target.
    // Once rendered they are accessible as `this.eventTargets`.
    this.eventsTarget.innerHTML = renderEvents(
      this._timeline.startAt,
      this._timeline.events,
    )

    // Navigate to the session start event but don't auto-play.
    this._navigateToEvent(0, false)
  }

  disconnect() {
    // Unregister player event handlers
    this._player.off('play', this._onPlayerPlay)
    this._player.off('pause', this._onPlayerPause)
    this._player.off('timeupdate', this._onPlayerTimeUpdate)
    this._player.off('ended', this._onPlayerEnded)

    this._player.dispose()
  }

  _onPlayerPlay = () => {
    this.isPlayingValue = true
  }

  _onPlayerPause = () => {
    this.isPlayingValue = false
  }

  _onPlayerTimeUpdate = () => {
    if (
      !(
        this._timeline &&
        this.recordingValue.hasRecording &&
        this.isPlayingValue
      )
    ) {
      return
    }

    const { sectionIndex, recordingIndex } = this.recordingValue
    const recording = this._timeline.recording(sectionIndex, recordingIndex)

    // Determine the absolute time based on the relative player time and
    // absolute recording start time
    const playerTime = Math.floor(this._player.currentTime() * 1000)
    const time = playerTime + recording.startAt

    // Set the current time to the relative time from the session's start
    this.currentTimeValue = time - this._timeline.startAt

    // Set the active event index to the latest event in the recording, having
    // occurred before the new time
    const latestEventIndex =
      this._timeline.latestEvent1DIndexInRecordingBeforeTime(
        sectionIndex,
        recordingIndex,
        time,
      )
    if (latestEventIndex !== -1) {
      this.activeEventIndexValue = latestEventIndex
    }
  }

  _onPlayerEnded = () => {
    if (!(this._timeline && this.recordingValue.hasRecording)) {
      return
    }

    const { sectionIndex, recordingIndex } = this.recordingValue

    // Find the latest event in the recording
    const latestEventIndex = this._timeline.latestEvent2DIndexInRecording(
      sectionIndex,
      recordingIndex,
    )

    // Search for the next event after this recording
    let searchSectionIndex = sectionIndex
    let searchEventIndex = latestEventIndex
    let nextEventIndex = -1
    while (searchSectionIndex < this._timeline.sectionsCount) {
      const nextRecording = this._timeline.eventRecording(
        searchSectionIndex,
        searchEventIndex,
      )
      if (
        nextRecording.sectionIndex !== sectionIndex ||
        nextRecording.recordingIndex !== recordingIndex
      ) {
        nextEventIndex = this._timeline.event1DIndex(
          searchSectionIndex,
          searchEventIndex,
        )
        break
      }

      searchEventIndex++
      if (
        searchEventIndex ===
        this._timeline.sectionEventsCount(searchSectionIndex)
      ) {
        searchEventIndex = 0
        searchSectionIndex++
      }
    }

    if (nextEventIndex > -1) {
      // If there is a next event, navigate to it
      this._navigateToEvent(nextEventIndex, true)
    } else {
      // Otherwise, update the state to point at the last event in this recording
      const recording = this._timeline.recording(sectionIndex, recordingIndex)
      this.currentTimeValue = recording.endAt - this._timeline.startAt
      this.activeEventIndexValue = this._timeline.event1DIndex(
        sectionIndex,
        latestEventIndex,
      )
    }
  }

  _navigateToEvent(index, autoPlay) {
    const [sectionIndex, eventIndex] = this._timeline.event2DIndex(index)
    const event = this._timeline.event(sectionIndex, eventIndex)

    this.currentTimeValue = event.time - this._timeline.startAt
    this.recordingValue = this._timeline.eventRecording(
      sectionIndex,
      eventIndex,
    )
    this.videoTargetValue = {
      time: event.time,
      autoPlay,
      // Guarantee a state update even if the last video target was the same
      unique: Math.random(),
    }
    this.activeEventIndexValue = index
  }

  _scrollToEvent(index) {
    const eventEl = this.eventTargets[index]
    const eventY = eventEl.getBoundingClientRect().y
    const headingTotalHeight =
      HEADING_STICKY_TOP + this.headingTarget.getBoundingClientRect().height

    window.scroll({
      top: window.scrollY + eventY - headingTotalHeight - SCROLL_MARGIN_TOP,
    })
  }

  clearAutoScroll() {
    this.isAutoScrollingValue = false
  }

  setAutoScroll(event) {
    const isChecked = event.target.checked
    this.isAutoScrollingValue = isChecked
  }

  navigateToEvent(event) {
    const index = event.params.index
    this.isAutoScrollingValue = true
    this._navigateToEvent(index, true)
  }

  goPrev() {
    if (this.activeEventIndexValue <= 0) {
      return
    }

    this.isAutoScrollingValue = true
    this._navigateToEvent(this.activeEventIndexValue - 1, true)
  }

  goNext() {
    if (
      this._timeline &&
      this.activeEventIndexValue >= this._timeline.eventsCount - 1
    ) {
      return
    }

    this.isAutoScrollingValue = true
    this._navigateToEvent(this.activeEventIndexValue + 1, true)
  }

  toggleVideo() {
    if (!(this._player && this.recordingValue.hasRecording)) {
      return
    }

    this.isAutoScrollingValue = true
    if (this.isPlayingValue) {
      this._player.pause()
    } else {
      this._player.play()
    }
  }

  activeEventIndexValueChanged(activeEventIndex, prevActiveEventIndex) {
    // Remove the data-state attribute from the previous active event if exists
    if (prevActiveEventIndex !== undefined && prevActiveEventIndex !== -1) {
      const prevEventEl = this.eventTargets[prevActiveEventIndex]
      delete prevEventEl.dataset.state
    }

    // Add the data-state attribute to the new active event if exists
    if (activeEventIndex !== undefined && activeEventIndex !== -1) {
      const eventEl = this.eventTargets[activeEventIndex]
      eventEl.dataset.state = 'active'

      if (this.isAutoScrollingValue) {
        // Scroll to the event if auto-scrolling enabled
        this._scrollToEvent(activeEventIndex)
      }
    }
  }

  isAutoScrollingValueChanged(isAutoScrolling) {
    // Update the auto-scroll checkbox
    if (this.hasAutoScrollTarget) {
      this.autoScrollTarget.checked = isAutoScrolling
    }

    // Scroll to the active event if exists
    if (
      isAutoScrolling &&
      this.activeEventIndexValue !== undefined &&
      this.activeEventIndexValue !== -1
    ) {
      this._scrollToEvent(this.activeEventIndexValue)
    }
  }

  currentTimeValueChanged(currentTime) {
    if (!this._timeline) {
      return
    }

    const isStart = currentTime === 0
    const isEnd = currentTime === this._timeline.endAt - this._timeline.startAt

    // Update the current time text and the enabled state of the go prev and
    // go next buttons
    this.currentTimeTarget.innerText = toDuration(currentTime)
    this.goPrevTarget.disabled = isStart
    this.goNextTarget.disabled = isEnd
  }

  recordingValueChanged(recording) {
    if (!(this._player && this._timeline)) {
      return
    }

    // Show the video or no video element based on the existence of a recording
    if (recording.hasRecording) {
      this.noVideoTarget.classList.add('hidden')
      this.videoTarget.classList.remove('!hidden')
    } else {
      this.noVideoTarget.classList.remove('hidden')
      this.videoTarget.classList.add('!hidden')
    }

    this.toggleVideoTarget.disabled = !recording.hasRecording
    this.isPlayingValue = false

    if (!recording.hasRecording) {
      this._player.reset()
      return
    }

    // Load the video
    const { sectionIndex, recordingIndex } = recording
    const hlsInfo = this._timeline.recording(
      sectionIndex,
      recordingIndex,
    ).hlsInfo
    this._player.src({
      src: hlsInfo.url,
      type: 'application/x-mpegURL',
    })
  }

  videoTargetValueChanged(videoTarget) {
    if (
      !(
        this._player &&
        this._timeline &&
        videoTarget.time >= 0 &&
        this.recordingValue.hasRecording
      )
    ) {
      return
    }

    const { sectionIndex, recordingIndex } = this.recordingValue
    const recording = this._timeline.recording(sectionIndex, recordingIndex)

    // Determine the relative player time from the absolute target time
    const offsetSeconds =
      Math.max(0, videoTarget.time - recording.startAt) / 1000

    this._player.currentTime(offsetSeconds)

    if (videoTarget.autoPlay) {
      this._player.play()
    }
  }

  isPlayingValueChanged(isPlaying) {
    this.toggleVideoTarget.innerHTML = isPlaying ? iconPause() : iconPlay()
    this.toggleVideoTarget.setAttribute('title', isPlaying ? 'Pause' : 'Play')
    this.toggleVideoTarget.setAttribute(
      'aria-label',
      isPlaying ? 'pause timeline' : 'play timeline',
    )
  }
}
