import React, { ReactNode } from 'react'
import cx from 'classnames'

import styles from './ModalFooter.module.scss'

export const ModalFooter = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return <div className={cx(styles.modalFooter, className)}>{children}</div>
}
