import React from 'react'
import { useTranslation } from 'react-i18next'

import { LinkButton } from '@/core/mantine-ui'

export const NotificationPanelFooter = () => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center px-16 h-44">
      <LinkButton
        href={`${window.location.origin}/notifications-center/`}
        size="sm"
        variant="white"
        className="pl-0"
      >
        {t('View all notifications')}
      </LinkButton>
    </div>
  )
}
