import {
  BillableSection,
  ObservationData,
  PostSessionData,
  RecordingData,
  SectionData,
} from './data'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parsePostSessionData(data: any): PostSessionData {
  const {
    timezone,
    section_data,
    billable_sections,
    observations,
    programs,
    free_operants,
    session,
    user_id,
    is_edit_allowed,
  } = data
  return {
    timezone,
    sections: parseSections(section_data),
    billable_sections: parseBillableSections(billable_sections),
    observations: parseObservations(observations),
    programs,
    free_operants,
    session,
    user_id,
    is_edit_allowed,
  }
}

type Section = {
  start_at: string
  end_at: string
  pause_reason: string
  recordings: Recording[]
}

function parseSection(section: Section, sectionIndex: number): SectionData {
  return {
    startAt: new Date(section.start_at),
    endAt: new Date(section.end_at),
    pauseReason: section.pause_reason,
    recordings: section.recordings.map((recording: any) => {
      return parseRecording(recording, sectionIndex)
    }),
  }
}

export function parseSections(sections: Section[]): SectionData[] {
  if (!Array.isArray(sections)) {
    throw new Error('expected sections to be an array')
  }

  return sections.map((section, i) => {
    return parseSection(section, i)
  })
}

type Recording = {
  browser_session_key: string
  device_name: string
  start_at: string
  end_at: string
  hls_info: HlsInfo
}

type Billable = {
  end_at: string
  start_at: string
}

type HlsInfo = {
  stream_name: string
  start_time: Date
  url: string
}

function parseRecording(
  recording: Recording,
  sectionIndex: number,
): RecordingData {
  return {
    browserSessionKey: recording.browser_session_key,
    deviceName: recording.device_name,
    startAt: new Date(recording.start_at),
    endAt: new Date(recording.end_at),
    hlsInfo: {
      startTime: recording.hls_info.start_time,
      url: recording.hls_info.url,
    },
    sectionIndex,
  }
}

export function parseBillableSections(sections: Billable[]): BillableSection[] {
  if (!Array.isArray(sections)) {
    throw new Error('expected billable sections to be an array')
  }

  return sections.map((section) => {
    return parseBillable(section)
  })
}

function parseBillable(billable: Billable): BillableSection {
  return {
    startAt: new Date(billable.start_at),
    endAt: new Date(billable.end_at),
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseObservation(observation: any): ObservationData {
  const base = {
    type: observation.type,
    createdAt: new Date(observation.created_at),
  }

  switch (observation.type) {
    case 'program_target_observation':
      return {
        ...base,
        uuid: observation.uuid,
        name: observation.name,
        program: observation.program,
        status: observation.status,
        data: observation.data,
      }
    case 'free_operant_observation':
      return {
        ...base,
        uuid: observation.uuid,
        name: observation.name,
        status: observation.status,
        data: observation.data,
      }
    case 'timestamp':
      return {
        ...base,
        uuid: observation.uuid,
        comment: observation.comment,
      }
    default:
      throw new Error('invalid observation type')
  }
}

function splitSummaryTrials(
  observations: ObservationData[],
): ObservationData[] {
  const summaryTrials = observations.filter(
    (observation) =>
      observation.type === 'free_operant_observation' &&
      typeof observation.data.summary_trial === 'object',
  )

  summaryTrials.forEach((observation) => {
    if (
      observation.type === 'free_operant_observation' &&
      typeof observation.data.summary_trial === 'object'
    ) {
      const {
        trial = 0,
        start_time = 0,
        observed_intervals = 0,
        not_observed_intervals = 0,
        hours = 0,
        minutes = 0,
        seconds = 0,
        end_time = 0,
      } = observation.data.summary_trial
      //creates summary start_trial
      const start_trial = {
        ...observation,
        createdAt: new Date(start_time),
        uuid: `${observation.uuid}-start`,
        data: {
          status: 'start_trial',
          summary_trial: {
            trial: trial,
          },
        },
      }
      //creates summary end_trial
      const end_trial = {
        ...observation,
        createdAt: new Date(end_time),
        uuid: `${observation.uuid}-end`,
        data: {
          status: 'end_trial',
          summary_trial: {
            trial: trial,
            observed_intervals: observed_intervals,
            not_observed_intervals: not_observed_intervals,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
          },
        },
      }

      //insert both observations inside previous index
      const indexOfObservation = observations.indexOf(observation)
      observations = [
        ...observations.slice(0, indexOfObservation),
        ...[start_trial, end_trial],
        ...(indexOfObservation < observations.length - 1
          ? observations.slice(indexOfObservation + 1)
          : []),
      ]
    }
  })

  return observations
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseObservations(observations: any): ObservationData[] {
  if (!Array.isArray(observations)) {
    throw new Error('expected observations to be an array')
  }

  return splitSummaryTrials(observations.map(parseObservation))
}
