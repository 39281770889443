import React from 'react'

import { Button } from '@/core/mantine-ui'
import { PushNotification } from '@/core/services/websocket/services'

interface NotificationDescriptionProps {
  description: PushNotification['description']
  actionLink: PushNotification['action_link']
}

export const NotificationDescription = ({
  description,
  actionLink,
}: NotificationDescriptionProps) => {
  if (!actionLink) return <div>{description}</div>

  return (
    <div>
      <p className="mb-4">{description}</p>

      <Button size="sm" onClick={() => window.open(actionLink.url, '_blank')}>
        {actionLink.label}
      </Button>
    </div>
  )
}
