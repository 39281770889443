import { FeatureDefinition } from '@growthbook/growthbook-react'
import * as Sentry from '@sentry/react'
import { htmlTreeAsString } from '@sentry/utils'

import { config } from '../config'
import { devModeLog } from '../helpers/logging'

import type { Context, CaptureContext } from '@sentry/types'

let initialized = false

const SENTRY_DSN = config.sentryDsn
const SENTRY_ENV = config.sentryEnvironment
const TRACE_SAMPLE_RATE = config.traceSampleRate

const CLICKABLE_TAG_NAMES = [
  'a',
  'button',
  'input',
  'select',
  'textarea',
  'details',
  'dialog',
  'menu',
  'summary',
]

export const ErrorMonitoring = {
  init() {
    if (SENTRY_DSN && SENTRY_ENV && !initialized) {
      console.log('[Error monitoring]: Initializing')

      Sentry.init({
        dsn: SENTRY_DSN,
        transport: Sentry.makeBrowserOfflineTransport(
          Sentry.makeFetchTransport,
        ),
        transportOptions: {
          dbName: 'sentry-offline',
          storeName: 'queue',
          maxQueueSize: 30,
          flushAtStartup: false,
          shouldStore: () => true,
        },
        environment: SENTRY_ENV,
        tracesSampleRate: TRACE_SAMPLE_RATE,
        sendDefaultPii: true,
        release: process.env.SENTRY_RELEASE,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: true,
          }),
        ],

        /**
         * a list of messages to be filtered out before being sent to Sentry as
         * either regular expressions or strings. When using strings, they’ll
         * partially match the messages, so if you need to achieve an exact match,
         * use RegExp patterns instead
         *
         * via https://docs.sentry.io/clients/javascript/config/
         */
        ignoreErrors: [],

        /**
         * Improve sentry breadcrumbs for UI clicks. Sometimes the target
         * of a click is a non-clickable child element. In this case we just
         * see some garbage classnames in sentry which make it difficult to
         * understand which element was clicked. This at least appends some
         * additional information about the nearest clickable.
         */
        beforeBreadcrumb: (breadcrumb, hint) => {
          devModeLog('[Sentry] Before breadcrumb', breadcrumb, hint)

          if (breadcrumb.category === 'ui.click') {
            const clickableElement = findClosestClickableElement(hint?.event)

            if (clickableElement && clickableElement !== hint?.event?.target) {
              return {
                ...breadcrumb,
                message: `Immediate Target: ${
                  breadcrumb.message
                }\nClosest Clickable: ${htmlTreeAsString(clickableElement)}`,
              }
            }
          }

          return breadcrumb
        },
      })
      Sentry.setUser({
        email: window.email,
        id: window.UUID,
      })

      initialized = true
    }
  },

  setAppFeatures(features: Record<string, FeatureDefinition>) {
    devModeLog('[Error monitoring]: Setting app features', features)

    Sentry.setContext('App Features', features)
  },

  clearScope() {
    Sentry.configureScope((scope) => scope.clear())
  },

  captureException(
    error: Error,
    extraInfo: Record<string, Context | null> = {},
    captureContext?: CaptureContext,
  ) {
    devModeLog(
      '[Error monitoring]: Capturing exception',
      error,
      extraInfo,
      captureContext,
    )

    Sentry.withScope((scope) => {
      Object.entries(extraInfo).forEach(([key, value]) =>
        scope.setContext(key, value),
      )

      Sentry.captureException(error, captureContext)
    })
  },

  captureMessage(message: string, extraInfo = {}) {
    devModeLog('[Error monitoring]: Capturing message', message, extraInfo)

    Sentry.withScope((scope) => {
      Object.entries(extraInfo).forEach(([key, value]) =>
        scope.setContext(key, value),
      )

      Sentry.captureMessage(message)
    })
  },
}

const findClosestClickableElement = ({ target }: { target: HTMLElement }) => {
  if (CLICKABLE_TAG_NAMES.includes(target.tagName.toLowerCase())) {
    return target
  }

  return target.closest(CLICKABLE_TAG_NAMES.join(', '))
}
