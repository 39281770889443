import { createRoot } from 'react-dom/client'
import React, { lazy, Suspense } from 'react'

import spinner from '../../../../../images/spinner.gif'

const QuickSessionRoot = lazy(() => import('./index'))

export const mountQuickSession = () => {
  const schedule_session_root = document.getElementById('schedule_session')

  if (schedule_session_root) {
    createRoot(schedule_session_root).render(
      <Suspense
        fallback={
          <div className="flex justify-center">
            <img className="w-48 h-48" src={spinner} alt="loading..." />
          </div>
        }
      >
        <QuickSessionRoot />
      </Suspense>,
    )
  }
}
