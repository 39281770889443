import { z } from 'zod'

import { NotificationWSClientEvents } from '../constants'

export const notificationListClientMessageSchema = z.object({
  event_type: z.literal(NotificationWSClientEvents.LIST),
})

export const notificationCountClientMessageSchema = z.object({
  event_type: z.literal(NotificationWSClientEvents.COUNT),
})

export const dismissNotificationClientMessageSchema = z.object({
  event_type: z.literal(NotificationWSClientEvents.DISMISS),
  data: z.object({
    uuid: z.string(),
  }),
})

export const markAsReadClientMessageSchema = z.object({
  event_type: z.literal(NotificationWSClientEvents.MARK_AS_READ),
  data: z.object({
    uuid: z.string(),
  }),
})

export const markAsUnreadClientMessageSchema = z.object({
  event_type: z.literal(NotificationWSClientEvents.MARK_AS_UNREAD),
  data: z.object({
    uuid: z.string(),
  }),
})

export const markAllAsReadClientMessageSchema = z.object({
  event_type: z.literal(NotificationWSClientEvents.MARK_ALL_READ),
})

export type NotificationListClientMessage = z.infer<
  typeof notificationListClientMessageSchema
>

export type NotificationCountClientMessage = z.infer<
  typeof notificationCountClientMessageSchema
>

export type DismissNotificationClientMessage = z.infer<
  typeof dismissNotificationClientMessageSchema
>

export type MarkAsReadClientMessage = z.infer<
  typeof markAsReadClientMessageSchema
>

export type MarkAsUnreadClientMessage = z.infer<
  typeof markAsUnreadClientMessageSchema
>

export type MarkAllAsReadClientMessage = z.infer<
  typeof markAllAsReadClientMessageSchema
>
