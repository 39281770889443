import React from 'react'
import { IfFeatureEnabled as GrowthbookIfFeatureEnabled } from '@growthbook/growthbook-react'

import { AppFeatures } from '@/core/services/growthbook/types'

export const IfFeatureEnabled = ({
  children,
  feature,
}: {
  children: React.ReactNode
  feature: keyof AppFeatures
}) => {
  return (
    <GrowthbookIfFeatureEnabled feature={feature}>
      {children}
    </GrowthbookIfFeatureEnabled>
  )
}
