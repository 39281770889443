import { GrowthBookProvider } from '@growthbook/growthbook-react'
import React from 'react'

import { growthbook } from './growthbook'

export function GrowthbookWrapper({ children }: { children: React.ReactNode }) {
  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  )
}
