// When changing the offline config, make sure to update logout.js to match
// We have a ticket to fix this: https://spectrumai.atlassian.net/browse/TW-2814
export const config = {
  env: process.env.NODE_ENV,
  i18nVersions: process.env.LOCALE_VERSIONS,
  apiRequestTimeout: 50000,
  MAX_TTL_MILLIS: 1000 * 60 * 30,
  TTL: 'TTL',
  swURL: '/sw.js',
  DB_NAME: 'offline_twyll',
  DB_VERSION: 4,
  URL_BLOCKLIST: ['/accounts/login/'],
  symmKey: 'symmKey',
  SYNC_INTERVAL: 1000 * 60 * 60, // one hour in seconds
  SYMMETRIC_KEY_ID: '1', // always use 1 so we overwrite the old key when we get a new one
} as const
