import React from 'react'
import { Menu as MantineMenu, MenuProps } from '@mantine/core'

export const Menu = ({ ...props }: MenuProps) => {
  return <MantineMenu {...props} />
}

Menu.Item = MantineMenu.Item
Menu.Label = MantineMenu.Label
Menu.Target = MantineMenu.Target
Menu.Divider = MantineMenu.Divider
Menu.Dropdown = MantineMenu.Dropdown
