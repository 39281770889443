import React, { PropsWithChildren } from 'react'

import {
  DocumentClaimServerMessage,
  DocumentsServerWSEvent,
} from '@/core/services/websocket/services/documents-ws-provider/schemas'
import { useWebsocketManager } from '@/core/services/websocket/useWebsocketManager'

import { DocumentsWsContext } from './context'
import { useDocumentsEventHandlers, useDocumentsMessagesEvents } from './hooks'

interface DocumentsWSProviderProps extends PropsWithChildren {
  onClaimChange?: (message: DocumentClaimServerMessage) => void
}

export const DocumentsWSProvider = ({
  children,
  onClaimChange,
}: DocumentsWSProviderProps) => {
  const { lastJsonMessage } =
    useWebsocketManager<DocumentsServerWSEvent | null>({})

  const { handleClaimChange } = useDocumentsEventHandlers({
    onClaimChange,
  })

  useDocumentsMessagesEvents({
    lastJsonMessage,
    onClaimChange: handleClaimChange,
  })

  return (
    <DocumentsWsContext.Provider value={{}}>
      {children}
    </DocumentsWsContext.Provider>
  )
}
