import { z } from 'zod'

export const programEstimatedMasteryAttributesSchema = z
  .object({
    reportType: z.string().nullish(),
  })
  .or(z.object({}))

export type ProgramEstimatedMasteryAttributes = z.infer<
  typeof programEstimatedMasteryAttributesSchema
>
