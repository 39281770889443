import {
  format as formatDate,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  isDate,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
// eslint-disable-next-line import/no-duplicates
import { enUS } from 'date-fns/locale'

export const DATE_LOCALES = {
  'en-us': enUS,
}

export const dateFormatterLongMonthYearDayUTC = new Intl.DateTimeFormat(
  'en-US',
  {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  },
)

export const dateFormatterShortMonthYearDayUTC = new Intl.DateTimeFormat(
  'en-US',
  {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  },
)

export const dateFormatterShortMonthYearDayTimeUTC = new Intl.DateTimeFormat(
  'en-US',
  {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  },
)

export const dateFormatterShortMonthDayUTC = new Intl.DateTimeFormat('en-US', {
  month: 'short',
  day: 'numeric',
  timeZone: 'UTC',
})

// @NOTE(shawk): yyyy-mm-dd
export function toServerDate(date: Date) {
  return date.toISOString().split('T')[0]
}

const DISTANCE_UNITS = [
  'second',
  'minute',
  'hour',
  'day',
  'month',
  'year',
] as const

type FormatDateOptions = {
  format?: 'long' | 'short' | string
}

export function dateFormatter(
  value: Date,
  lng: string,
  options: FormatDateOptions,
) {
  if (!isDate(value)) {
    throw new Error(
      'Invalid non-date value passed to i18n `distance` formatter',
    )
  }

  if (!options.format) {
    throw new Error('Missing format option for i18n `date` formatter')
  }

  switch (options.format) {
    case 'long':
      return formatDate(value, 'Pp', { locale: validateFormatterLocale(lng) })
    case 'short':
      return formatDate(value, 'P', { locale: validateFormatterLocale(lng) })
    case 'full-month':
      return formatDate(value, 'PP', {
        locale: validateFormatterLocale(lng),
      })

    default:
      return formatDate(value, options.format, {
        locale: validateFormatterLocale(lng),
      })
  }
}

export const dateToTimeFormatter = (
  value: Date,
  lng: string,
  options: FormatDateOptions,
) => {
  if (!isDate(value)) {
    throw new Error('Invalid non-date value passed to i18n `time` formatter')
  }

  if (!options.format) {
    throw new Error('Missing format option for i18n `time` formatter')
  }

  switch (options.format) {
    case 'short':
      return formatDate(value, 'HH:mm', {
        locale: validateFormatterLocale(lng),
      })
    case 'shortWithAMPM':
      return formatDate(value, 'hh:mm a', {
        locale: validateFormatterLocale(lng),
      })

    default:
      return formatDate(value, options.format, {
        locale: validateFormatterLocale(lng),
      })
  }
}

type FormatDistanceOptions = {
  addSuffix?: boolean
} & (
  | {
      unit: (typeof DISTANCE_UNITS)[number]
      roundingMethod?: 'floor' | 'ceil' | 'round'
    }
  | {
      unit?: never
      includeSeconds?: boolean
    }
)

/**
 * Returns the distance between the given UTC date and now.
 *
 * @NOTE(shawk): if `unit` is specified, used the strict distance formatter
 * to force the specific unit to be used, otherwise use the default distance
 * formatter which will use the most appropriate unit
 */
export function distanceFormatter(
  value: Date,
  lng: string,
  options: FormatDistanceOptions,
) {
  if (!isDate(value)) {
    throw new Error(
      'Invalid non-date value passed to i18n `distance` formatter',
    )
  }

  // @NOTE(shawk): assumes `value` is in UTC and is being compared to a local date
  value = toZonedTime(value, 'UTC')

  if (options.unit) {
    if (!DISTANCE_UNITS.includes(options.unit)) {
      throw new Error(
        `Invalid unit "${options.unit}" passed to i18n \`distance\` formatter`,
      )
    }

    return formatDistanceToNowStrict(value, {
      locale: validateFormatterLocale(lng),
      unit: options.unit,
      roundingMethod: options.roundingMethod,
      addSuffix: options.addSuffix,
    })
  }

  return formatDistanceToNow(value, {
    locale: validateFormatterLocale(lng),
    includeSeconds: options.includeSeconds,
    addSuffix: options.addSuffix,
  })
}

function validateFormatterLocale(lng: string | undefined) {
  if (!lng || !(lng in DATE_LOCALES)) {
    throw new Error(`Missing or invalid language "${lng}"`)
  }

  return DATE_LOCALES[lng as keyof typeof DATE_LOCALES]
}
