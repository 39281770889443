import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from 'react'

import { OfflineApplication } from '../OfflineApplication'

const offlineApplication = new OfflineApplication()
const OfflineApplicationContext = createContext({
  initializing: true,
  offlineApplication,
})

const OfflineApplicationProvider = ({ children }: { children: ReactNode }) => {
  const [initializing, setInitializing] = useState(false)

  const initializeOfflineApplication = async () => {
    setInitializing(true)
    try {
      await offlineApplication.init()
    } catch (err) {
      console.error('Error initializing offline application', err)
    } finally {
      setInitializing(false)
    }
  }

  useEffect(() => {
    initializeOfflineApplication()
  }, [])

  return (
    <OfflineApplicationContext.Provider
      value={{ initializing, offlineApplication }}
    >
      {children}
    </OfflineApplicationContext.Provider>
  )
}

const useOfflineApplication = () => {
  return useContext(OfflineApplicationContext)
}

export { OfflineApplicationProvider, useOfflineApplication }
