/**
 * Implements amazon-kinesis-video-streams-webrtc RequestSigner.
 * Return a static value when called to sign a URL.
 */
export class StaticRequestSigner {
  constructor(url) {
    this.signedURL = url
  }

  getSignedURL() {
    return Promise.resolve(this.signedURL)
  }
}
