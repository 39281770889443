import { Role, SignalingClient } from 'amazon-kinesis-video-streams-webrtc'

import { StaticRequestSigner } from './static_request_signer'

export class VideoPeerConnection {
  constructor(config, stream) {
    this.config = config
    this.stream = stream
  }

  get state() {
    return this.pc ? this.pc.connectionState : 'new'
  }

  addStateChangeListener(listener) {
    this.pc.addEventListener('connectionstatechange', listener)
  }

  removeStateChangeListener(listener) {
    this.pc.removeEventListener('connectionstatechange', listener)
  }

  connect() {
    const { channelARN, channelEndpoint, region, signedURL, iceServers } =
      this.config

    this.sc = new SignalingClient({
      channelARN,
      channelEndpoint,
      region,
      clientId: 'client',
      role: Role.VIEWER,
      requestSigner: new StaticRequestSigner(signedURL),
    })

    // Attach signaling channel listeners
    this.sc.on('open', this.onSCOpen)
    this.sc.on('error', this.onSCError)
    this.sc.on('close', this.onSCClose)
    this.sc.on('sdpOffer', this.onSCSDPOffer)
    this.sc.on('iceCandidate', this.onSCICECandidate)

    this.pc = new RTCPeerConnection({
      // Concat the static STUN server with the list of TURN servers
      iceServers: [
        { urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443` },
      ].concat(iceServers),
    })

    // Add tracks
    this.stream.getTracks().forEach((track) => {
      this.pc.addTrack(track)
    })

    // Attach peer connection listeners
    this.pc.addEventListener('icecandidate', this.onPCICECandidate)
    this.pc.addEventListener(
      'icegatheringstatechange',
      this.onPCICEGatheringStateChange,
    )
    this.pc.addEventListener(
      'iceconnectionstatechange',
      this.onPCICEConnectionStateChange,
    )

    this.sc.open()
  }

  dispose() {
    // Detach peer connection listeners
    this.pc.removeEventListener('icecandidate', this.onPCICECandidate)
    this.pc.removeEventListener(
      'icegatheringstatechange',
      this.onPCICEGatheringStateChange,
    )
    this.pc.removeEventListener(
      'iceconnectionstatechange',
      this.onPCICEConnectionStateChange,
    )

    if (this.pc) {
      this.pc.close()
    }

    if (this.sc) {
      this.sc.close()
    }
  }

  onSCOpen = async () => {
    console.debug('onSCOpen')
  }

  onSCError = (error) => {
    console.debug('onSCError', error)

    // Handle signaling channel errors
  }

  onSCClose = () => {
    console.debug('onSCClose')
  }

  // When the SDP offer is received from the master, add it to the peer connection.
  onSCSDPOffer = async (offer) => {
    console.debug('onSCSDPOffer', offer)

    await this.pc.setRemoteDescription(offer)

    const answer = await this.pc.createAnswer()
    await this.pc.setLocalDescription(answer)

    console.debug('sending SDP answer', this.pc.localDescription)

    this.sc.sendSdpAnswer(this.pc.localDescription)
  }

  // When an ICE candidate is received from the master, add it to the peer connection.
  onSCICECandidate = (candidate) => {
    console.debug('onSCICECandidate', candidate)

    this.pc.addIceCandidate(candidate)
  }

  // When an ICE candidate is generated from the client, send it to the master.
  onPCICECandidate = (event) => {
    console.debug('onPCICECandidate', event)

    if (event.candidate) {
      this.sc.sendIceCandidate(event.candidate)
    } else {
      // No more ICE candidates will be generated
    }
  }

  onPCICEGatheringStateChange = (event) => {
    console.debug('onPCICEGatheringStateChange', event)
  }

  onPCICEConnectionStateChange = () => {
    console.debug('onPCICEConnectionStateChange', this.pc.iceConnectionState)
  }
}
