import colors from 'tailwindcss/colors'

// GRAY COLORS is based on https://tailwindcss.com/docs/customizing-colors - Grey palette

const GRAY_50 = '#f9fafb'
const GRAY_100 = '#f3f4f6'
const GRAY_200 = '#e5e7eb'
const GRAY_300 = '#d1d5db'
const GRAY_400 = '#9ca3af'
const GRAY_500 = '#6b7280'
const GRAY_600 = '#4b5563'
const GRAY_700 = '#374151'
const GRAY_800 = '#1f2937'
const GRAY_900 = '#111827'
const GRAY_950 = '#030712'

export const GRAY_MANTINE_PALETTE = [
  GRAY_50,
  GRAY_100,
  GRAY_200,
  GRAY_300,
  GRAY_400,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_800,
  GRAY_900,
  GRAY_950,
] as const

export const GRAY_TAILWIND_PALETTE = colors.gray
