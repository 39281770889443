import { config } from '@/offline/config'

import { Stores } from '../models'
import { importCryptoKey } from '../services/DefaultCryptographyService'
import { LocalDataManagerInterface } from '../interfaces/LocalDataManagerInterface'
import { SymmetricKeyManagerInterface } from '../interfaces/SymmetricKeyManagerInterface'

export default class SymmetricKeyManager
  implements SymmetricKeyManagerInterface
{
  private localDataManager: LocalDataManagerInterface

  constructor(localDataManager: LocalDataManagerInterface) {
    this.localDataManager = localDataManager
  }

  async get() {
    await this.localDataManager.initDB()
    const symmetricKey = await this.localDataManager.getStoreData(
      Stores.SymmetricKey,
    )

    if (symmetricKey.length && symmetricKey[0]) {
      return symmetricKey[0].symmetric_key as CryptoKey
    }

    return this.fetchAndCache()
  }

  async fetchAndCache() {
    const response = await fetch('/api/users/v1/user/symmetric-key', {
      credentials: 'same-origin',
    })

    if (response.status >= 400 && response.status <= 499) {
      this.localDataManager.deleteData(
        Stores.SymmetricKey,
        config.SYMMETRIC_KEY_ID,
      )
      const cacheName = 'workbox-runtime-' + window.location.origin + '/'
      const cache = await caches.open(cacheName)
      await cache.delete('/api/users/v1/user/symmetric-key')
      deleteAllCookies()
      window.location.href = '/accounts/login'
    }

    const json = await response.json()

    if (!json.symmetric_key) {
      throw new Error('No symmetric key returned')
    }

    const key = await importCryptoKey(json.symmetric_key)

    await this.localDataManager.initDB()
    await this.localDataManager.addData(Stores.SymmetricKey, {
      id: config.SYMMETRIC_KEY_ID,
      symmetric_key: key,
    })

    return key
  }
}

// https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
function deleteAllCookies() {
  const cookies = document.cookie.split('; ')
  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split('.')
    while (d.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path='
      const p = location.pathname.split('/')
      document.cookie = cookieBase + '/'
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/')
        p.pop()
      }
      d.shift()
    }
  }
}
