import {
  TEAL_BLUE_MANTINE_PALLETE,
  GRAY_MANTINE_PALETTE,
  GREEN_MANTINE_PALLETE,
  AMBER_MANTINE_PALETTE,
  RED_MANTINE_PALETTE,
} from './colors-pallete'

export const PRIMARY_COLOR = TEAL_BLUE_MANTINE_PALLETE[8]
export const PRIMARY_HOVER = TEAL_BLUE_MANTINE_PALLETE[7]
export const PRIMARY_ACTIVE = TEAL_BLUE_MANTINE_PALLETE[9]

export const PRIMARY_TEXT_COLOR = GRAY_MANTINE_PALETTE[8]
export const SECONDARY_TEXT_COLOR = GRAY_MANTINE_PALETTE[5]

export const DEFAULT_BORDER_COLOR = '#D4D4D4'

export const DEFAULT_LINK_COLOR = PRIMARY_COLOR

export const SUCCESS_COLOR = GREEN_MANTINE_PALLETE[5]
export const WARNING_COLOR = AMBER_MANTINE_PALETTE[4]
export const DANGER_COLOR = RED_MANTINE_PALETTE[6]
