export const PROGRAM_TARGET_STATUSES = [
  'not_started',
  'baseline',
  'intervention',
  'maintenance_daily',
  'maintenance_eod',
  'maintenance_weekly',
  'maintenance_every_other_week',
  'maintenance_monthly',
  'on_hold',
  'completed',
  'archived',
] as const

export const MAINTENANCE_STATUSES: [
  'maintenance_daily',
  'maintenance_eod',
  'maintenance_weekly',
  'maintenance_every_other_week',
  'maintenance_monthly',
] = [
  'maintenance_daily',
  'maintenance_eod',
  'maintenance_weekly',
  'maintenance_every_other_week',
  'maintenance_monthly',
]

export type MaintananceStatuses = (typeof MAINTENANCE_STATUSES)[number]
export type TargetStatus = (typeof PROGRAM_TARGET_STATUSES)[number]
