import { Controller } from '@hotwired/stimulus'
import gsap from 'gsap'

export default class extends Controller {
  close() {
    // fade toast and then remove el
    gsap.timeline().to(this.element, {
      autoAlpha: 0,
      duration: 0.3,
      onComplete: () => {
        this.element.remove()
      },
    })
  }
}
