import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { classesMerge } from '@/core/helpers/classes-merge'

interface DescriptionProps {
  description: string | null
  isRead: boolean
}

export const Description = ({ description, isRead }: DescriptionProps) => {
  const { t } = useTranslation()
  const descriptionRef = useRef<HTMLParagraphElement>(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    if (descriptionRef.current) {
      const { scrollHeight, clientHeight } = descriptionRef.current
      if (scrollHeight > clientHeight) {
        setIsOverflowing(true)
      }
    }
  }, [])

  const toggleExpand = () => setIsExpanded((prev) => !prev)

  return (
    <div className="relative">
      <p
        ref={descriptionRef}
        className={classesMerge('overflow-hidden text-xs', {
          ['max-h-none']: isExpanded,
          ['max-h-[70px]']: !isExpanded,
          ['text-secondary-text']: isRead,
        })}
      >
        {description}
      </p>
      {!isExpanded && isOverflowing && (
        <div className="absolute left-0 right-0 h-40 pointer-events-none bottom-20 bg-gradient-to-b from-transparent to-white" />
      )}
      {isOverflowing && (
        <button onClick={toggleExpand} className="text-xs text-secondary-text">
          {isExpanded ? t('Collapse') : t('Show more')}
        </button>
      )}
    </div>
  )
}
