import React, { PropsWithChildren } from 'react'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import { createRoot } from 'react-dom/client'

import I18nWrapper from '@/core/components/I18nWrapper'
import { GrowthbookWrapper } from '@/core/services/growthbook'
import { mountQuickSession } from '@/offline/components/schedule-offline-session/helper'
import { NotificationPanel } from '@/notifications-center/NotificationPanel'
import { NotificationsWrapper } from '@/core/services/notifications'
import { Sync } from '@/sync/Sync'
import { mountPostSessionReact } from '@/ps/helper'
import { MantineProvider } from '@/core/mantine-ui'

import '@/core/prelude'
import '../css/styles.css'
import './nonce'

window.Stimulus = Application.start()
const context = require.context('./controllers', true, /\.js$/)
window.Stimulus.load(definitionsFromContext(context))

// mount Sync dropdown in the Header
const syncRootElement = document.getElementById('sync-root')
const notificationPanelRoot = document.getElementById('notification-panel-root')

const RootComponent = ({ children }: PropsWithChildren) => (
  <React.StrictMode>
    <GrowthbookWrapper>
      <I18nWrapper>
        <MantineProvider>{children}</MantineProvider>
      </I18nWrapper>
    </GrowthbookWrapper>
  </React.StrictMode>
)

if (syncRootElement) {
  createRoot(syncRootElement).render(
    <RootComponent>
      <Sync />
    </RootComponent>,
  )
}

// mount notifications panel in the Hedaer

if (notificationPanelRoot) {
  createRoot(notificationPanelRoot).render(
    <RootComponent>
      <NotificationsWrapper>
        <NotificationPanel />
      </NotificationsWrapper>
    </RootComponent>,
  )
}

// mount Post Session page React app
mountPostSessionReact()

//mount Quick Session
mountQuickSession()
