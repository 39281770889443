import { Controller } from '@hotwired/stimulus'
import SignaturePad from 'signature_pad'

// https://github.com/szimek/signature_pad/blob/gh-pages/js/signature_pad.js
export default class extends Controller {
  static targets = ['canvas', 'signatureDataInput']

  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget)

    this.signaturePad.addEventListener('endStroke', () => {
      this.saveSignatureData()
    })
  }

  saveSignatureData() {
    this.signatureDataInputTarget.value = this.signaturePad.toDataURL()
  }

  clearSignature() {
    this.signaturePad.clear()
    this.signatureDataInputTarget.value = ''
  }
}
