import React from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@/core/components/icons/Icon'
import { classesMerge } from '@/core/helpers/classes-merge'
import { useIsOnline } from '@/offline/hooks/useIsOnline'

import { getSyncStatus } from './config'
import { getLastSyncDateTime } from './helpers'
interface SyncCardProps {
  isVisible: boolean
  title: string
  handleSyncData: () => void
  disabled: boolean
}

export const SyncCard = ({
  isVisible,
  title,
  handleSyncData,
  disabled,
}: SyncCardProps) => {
  const { t } = useTranslation(['common'])
  const { isOnline } = useIsOnline()

  const syncStatus = getSyncStatus(t)

  const lastSyncDate = localStorage.getItem('last-sync')
  const lastSyncLabel = getLastSyncDateTime(lastSyncDate)

  return isVisible ? (
    <section className="absolute top-[60px] right-20 bg-white w-[224px] pt-10 shadow-card rounded">
      <div className="pb-10 pl-20 divide-gray-400 border-b-1">
        <p
          className={classesMerge('font-semibold text-black', {
            'text-red-500': syncStatus.CONNECTION_TIMEOUT.title === title,
          })}
        >
          {title}
        </p>
        <span className="text-gray-600">
          {t('Last sync {{lastSyncLabel}}', { lastSyncLabel, ns: 'common' })}
        </span>
      </div>
      {isOnline ? (
        <button
          className="flex justify-start w-full btn pl-[28px] rounded-none"
          onClick={handleSyncData}
          disabled={disabled}
          aria-label="Sync button"
        >
          <div className="pt-4">
            <Icon icon="sync" />
          </div>
          <span className="text-black">{t('Sync now', { ns: 'common' })}</span>
        </button>
      ) : null}
    </section>
  ) : null
}
