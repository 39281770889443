import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { SYNC_EVENT_TYPES } from '@/offline/interfaces/SyncEventBusManagerInterface'
import { useIsOnline } from '@/offline/hooks/useIsOnline'
import { useOfflineApplication } from '@/offline/hooks/useOfflineApplication'

import { getSyncStatus } from '../config'

export const useListenSyncData = () => {
  const { t } = useTranslation(['common'])
  const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"

  const { SYNCED, NO_CONNECTION, SYNCING, CONNECTION_TIMEOUT } =
    getSyncStatus(t)

  const { isOnline } = useIsOnline()
  const {
    offlineApplication: { syncEventBusManager: syncBus },
  } = useOfflineApplication()
  const [syncStatus, setSyncStatus] = useState(
    isOnline ? SYNCED : NO_CONNECTION,
  )

  useEffect(() => {
    syncBus.addEventListener(SYNC_EVENT_TYPES.START, () => {
      setSyncStatus(SYNCING)
    })
    syncBus.addEventListener(SYNC_EVENT_TYPES.SUCCESS, () => {
      localStorage.setItem('last-sync', format(new Date(), dateFormat))
      setSyncStatus(SYNCED)
    })
    syncBus.addEventListener(SYNC_EVENT_TYPES.ERROR, () => {
      setSyncStatus(CONNECTION_TIMEOUT)
    })

    window.addEventListener('offline', () => {
      setSyncStatus(NO_CONNECTION)
    })
    window.addEventListener('online', () => {
      setSyncStatus(SYNCED)
    })
  }, [CONNECTION_TIMEOUT, NO_CONNECTION, SYNCED, SYNCING, syncBus])

  return { syncStatus }
}
