import React, { PropsWithChildren } from 'react'

import { NotificationWSProvider } from '@/core/services/websocket'

import { NotificationsManagerProvider } from './services'

export const NotificationsWrapper = ({ children }: PropsWithChildren) => {
  return (
    <>
      <NotificationsManagerProvider>
        <NotificationWSProvider>{children}</NotificationWSProvider>
      </NotificationsManagerProvider>
    </>
  )
}
