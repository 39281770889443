import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['roleTypeSelect', 'accessLevelWrapper']

  connect() {
    this.toggle()
  }

  toggle() {
    let role = this.roleTypeSelectTarget.value

    if (role === 'Therapist' || role === 'Supervisor') {
      this.accessLevelWrapperTarget.classList.remove('hidden')
    } else {
      this.accessLevelWrapperTarget.classList.add('hidden')
    }
  }
}
