import React, { useEffect, useRef } from 'react'
import { CheckboxProps, Checkbox as MantineCheckbox } from '@mantine/core'

import styles from './Checkbox.module.scss'

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  indeterminate,
  size = 'sm',
  label,
  error,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = Boolean(indeterminate && !checked)
    }
  }, [indeterminate, checked])

  return (
    <MantineCheckbox
      ref={inputRef}
      classNames={styles}
      label={label}
      error={error}
      size={size}
      checked={checked}
      indeterminate={false}
      {...props}
    />
  )
}
