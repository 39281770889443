import { z } from 'zod'

export const iseSessionTargetStateFrequencyAndRateSchema = z.object({
  dataCollectionMethod: z.literal('frequency_and_rate'),
  observations: z.array(z.number()),
})

export type ISESessionTargetStateFrequencyAndRate = z.infer<
  typeof iseSessionTargetStateFrequencyAndRateSchema
>
