import React from 'react'

import { notifications } from '@/core/mantine-ui'
import { PushNotification } from '@/core/services/websocket/services'

import { NotificationDescription, NotificationTitle } from '../../components'
import {
  getNotificationBackgroundColor,
  getNotificationIcon,
} from '../../helpers'

export const usePostNotification = () => {
  const postNotification = ({
    title,
    description,
    action_link,
    category,
    notification_type,
    autoclose,
  }: PushNotification) => {
    notifications.show({
      title: <NotificationTitle title={title} />,
      message: (
        <NotificationDescription
          description={description}
          actionLink={action_link}
        />
      ),
      color: getNotificationBackgroundColor(category),
      icon: getNotificationIcon(notification_type, category),
      autoClose: autoclose ?? undefined,
    })
  }

  return { postNotification }
}
