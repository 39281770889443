/**
 * This module is used to initialize global services that must always be
 * initialized before other application modules.
 */
import { pdfjs } from 'react-pdf'

import { config } from '@/core/config'

import { ErrorMonitoring } from './services/error-monitoring'

if (config.enableErrorReporting) {
  ErrorMonitoring.init()
}

// Fixes error for PDFViewer on Safari browser
if (typeof Promise.withResolvers === 'undefined') {
  if (window)
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    window.Promise.withResolvers = function () {
      let resolve, reject
      const promise = new Promise((res, rej) => {
        resolve = res
        reject = rej
      })
      return { promise, resolve, reject }
    }
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url,
).toString()
