// BLUE COLORS is base on Spectrum AI color palette (taken from figma)

const BLUE_50 = '#ECF4FA'
const BLUE_100 = '#CEE0EF'
const BLUE_200 = '#9DC1DF'
const BLUE_300 = '#6BA2D0'
const BLUE_400 = '#3A83C0'
const BLUE_500 = '#0964B0'
const BLUE_600 = '#07508D'
const BLUE_700 = '#053C6A'
const BLUE_800 = '#042846'
const BLUE_900 = '#021423'

export const BLUE_MANTINE_PALETTE = [
  BLUE_50,
  BLUE_100,
  BLUE_200,
  BLUE_300,
  BLUE_400,
  BLUE_500,
  BLUE_600,
  BLUE_700,
  BLUE_800,
  BLUE_900,
] as const

export const BLUE_TAILWIND_PALETTE = {
  50: BLUE_50,
  100: BLUE_100,
  200: BLUE_200,
  300: BLUE_300,
  400: BLUE_400,
  500: BLUE_500,
  600: BLUE_600,
  700: BLUE_700,
  800: BLUE_800,
  900: BLUE_900,
}
