import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab']
  static values = {
    activeIndex: Number,
  }

  initialize() {
    this.windowResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // set default offset based on parent padding (if necessary)
        let offsetLeft = 0
        // set default gap
        const tabGap = 8
        // loop through each prior button and find width to determine offset
        for (let i = 0; i < this.tabTargets.indexOf(entry.target); i++) {
          offsetLeft += parseInt(this.tabTargets[i].dataset.width) + tabGap
        }

        // set data attributes for button's left/width
        entry.target.dataset.left = offsetLeft
        entry.target.dataset.width = Math.floor(
          entry.borderBoxSize[0].inlineSize,
        )
      }
    })
  }

  connect() {
    // run window resize observer on window resize
    this.tabTargets.forEach((tab) => {
      this.windowResizeObserver.observe(tab)
    })

    // delay adding transition until indicator is set (on load only)
    setTimeout(() => {
      this.element.classList.add('before:transition-all', 'before:duration-300')
      this.activeIndexValueChanged()
    }, 100)
  }

  disconnect() {
    // disconnect window resize observer
    this.windowResizeObserver.unobserve(this.tabTargets)
  }

  updateIndex(e) {
    // find pressed tab's index and set activeIndex
    this.activeIndexValue = this.tabTargets.indexOf(e.currentTarget)
  }

  setIndicator() {
    // make sure dataset width is defined
    if (this.tabTargets[this.activeIndexValue].dataset.width) {
      // set indicators x and width based on button
      this.element.style.cssText = `--position: ${
        this.tabTargets[this.activeIndexValue].dataset.left
      }px; --size: ${this.tabTargets[this.activeIndexValue].dataset.width}px`
    }
  }

  setActiveTab() {
    this.tabTargets.forEach((el) => {
      el.setAttribute('aria-selected', false)
    })

    this.tabTargets[this.activeIndexValue].setAttribute('aria-selected', true)
  }

  activeIndexValueChanged() {
    // check if no index selected or no tabs
    if (this.activeIndexValue == -1 || this.tabTargets.length === 0) return

    this.setActiveTab()
    this.setIndicator()
  }
}
