import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    /**
     * This gives an href address so hover will indicate where the page will navigate to, but it doesn't work if the user manually navigated to this page as opposed to hitting a broken link or redirect. As such I'm using it strictly for visual reasons, and the actual back interaction happens in the goBack function.
     */
    this.element.href = document.referrer
  }

  goBack(e) {
    e.preventDefault()
    return window.history.back()
  }
}
