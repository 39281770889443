import React, { forwardRef } from 'react'

import { MaterialIcon } from '@/core/components/material-icon'
import { ActionIcon } from '@/core/mantine-ui'

import { UnreadNotificationsBadge } from './components'

interface NotificationPanelTargetButtonProps
  extends React.ComponentPropsWithoutRef<'div'> {
  unreadCount: number
}

export const NotificationPanelTargetButton = forwardRef<
  HTMLDivElement,
  NotificationPanelTargetButtonProps
>(({ unreadCount, ...restProps }, ref) => {
  return (
    <div ref={ref} {...restProps}>
      <ActionIcon size={'lg'}>
        {unreadCount > 0 && <UnreadNotificationsBadge />}
        <MaterialIcon icon="notifications" size={28} />
      </ActionIcon>
    </div>
  )
})

NotificationPanelTargetButton.displayName = 'NotificationPanelTargetButton'
