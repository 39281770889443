import {
  differenceInMinutes,
  format,
  isToday,
  isYesterday,
  parseISO,
} from 'date-fns'
import i18n from 'i18next'

export const getLastSyncDateTime = (date: string | null) => {
  if (!date) {
    return i18n.t('is not available')
  }
  const storedDate = parseISO(date)
  const formattedTime = format(storedDate, 'hh:mm a')
  const minutesSinceSync = differenceInMinutes(new Date(), storedDate)
  if (minutesSinceSync < 1) {
    return i18n.t('just now')
  }
  if (isToday(storedDate)) {
    return `${formattedTime}`
  }
  if (isYesterday(storedDate)) {
    return i18n.t('yesterday')
  }
  return i18n.t('few days ago')
}
