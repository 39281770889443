import { z } from 'zod'

import {
  programDurationAttributesSchema,
  programEstimatedMasteryAttributesSchema,
  programFrequencyAndRateAttributesSchema,
  programPercentOfOpportunitiesAttributesSchema,
  programAttachmentsSchema,
  programCurriculumInformationSchema,
  programDataCollectionMethodSchema,
  programTarget,
  programTaskAnalysisAttributesSchema,
} from './program-schemas'

export const programSchema = z.object({
  attributes: z.union([
    programPercentOfOpportunitiesAttributesSchema,
    programTaskAnalysisAttributesSchema,
    programFrequencyAndRateAttributesSchema,
    programEstimatedMasteryAttributesSchema,
    programDurationAttributesSchema,
  ]),
  curriculumInformation: programCurriculumInformationSchema,
  dataCollectionMethod: programDataCollectionMethodSchema,
  domain: z.string(),
  goal: z.string().nullish(),
  goalTitle: z.string().nullish(),
  name: z.string(),
  order: z.number().nullish(),
  attachments: z.array(programAttachmentsSchema),
  isRecentlyUpdated: z.boolean(),
  sd: z.string().nullable(),
  targets: z.array(programTarget),
  updatedOn: z.string(),
  uuid: z.string(),
})

export type Program = z.infer<typeof programSchema>

export type ProgramAttributes = z.infer<typeof programSchema.shape.attributes>
