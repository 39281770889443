import {
  SYNC_EVENT_TYPES,
  SyncEventBusManagerInterface,
} from '../interfaces/SyncEventBusManagerInterface'

let bus: EventTarget
if (window.syncEventBus) {
  bus = window.syncEventBus
} else {
  bus = new EventTarget()
  window.syncEventBus = bus
}

export default class SyncEventBusManager
  implements SyncEventBusManagerInterface
{
  dispatch(e: Event) {
    bus.dispatchEvent(e)
  }

  addEventListener(type: keyof typeof SYNC_EVENT_TYPES, callback: () => void) {
    bus.addEventListener(type, callback)
  }
}
