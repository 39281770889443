import { GrowthBook } from '@growthbook/growthbook-react'

import { config } from '@/core/config'

import { ErrorMonitoring } from '../error-monitoring'

import { AppFeatures } from './types'

const GROWTHBOOK_ENABLED = !!config.growthbookClientKey

// Create a GrowthBook instance
export const growthbook = new GrowthBook<AppFeatures>({
  apiHost:
    config.mode === 'production'
      ? 'https://growthbook-proxy.prod.spectrumai.com'
      : 'http://localhost:4100',
  clientKey: config.growthbookClientKey,
  enableDevMode: config.mode !== 'production' ? true : false,
  enabled: GROWTHBOOK_ENABLED,
})

if (GROWTHBOOK_ENABLED) {
  // Load features from the GrowthBook API
  growthbook.loadFeatures({ autoRefresh: true, timeout: 2000 }).then(() => {
    ErrorMonitoring.setAppFeatures(growthbook.getFeatures())
  })

  // Set user attributes for targeting (from cookie, auth system, etc.)
  growthbook.setAttributes({
    user: window.user,
    email: window.email,
    signup_date: window.user_date_joined,
    agency: window.agency,
    clinic: window.clinic,
    isTherapistOnly: window.is_therapist_only,
    roles: window.user_role,
    isSpectrumAi: window.isSpectrumAi,
    browser: window.browser,
    device: window.device,
    operating_system: window.operating_system,
  })
}
