import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['videoDevicesList', 'audioDevicesList']
  static values = {
    videoDevices: Array,
    audioDevices: Array,
    selectedDeviceIndices: Array([null, null]),
  }

  async connect() {
    try {
      // Calling getUserMedia first prompts the user for permission
      // and sets up the correct constraints
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      })
      const devices = await navigator.mediaDevices.enumerateDevices()

      this.videoDevicesValue = devices.filter(
        (d) => d.kind === 'videoinput' && d.deviceId !== 'default',
      )
      this.audioDevicesValue = devices.filter(
        (d) => d.kind === 'audioinput' && d.deviceId !== 'default',
      )

      // Select default devices if they exist
      const indices = [-1, -1]
      if (this.videoDevicesValue.length > 0) {
        indices[0] = 0
      }
      if (this.audioDevicesValue.length > 0) {
        indices[1] = 0
      }
      this.selectedDeviceIndicesValue = indices
    } catch (ex) {
      console.log(ex.name + ': ' + ex.message)
    }
  }

  selectVideoDevice(event) {
    this.selectedDeviceIndicesValue = [
      event.params.index,
      this.selectedDeviceIndicesValue[1],
    ]
  }

  selectAudioDevice(event) {
    this.selectedDeviceIndicesValue = [
      this.selectedDeviceIndicesValue[0],
      event.params.index,
    ]
  }

  videoDevicesValueChanged() {
    this._updateVideoDevicesList()
  }

  audioDevicesValueChanged() {
    this._updateAudioDevicesList()
  }

  selectedDeviceIndicesValueChanged() {
    this._updateAudioDevicesList()
    this._updateVideoDevicesList()

    const [vi, ai] = this.selectedDeviceIndicesValue
    const vid = vi === -1 ? null : this.videoDevicesValue[vi].deviceId
    const aid = ai === -1 ? null : this.audioDevicesValue[ai].deviceId
    this.dispatch('deviceselect', {
      detail: {
        videoDeviceId: vid,
        audioDeviceId: aid,
      },
    })
  }

  _updateVideoDevicesList() {
    if (this.videoDevicesValue.length === 0) {
      this.videoDevicesListTarget.innerHTML =
        '<p class="text-sm">No devices</p>'
    } else {
      this.videoDevicesListTarget.innerHTML = this.videoDevicesValue
        .map((d, index) =>
          deviceBox(
            d,
            index,
            true,
            index === this.selectedDeviceIndicesValue[0],
          ),
        )
        .join('')
    }
  }

  _updateAudioDevicesList() {
    if (this.audioDevicesValue.length === 0) {
      this.audioDevicesListTarget.innerHTML =
        '<p class="text-sm">No devices</p>'
    } else {
      this.audioDevicesListTarget.innerHTML = this.audioDevicesValue
        .map((d, index) =>
          deviceBox(
            d,
            index,
            false,
            index === this.selectedDeviceIndicesValue[1],
          ),
        )
        .join('')
    }
  }
}

function deviceBox(d, i, isVideo, isSelected) {
  return `<button class="btn btn-sm py-4 min-h-40 h-auto !text-primary-text ${
    isSelected ? 'border-primary' : ''
  }"
    data-session-record-devices-index-param="${i}"
    data-action="session-record-devices#${
      isVideo ? 'selectVideoDevice' : 'selectAudioDevice'
    }">
      ${d.label}
  </button>`
}
