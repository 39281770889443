import colors from 'tailwindcss/colors'

// AMBER COLORS is based on https://tailwindcss.com/docs/customizing-colors - Amber palette

const AMBER_50 = '#fffbeb'
const AMBER_100 = '#fef3c7'
const AMBER_200 = '#fde68a'
const AMBER_300 = '#fcd34d'
const AMBER_400 = '#fbbf24'
const AMBER_500 = '#f59e0b'
const AMBER_600 = '#d97706'
const AMBER_700 = '#b45309'
const AMBER_800 = '#92400e'
const AMBER_900 = '#78350f'
const AMBER_950 = '#431407'

export const AMBER_MANTINE_PALETTE = [
  AMBER_50,
  AMBER_100,
  AMBER_200,
  AMBER_300,
  AMBER_400,
  AMBER_500,
  AMBER_600,
  AMBER_700,
  AMBER_800,
  AMBER_900,
  AMBER_950,
] as const

export const AMBER_TAILWIND_PALETTE = colors.amber
