import {
  iconAdd,
  iconAddNote,
  iconBlock,
  iconCheck,
  iconDescription,
  iconFlag,
  iconInfo,
  iconNotRecording,
  iconPause,
  iconPercent,
  iconPlay,
  iconPrompt,
  iconRecording,
  iconRemove,
  iconStop,
  iconTimer,
  iconTimerOff,
} from '../helpers/icons'
import {
  toAbsoluteHoursAndMinutes,
  toDuration,
  toDurationList,
} from '../helpers/utils'

export function renderEvents(sessionStartAt, events) {
  let index = 0
  return events
    .flatMap((sectionEvents) => {
      return sectionEvents.map((event) => {
        switch (event.type) {
          case 'session_start':
            return sessionStartEventHTML(index++, sessionStartAt, event)
          case 'session_pause':
            return sessionPauseEventHTML(index++, sessionStartAt, event)
          case 'session_end':
            return sessionEndEventHTML(index++, sessionStartAt, event)
          case 'recording_start':
            return recordingStartEventHTML(index++, sessionStartAt, event)
          case 'recording_end':
            return recordingEndEventHTML(index++, sessionStartAt, event)
          case 'observation':
            return observationEventHTML(index++, sessionStartAt, event)
          default:
            console.warn('Skipping event with unknown type', event)
            return ''
        }
      })
    })
    .join('')
}

function sessionStartEventHTML(index, sessionStartAt, event) {
  return sessionEventHTML(
    index,
    sessionStartAt,
    event,
    'Session Started',
    null,
    iconPlay('rect-16 text-primary'),
  )
}

function sessionPauseEventHTML(index, sessionStartAt, event) {
  return sessionEventHTML(
    index,
    sessionStartAt,
    event,
    `Pause &middot; ${toDurationList(event.pauseDuration)}`,
    `<p class="flex items-center text-secondary-text text-sm px-28 pb-8 w-full">
      ${event.reason || 'No reason provided'}
    </p>`,
    iconPause('rect-16 text-primary'),
  )
}

function sessionEndEventHTML(index, sessionStartAt, event) {
  return sessionEventHTML(
    index,
    sessionStartAt,
    event,
    'Session Ended',
    null,
    iconStop('rect-16 text-primary'),
  )
}

function recordingStartEventHTML(index, sessionStartAt, event) {
  return sessionEventHTML(
    index,
    sessionStartAt,
    event,
    'Video Started',
    null,
    iconRecording('ml-4 rect-8'),
  )
}

function recordingEndEventHTML(index, sessionStartAt, event) {
  return sessionEventHTML(
    index,
    sessionStartAt,
    event,
    'Video Stopped',
    null,
    iconNotRecording('ml-4 rect-8'),
  )
}

function sessionEventHTML(index, sessionStartAt, event, title, label, icon) {
  const absolute = toAbsoluteHoursAndMinutes(event.time)
  const offset = toDuration(Math.max(0, event.time - sessionStartAt))

  return eventHTML(
    index,
    `<div class="flex items-center justify-between space-x-16 p-8 w-full">
      <div class="flex items-center space-x-8">
        <div class="w-16 -m-2">
          ${icon || ''}
        </div>
        <div class="space-y-4">
          <h3 class="text-primary-text text-sm">${title}</h3>
          <p class="text-xs text-blue-600" aria-label="event timestamp">${offset}</p>
        </div>
      </div>
      <p class="text-xs text-secondary-text shrink-0" aria-label="event time of day">${absolute}</p>
    </div>
    ${label || ''}`,
  )
}

function observationEventHTML(index, sessionStartAt, event) {
  const observation = event.observation

  let title
  let label
  let icon
  const labelClasses = 'inline-flex px-8 py-2 bg-gray-200 rounded text-2xs'

  switch (observation.type) {
    case 'program_target_observation': {
      const values = ptoValues(observation)
      title = values.title
      const includeTargetName = values.includeTargetName ?? true
      label = `
        <div class="flex items-center flex-wrap gap-4 text-xs font-mono self-start text-secondary-text bg-gray-50 p-6 rounded-b w-full">
          <span class="${labelClasses}">${observation.program}</span>${
            includeTargetName
              ? `> <span class="${labelClasses}">${observation.name}</span>`
              : ''
          }
        </div>
      `
      icon = values.icon
      break
    }
    case 'free_operant_observation': {
      const values = fooValues(observation)
      title = values.title
      label = `
        <p class="flex items-center flex-wrap gap-4 text-xs font-mono self-start text-secondary-text bg-gray-50 p-6 rounded-b w-full">
          <span class="${labelClasses}">Free operant</span>
        </p>
      `
      icon = values.icon
      break
    }
    case 'timestamp': {
      if (observation.comment) {
        title = 'Note'
        label = `
          <p class="flex items-center text-secondary-text text-sm px-28 pb-8 w-full">
            ${observation.comment || 'No comment'}
          </p>
        `
        icon = iconDescription('rect-16 text-primary')
      } else {
        title = 'Timestamp'

        let basePath = window.location.pathname.match(
          /\/sessions\/[a-z0-9-]+/,
        )[0]
        let url = `${basePath}/timestamps/${observation.uuid}/edit`

        label = `
          <div
            class="shrink-0 inline-flex items-center gap-x-4 px-8 py-2 text-sm text-center truncate bg-amber-400 rounded uppercase font-semibold text-xs ml-28 mb-8 hover:bg-amber-300 transition-colors" title="Add note"
            hx-get="${url}"
            hx-swap="outerHTML"
            hx-trigger="click, keydown[keyCode==13]"
            tabindex="0"
            role="button"
          >
            Add Note
          </div>
        `
        icon = iconAddNote('rect-16 text-primary')
      }
      break
    }
  }

  const absolute = toAbsoluteHoursAndMinutes(event.time)
  const offset = toDuration(Math.max(0, event.time - sessionStartAt))

  return eventHTML(
    index,
    `<div class="flex items-center justify-between space-x-16 p-8 w-full">
      <div class="flex items-center space-x-8">
        <div class="w-16 -m-2">
          ${icon ? icon : ''}
        </div>
        <div class="space-y-4">
          <h3 class="text-primary-text text-sm">${title}</h3>
          <p class="text-xs text-blue-600" aria-label="event timestamp">${offset}</p>
        </div>
      </div>
      <p class="text-xs text-secondary-text shrink-0" aria-label="event time of day">${absolute}</p>
    </div>
    ${label ? label : ''}`,
  )
}

function eventHTML(index, content) {
  return `
    <div class="flex relative group before:shrink-0 before:w-16 before:min-h-32 before:mr-8 before:bg-[linear-gradient(to_right,#fff_7px,#9ca3af_8px,#9ca3af_8px,#9ca3af_9px,#fff_9px)] last:before:h-1/2 after:m-4 after:rect-8 after:rounded-full after:ring-4 after:ring-white after:bg-gray-400 after:absolute after:top-0 after:left-0">
      <button
        type="button"
        class="text-left flex flex-col w-[calc(100%-24px)] ring-1 ring-border rounded mb-24 cursor-pointer transition group-last:mb-0 hover:shadow-card hover:border-blue-500 data-[state=active]:ring-blue-300 data-[state=active]:ring-2"
        data-post-session-timeline-target="event"
        data-post-session-timeline-index-param="${index}"
        data-action="click->post-session-timeline#navigateToEvent"
      >
        ${content}
      </button>
    </div>
  `
}

function observationType(data) {
  if (typeof data.duration === 'object') {
    if (data.duration.status === 'running') {
      return 'duration_running'
    } else if (data.duration.status === 'stopped') {
      return 'duration_stopped'
    }
  } else if (typeof data.estimated_mastery === 'number') {
    return 'estimated_mastery'
  } else if (
    typeof data.frequency_and_rate === 'number' &&
    typeof data.intensity === 'number'
  ) {
    return 'frequency_rate_intensity'
  } else if (typeof data.frequency_and_rate === 'number') {
    return 'frequency_rate'
  } else if (typeof data.percent_of_opportunities === 'object') {
    return 'percent_of_opportunities'
  } else if (typeof data.partial_interval === 'object') {
    return `partial_interval_${data.status}`
  } else if (typeof data.whole_interval === 'object') {
    return `whole_interval_${data.status}`
  } else if (typeof data.task_analysis === 'object') {
    return `task_analysis_${data.status}`
  }

  return 'unknown'
}

function fooValues(observation) {
  const data = observation.data
  switch (observationType(data)) {
    case 'duration_running':
      return {
        title: `${observation.name} - Started`,
        icon: iconTimer('rect-16 text-primary'),
      }
    case 'duration_stopped':
      return {
        title: `${observation.name} - Stopped (${data.duration.duration.minutes}m, ${data.duration.duration.seconds}s)`,
        icon: iconTimerOff('rect-16 text-primary'),
      }
    case 'estimated_mastery':
      return {
        title: `${observation.name} - ${data.estimated_mastery}% Mastery`,
        icon: iconPercent('rect-16 text-primary'),
      }
    case 'frequency_rate_intensity':
      return {
        title: `${observation.name} - Level ${data.intensity}`,
        icon: iconFlag('rect-16 text-primary'),
      }
    case 'frequency_rate':
      return {
        title: `${observation.name}`,
        icon: iconFlag('rect-16 text-primary'),
      }
    case 'partial_interval_start_trial':
      return {
        title: `${observation.name} - Trial ${data.partial_interval.trial} Started`,
        icon: iconTimer('rect-16 text-primary'),
      }
    case 'partial_interval_end_trial':
      return {
        title: `${observation.name} - Trial Ended (${data.partial_interval.minutes}m, ${data.partial_interval.seconds}s)`,
        icon: iconTimerOff('rect-16 text-primary'),
      }
    case 'partial_interval_result':
      return {
        title: `${observation.name} - Trial ${
          data.partial_interval.trial
        }, Interval ${data.partial_interval.interval + 1}`,
        icon: data.partial_interval.result
          ? iconCheck('rect-16 text-primary')
          : iconBlock('rect-16 text-primary'),
      }
    case 'whole_interval_start_trial':
      return {
        title: `${observation.name} - Trial ${data.whole_interval.trial} Started`,
        icon: iconTimer('rect-16 text-primary'),
      }
    case 'whole_interval_end_trial':
      return {
        title: `${observation.name} - Trial Ended (${data.whole_interval.minutes}m, ${data.whole_interval.seconds}s)`,
        icon: iconTimerOff('rect-16 text-primary'),
      }
    case 'whole_interval_toggle':
      return {
        title: `${observation.name} – Behavior ${
          data.whole_interval.is_occurring ? 'Started' : 'Stopped'
        }`,
        icon: iconInfo('rect-16 text-primary'),
      }
    case 'whole_interval_result':
      return {
        title: `${observation.name} - Trial ${
          data.whole_interval.trial
        }, Interval ${data.whole_interval.interval + 1}`,
        icon: data.whole_interval.result
          ? iconCheck('rect-16 text-primary')
          : iconBlock('rect-16 text-primary'),
      }
    default:
      return { title: 'Unknown', icon: null }
  }
}

function ptoValues(observation) {
  const data = observation.data
  switch (observationType(data)) {
    case 'duration_running':
      return {
        title: `Started`,
        icon: iconTimer('rect-16 text-primary'),
      }
    case 'duration_stopped':
      return {
        title: `Stopped (${data.duration.duration.minutes}m, ${data.duration.duration.seconds}s)`,
        icon: iconTimer('rect-16 text-primary'),
      }
    case 'estimated_mastery':
      return {
        title: `${data.estimated_mastery}% Mastery`,
        icon: iconPercent('rect-16 text-primary'),
      }
    case 'frequency_rate_intensity':
      return {
        title: `Behavior occurred - Level ${data.intensity}`,
        icon: iconFlag('rect-16 text-primary'),
      }
    case 'frequency_rate':
      return {
        title: `Behavior occurred`,
        icon: iconFlag('rect-16 text-primary'),
      }
    case 'percent_of_opportunities': {
      const { prompt_title } = data.percent_of_opportunities.result
      let icon
      switch (prompt_title) {
        case 'Desired Behavior':
          icon = iconAdd('rect-16 text-primary')
          break
        case 'Undesired Behavior':
          icon = iconRemove('rect-16 text-primary')
          break
        case 'No Response':
          icon = iconBlock('rect-16 text-primary')
          break
        default:
          icon = iconPrompt('rect-16 text-primary')
          break
      }

      return {
        title: prompt_title,
        icon,
      }
    }
    case 'task_analysis_start_trial':
      return {
        title: `${observation.program} - Trial ${data.task_analysis.trial} Started`,
        icon: iconTimer('rect-16 text-primary'),
        includeTargetName: false,
      }
    case 'task_analysis_end_trial':
      return {
        title: `${observation.program} - Trial Ended (${data.task_analysis.minutes}m, ${data.task_analysis.seconds}s)`,
        icon: iconTimerOff('rect-16 text-primary'),
        includeTargetName: false,
      }
    case 'task_analysis_result': {
      const { prompt_title } = data.task_analysis.result
      let icon
      switch (prompt_title) {
        case 'Desired Behavior':
          icon = iconAdd('rect-16 text-primary')
          break
        case 'Undesired Behavior':
          icon = iconRemove('rect-16 text-primary')
          break
        case 'No Response':
          icon = iconBlock('rect-16 text-primary')
          break
        default:
          icon = iconPrompt('rect-16 text-primary')
          break
      }

      return {
        title: prompt_title,
        icon,
      }
    }
    default:
      return { title: 'Unknown', icon: null }
  }
}
