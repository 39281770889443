import React from 'react'
import {
  ElementProps,
  UnstyledButton as MantineUnstyledButton,
  UnstyledButtonProps,
} from '@mantine/core'

interface Props
  extends UnstyledButtonProps,
    ElementProps<'button', keyof UnstyledButtonProps> {}

export const UnstyledButton = ({ ...props }: Props) => {
  return <MantineUnstyledButton {...props} />
}
