import { z } from 'zod'

export const wholeIntervalResultSchema = z.object({
  interval: z.number(),
  result: z.boolean(),
  trial: z.number(),
})

export const iseSessionTargetStateWholeIntervalSchema = z.object({
  dataCollectionMethod: z.literal('whole_interval'),
  latestInterval: z.number().optional(),
  results: z.array(wholeIntervalResultSchema),
  startTime: z.number(),
  toggle: z
    .object({
      trial: z.number(),
      interval: z.number(),
      isOccurring: z.boolean(),
    })
    .nullable(),
  trial: z.number(),
})

export type WholeIntervalResultSchema = z.infer<
  typeof wholeIntervalResultSchema
>

export type ISESessionTargetStateWholeInterval = z.infer<
  typeof iseSessionTargetStateWholeIntervalSchema
>
