import React from 'react'
import { Card as MantineCard, CardProps } from '@mantine/core'

export const Card = ({ classNames, children, ...props }: CardProps) => {
  return (
    <MantineCard classNames={classNames} {...props}>
      {children}
    </MantineCard>
  )
}

Card.Section = MantineCard.Section
