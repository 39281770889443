import React from 'react'

import { classesMerge } from '@/core/helpers/classes-merge'
import { Notification } from '@/core/services/websocket/services'

import { NOTIFICATION_BAR_COLOR } from './constants'

interface NotificationCategoryBarProps {
  isRead: boolean
  category: Notification['category']
}

export const NotificationCategoryBar = ({
  isRead,
  category,
}: NotificationCategoryBarProps) => {
  if (isRead) return null

  return (
    <div
      className={classesMerge(
        'absolute w-4 h-[calc(100%-32px)] rounded left-8',
        NOTIFICATION_BAR_COLOR[category],
      )}
    />
  )
}
