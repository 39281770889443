import React, { useRef } from 'react'
import { TimeInput as MantineTimeInput, TimeInputProps } from '@mantine/dates'

import { MaterialIcon } from '@/core/components/material-icon'
import { ActionIcon } from '@/core/mantine-ui'

import styles from './TimeInput.module.scss'

export const TimeInput = (props: TimeInputProps) => {
  const ref = useRef<HTMLInputElement>(null)

  const pickerControl = (
    <ActionIcon
      variant="subtle"
      color="gray"
      onClick={() => ref.current?.showPicker()}
    >
      <MaterialIcon icon="schedule" />
    </ActionIcon>
  )

  return (
    <MantineTimeInput
      ref={ref}
      rightSection={pickerControl}
      classNames={{
        input: styles.timeInputInput,
        label: styles.timeInputLabel,
        ...props.classNames,
      }}
      {...props}
    />
  )
}
