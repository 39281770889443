import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']
  static values = {
    activeIndex: Number,
  }

  initialize() {
    this.windowResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // set default offset based on parent padding
        let offsetLeft = 2
        // loop through each prior button and find width to determine offset
        for (let i = 0; i < this.buttonTargets.indexOf(entry.target); i++) {
          offsetLeft += parseInt(this.buttonTargets[i].dataset.width)
        }
        // set data attributes for button's left/width
        entry.target.dataset.left = offsetLeft
        entry.target.dataset.width = Math.floor(
          entry.borderBoxSize[0].inlineSize,
        )
      }
      this.activeIndexValueChanged()
    })
  }

  connect() {
    // run window resize observer on window resize
    this.buttonTargets.forEach((btn) => {
      this.windowResizeObserver.observe(btn)
    })

    // delay adding transition until indicator is set (on load only)
    setTimeout(() => {
      this.element.classList.add('before:transition-all', 'before:duration-300')
    }, 100)
  }

  updateIndex(e) {
    // find pressed button's index and set activeIndex
    this.activeIndexValue = this.buttonTargets.indexOf(e.currentTarget)
  }

  updateIndexFromOutsideController(e) {
    this.activeIndexValue = e.detail.content
  }

  setIndicator() {
    // set indicators x and width based on button
    this.element.style.cssText = `--position: ${
      this.buttonTargets[this.activeIndexValue].dataset.left
    }px; --size: ${this.buttonTargets[this.activeIndexValue].dataset.width}px`
  }

  setActiveTab() {
    this.buttonTargets.forEach((el) => {
      delete el.dataset.state
    })
    this.buttonTargets[this.activeIndexValue].dataset.state = 'active'
  }

  activeIndexValueChanged() {
    // check if no index selected
    if (this.activeIndexValue == -1) return

    this.setActiveTab()
    this.setIndicator()
  }
}
