import { z } from 'zod'

import { IconProps } from '@/core/components/icons/Icon'
import i18n from '@/core/locales/i18n'
import {
  FREE_OPERANT_DATA_COLLECTION_METHODS,
  FREE_OPERANT_STATUSES,
  FreeOperantStatus,
} from '@/core/models/free-operants/constants'
import {
  CALCULATION_TYPES,
  OPERATOR_TYPES,
  TRIALS_CRITERIA_TYPES,
} from '@/tp/models/program/constants'
import { exhaustiveGuard } from '@/core/helpers/enum'
import { toPrecision } from '@/core/helpers/number'
import { transformUndefinedToNull } from '@/core/helpers/form'

import { phaseChangeSchema } from '../phase-change/schema'
import { attachmentSchema } from '../attachment'

export const FREE_OPERANT_STATUS_ICONS = {
  baseline: undefined,
  intervention: undefined,
  maintenance_daily: 'mastery',
  maintenance_eod: 'mastery',
  maintenance_every_other_week: 'mastery',
  maintenance_monthly: 'mastery',
  maintenance_weekly: 'mastery',
  on_hold: 'pause',
  completed: 'mastery',
  archived: 'lock',
} as const satisfies Record<FreeOperantStatus, IconProps['icon'] | undefined>

export const FREE_OPERANT_STATUS_COLORS = {
  baseline: 'text-secondary-text',
  intervention: 'text-secondary-text',
  maintenance_daily: 'text-primary',
  maintenance_eod: 'text-primary',
  maintenance_every_other_week: 'text-primary',
  maintenance_monthly: 'text-primary',
  maintenance_weekly: 'text-primary',
  on_hold: 'text-amber-500',
  completed: 'text-primary',
  archived: 'text-secondary-text',
} as const satisfies Record<FreeOperantStatus, string>

export const FREE_OPERANT_TRENDS = ['increase', 'maintain', 'decrease'] as const

export type FreeOperantTrend = (typeof FREE_OPERANT_TRENDS)[number]

export const FREQUENCY_AND_RATE_REPORT_TYPES = ['FRE', 'MIN', 'HR'] as const

export type FrequencyAndRateReportTypes =
  (typeof FREQUENCY_AND_RATE_REPORT_TYPES)[number]

export const DURATION_REPORT_TYPES = ['AVG', 'TOT'] as const

export type DurationReportTypes = (typeof DURATION_REPORT_TYPES)[number]

export const INTENSITY_SCALE_CHOICES = ['2', '3', '4', '5'] as const

export const DURATION_STATUSES = ['running', 'stopped'] as const

export type DurationStatus = (typeof DURATION_STATUSES)[number]

export const relatedProgramSchema = z.object({
  id: z.string(),
  title: z.string(),
  goalTitle: z.string().nullish(),
  domain: z.string().nullish(),
})

export const objectivesSchema = z.array(
  z.object({
    id: z.string(),
    title: z.string(),
    referenceTitle: z.string().optional(),
    domain: z.string(),
  }),
)

export const programsSchema = z.array(relatedProgramSchema)

export const freeOperantRuleSchema = z.object({
  id: z.number(),
  fromStatus: z.enum(FREE_OPERANT_STATUSES),
  toStatus: z.enum(FREE_OPERANT_STATUSES),
  calculationType: z.enum(CALCULATION_TYPES),
  operator: z.enum(OPERATOR_TYPES),
  value: z.string(),
  sessionCount: z.number(),
  trialsCriteria: z.enum(TRIALS_CRITERIA_TYPES),
})

export type FreeOperantRule = z.infer<typeof freeOperantRuleSchema>
export type RelatedProgram = z.infer<typeof relatedProgramSchema>

export const baseSchema = z.object({
  id: z.string(),
  name: z.string(),
  objectives: objectivesSchema,
  programs: programsSchema.nullish(),
  status: z.enum(FREE_OPERANT_STATUSES),
  trend: z.enum(FREE_OPERANT_TRENDS),
  operationalDefinition: z.string(),
  inSessionInstructions: z.string(),
  antecedentStrategies: z.string(),
  consequentStrategies: z.string(),
  dataCollectionMethod: z.enum(FREE_OPERANT_DATA_COLLECTION_METHODS),
  baselineLevel: z
    .number()
    .min(0, {
      message: 'If specified, the minimum baseline level must be 0 or greater',
    })
    .nullish()
    .transform(transformUndefinedToNull),
  behaviorFunctions: z.array(z.string()),
  behaviorDomains: z.array(z.string()),
  freeOperantGoal: z.string().nullable(),
  rules: z.array(freeOperantRuleSchema),
  phaseChanges: z.array(phaseChangeSchema).default([]),
  attachments: z.array(attachmentSchema),
  hasObservations: z.boolean(),
  lastObservation: z.number().nullable().optional(),
  sparklineData: z
    .array(
      z.object({
        sessionId: z.string(),
        sessionTime: z.string().pipe(z.coerce.date()),
        aggregateData: z.any(),
      }),
    )
    .optional(),
  order: z.number().nullish(),
})

const intervalSchema = z.object({
  intervalLength: z.number().nullable(),
  intervals: z.number().nullish(),
  trials: z.number().nullish(),
})

const dataCollectionMethodSchema = z.discriminatedUnion(
  'dataCollectionMethod',
  [
    z.object({
      dataCollectionMethod: z.literal('frequency_and_rate'),
      dataCollectionMethodAttributes: z.object({
        trackIntensity: z.boolean().optional(),
        intensityScale: z.number().nullable().optional(),
        reportType: z.enum(FREQUENCY_AND_RATE_REPORT_TYPES),
      }),
    }),
    z.object({
      dataCollectionMethod: z.literal('estimated_mastery'),
      dataCollectionMethodAttributes: z.object({}).default({}),
    }),
    z.object({
      dataCollectionMethod: z.literal('duration'),
      dataCollectionMethodAttributes: z.object({
        durationTimer: z.number().optional(),
        reportType: z.enum(DURATION_REPORT_TYPES),
      }),
    }),
    z.object({
      dataCollectionMethod: z.literal('partial_interval'),
      dataCollectionMethodAttributes: intervalSchema,
    }),
    z.object({
      dataCollectionMethod: z.literal('whole_interval'),
      dataCollectionMethodAttributes: intervalSchema,
    }),
  ],
)

export const freeOperantSchema = z.intersection(
  baseSchema,
  dataCollectionMethodSchema,
)

export type FreeOperant = z.infer<typeof freeOperantSchema>

export function formatBaselineLevel(
  freeOperant: FreeOperant,
  t: typeof i18n.t,
) {
  const { baselineLevel } = freeOperant
  if (baselineLevel === null) {
    return t('None', { ns: 'tp' })
  }

  const suffix = baselineLevelSuffix(freeOperant)

  if (suffix === '%') {
    return `${baselineLevel}${suffix}`
  }

  return `${baselineLevel} ${suffix}`
}

export function baselineLevelSuffix(params: FreeOperant) {
  let suffix = ''
  const {
    dataCollectionMethod: dcm,
    dataCollectionMethodAttributes: attributes,
  } = params

  if (
    dcm === 'estimated_mastery' ||
    dcm === 'partial_interval' ||
    dcm === 'whole_interval'
  ) {
    suffix = '%'
  } else if (dcm === 'frequency_and_rate') {
    if (attributes?.reportType === 'MIN') {
      suffix = 'per minute'
    } else if (attributes?.reportType === 'HR') {
      suffix = 'per hour'
    }
  }

  return suffix
}

export function getCriteriaType(freeOperant: FreeOperant, t: typeof i18n.t) {
  switch (freeOperant.dataCollectionMethod) {
    case 'frequency_and_rate': {
      const reportType = freeOperant.dataCollectionMethodAttributes.reportType

      switch (reportType) {
        case 'FRE':
          return t('frequency', { ns: 'tp' })
        case 'MIN':
          return t('frequency per minute', { ns: 'tp' })
        case 'HR':
          return t('frequency per hour', { ns: 'tp' })
        default:
          return
      }
    }

    case 'duration': {
      const reportType = freeOperant.dataCollectionMethodAttributes.reportType

      switch (reportType) {
        case 'TOT':
          return t('total duration', { ns: 'tp' })
        case 'AVG':
          return t('average duration', { ns: 'tp' })
        default:
          return
      }
    }

    case 'partial_interval':
    case 'whole_interval':
      return t('interval', { ns: 'tp' })

    case 'estimated_mastery':
      return t('estimated mastery', { ns: 'tp' })
  }
}

export const formatFrequencyAndRateValue = (
  reportType: FrequencyAndRateReportTypes,
  value: number,
) => {
  switch (reportType) {
    case 'FRE':
      return `${value}`
    case 'HR':
      return `${value}/hr`
    case 'MIN':
      return `${value}/min`
    default:
      return exhaustiveGuard(reportType)
  }
}

const formatDurationValue = (
  reportType: DurationReportTypes,
  value: number,
  t: typeof i18n.t,
) => {
  const { hours, minutes, seconds } = secondsToHMS(value)

  let suffix
  switch (reportType) {
    case 'AVG':
      suffix = '(avg)'
      break
    case 'TOT':
      suffix = '(total)'
      break
    default:
      return exhaustiveGuard(reportType)
  }

  if (hours === 0 && minutes === 0) {
    return t('{{count}} s {{suffix}}', {
      count: seconds,
      suffix,
      ns: 'tp',
    })
  }

  return [
    hours > 0 && `${hours}h`,
    minutes > 0 && `${minutes}m`,
    `${seconds}s`,
    suffix,
  ]
    .filter(Boolean)
    .join(' ')
}

const secondsToHMS = (secs: number) => {
  const hours = Math.floor(secs / 3600)
  const minutes = Math.floor((secs % 3600) / 60)
  const seconds = Math.floor(secs % 60)

  return { hours, minutes, seconds }
}

export const formatFreeOperantObservation = (
  freeOperant: FreeOperant,
  value: number,
  t: typeof i18n.t,
) => {
  const dcm = freeOperant.dataCollectionMethod

  switch (dcm) {
    case 'frequency_and_rate': {
      const attributes = freeOperant.dataCollectionMethodAttributes
      return formatFrequencyAndRateValue(attributes.reportType, value)
    }

    case 'duration': {
      const attributes = freeOperant.dataCollectionMethodAttributes
      return formatDurationValue(attributes.reportType, value, t)
    }

    case 'estimated_mastery':
    case 'partial_interval':
    case 'whole_interval':
      return `${toPrecision(value * 100, 2)}%`

    default:
      return exhaustiveGuard(dcm)
  }
}

export const freeOperantObservationBaseSchema = z.object({
  id: z.string(),
  dataCollectionMethod: z.enum(FREE_OPERANT_DATA_COLLECTION_METHODS),
  timestamp: z.string().pipe(z.coerce.date()),
  user: z.string().nullable(),
  userAvatarUrl: z.string().nullable(),
  userInitials: z.string().nullable(),
  status: z.enum([...FREE_OPERANT_STATUSES, 'not_started']),
  sessionId: z.string(),
})

export const freeOperantObservationSchema = z.discriminatedUnion(
  'dataCollectionMethod',
  [
    freeOperantObservationBaseSchema.extend({
      dataCollectionMethod: z.literal('frequency_and_rate'),
      data: z.object({
        intensity: z.number().min(1).optional(),
        frequencyAndRate: z.number().optional().default(0),
      }),
    }),
    freeOperantObservationBaseSchema.extend({
      dataCollectionMethod: z.literal('estimated_mastery'),
      data: z.object({
        estimatedMastery: z.number().min(0).max(100),
      }),
    }),
    freeOperantObservationBaseSchema.extend({
      dataCollectionMethod: z.literal('duration'),
      data: z.object({
        duration: z.object({
          status: z.enum(DURATION_STATUSES),
          duration: z.object({
            hours: z.number(),
            minutes: z.number(),
            seconds: z.number(),
          }),
        }),
      }),
    }),
    freeOperantObservationBaseSchema.extend({
      dataCollectionMethod: z.literal('partial_interval'),
      data: z.object({
        partialInterval: z.object({
          response: z.string().nullable(),
          trial: z.number().optional().nullable(),
        }),
      }),
    }),
    freeOperantObservationBaseSchema.extend({
      dataCollectionMethod: z.literal('whole_interval'),
      data: z.object({
        wholeInterval: z.object({
          response: z.string().optional().nullable(),
          trial: z.number().optional().nullable(),
        }),
      }),
    }),
  ],
)

export type FreeOperantObservation = z.infer<
  typeof freeOperantObservationSchema
>

export const freeOperantExportRawData = z.object({
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  allTime: z.boolean(),
})

export const freeOpExportRawData = z.object({
  fileName: z.string(),
  fileContent: z.any(),
})

export type ExportRawData = z.infer<typeof freeOpExportRawData>
export type FreeOperantExportRawData = z.infer<typeof freeOperantExportRawData>
