import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    // check each POS select
    this.checkPosSelects()
  }

  checkPosSelects() {
    // get each pos code select field on connect
    const posCodeSelect = this.element.querySelectorAll(
      'select[id$="pos_code_title"]',
    )

    // for each pos code select
    posCodeSelect.forEach((select) => {
      // check whether or not 'Other' is active
      this.handlePosNumber(select)
    })
  }

  handlePosSelect(e) {
    // when pos code select action occurs
    this.handlePosNumber(e.currentTarget, true)
  }

  handlePosNumber(select, purge = false) {
    // get the pos code input block and input
    const posCodeBlock = select
      .closest('fieldset')
      .querySelector('[data-element="pos-code-block"]')
    const posCodeInput = posCodeBlock.querySelector('input')

    // if select is set to 'Other'
    if (select.value == 'Other') {
      // reveal pos code input
      posCodeBlock.classList.remove('hidden')
      // set input to required
      posCodeInput.setAttribute('required', '')
      // purge input if from select event (not on load)
      if (purge) posCodeInput.value = ''
    } else {
      // otherwise hide pos code input
      posCodeBlock.classList.add('hidden')
      // remove required
      posCodeInput.removeAttribute('required')
    }
  }
}
