import React from 'react'
import { FocusTrap as MantineFocusTrap, FocusTrapProps } from '@mantine/core'

export const FocusTrap: React.FC<FocusTrapProps> = ({
  active,
  children,
  ...props
}: FocusTrapProps) => {
  return (
    <MantineFocusTrap active={active} {...props}>
      {children}
    </MantineFocusTrap>
  )
}
