export function toPrecision(num: number, precision: number) {
  const factor = Math.pow(10, precision)
  return Math.round(num * factor) / factor
}

/**
 * given a file size number in bytes, return a human-readable string denoting bytes, kilobytes, or megabytes
 *
 * > [0, 97, 1100, 100000000, 172817892].map(formattedFileSize)
 * > ['0 B', '97 B', '1.07 KB', '95.37 MB', '164.81 MB']
 */
export function formatFileSize(bytes: number) {
  const i = bytes == 0 ? 0 : Math.floor(Math.log(bytes) / Math.log(1024))
  return (
    Number((bytes / Math.pow(1024, i)).toFixed(2)) * 1 +
    ' ' +
    ['B', 'KB', 'MB'][i]
  )
}
