import colors from 'tailwindcss/colors'

// VIOLET COLORS is based on https://tailwindcss.com/docs/customizing-colors - Violet palette

const VIOLET_50 = '#f5f3ff'
const VIOLET_100 = '#ede9fe'
const VIOLET_200 = '#ddd6fe'
const VIOLET_300 = '#c4b5fd'
const VIOLET_400 = '#a78bfa'
const VIOLET_500 = '#8b5cf6'
const VIOLET_600 = '#7c3aed'
const VIOLET_700 = '#6d28d9'
const VIOLET_800 = '#5b21b6'
const VIOLET_900 = '#4c1d95'
const VIOLET_950 = '#2e1065'

export const VIOLET_MANTINE_PALLETE = [
  VIOLET_50,
  VIOLET_100,
  VIOLET_200,
  VIOLET_300,
  VIOLET_400,
  VIOLET_500,
  VIOLET_600,
  VIOLET_700,
  VIOLET_800,
  VIOLET_900,
  VIOLET_950,
] as const

export const VIOLET_TAILWIND_PALLETE = colors.violet
