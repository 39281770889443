import { useFeatureValue as useGrowthbookFeatureValue } from '@growthbook/growthbook-react'
import { ValueOf } from 'type-fest'

import { AppFeatures } from '@/core/services/growthbook/types'

export const useFeatureValue = (
  feature: keyof AppFeatures,
  fallback: ValueOf<AppFeatures>,
) => {
  return useGrowthbookFeatureValue(feature, fallback)
}
