export const PROGRAM_STATUSES = [
  'not_started',
  'active',
  'on_hold',
  'maintenance',
  'completed',
  'archived',
] as const

export type ProgramStatus = (typeof PROGRAM_STATUSES)[number]
